import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Snackbar,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import MuiAlert from "@mui/material/Alert";
import { getPharmacyById, updatePharmacy } from "./../../ApiConfig.jsx";

import { MuiTelInput } from "mui-tel-input";
const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  pharmacyId: yup.string().required("Id is required"),
  type: yup.string().required("Type is required"),
  address: yup.string().required("Address is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup
    .string()
    .matches(/^\+\d(\s*\d){5,13}$/, "Phone number is invalid")
    .required("Phone number is required"),
  contactPerson: yup.string().required("Contact person is required"),
});

function EditPharmacy() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [status, setStatus] = useState("Inactive");
  const initialUserDetails = {
    name: "",
    pharmacyId: "",
    type: "",
    address: "",
    phone: "",
    email: "",
    contactPerson: "",
  };

  const [itemDetails, setItemDetails] = useState(initialUserDetails);
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchItemData = async (id) => {
    try {
      const data = await getPharmacyById(id);
      if (data.succes) {
        setStatus(data.data.status);
        setItemDetails({
          name: data.data.name,
          pharmacyId: data.data.pharmacyId,
          type: data.data.type,
          address: data.data.address,
          phone: data.data.phone,
          email: data.data.email,
          contactPerson: data.data.contactPerson,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchItemData(id);
  }, [id]);

  const validateField = (field, value) => {
    validationSchema
      .validateAt(field, { ...itemDetails, [field]: value })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: error.message }));
      });
  };

  const handleFieldChange = (field, value) => {
    setItemDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [field]: value,
    }));
    validateField(field, value);
  };

  const updateHandler = async (userDetails) => {
    const hasErrors = Object.values(errors).some(
      (errorMessage) => errorMessage !== ""
    );

    if (hasErrors) {
      console.log("Errors found. Cannot update hospital.");
      return;
    }

    console.log(userDetails);
    setIsLoading(true);
    const body = {
      ...userDetails,
      status,
    };
    try {
      const respondData = await updatePharmacy(id, body);
      console.log(respondData);
      if (respondData.succes) {
        setAlertSeverity("success");
        setAlertMessage("Pharmacy edited successfully");
        setTimeout(() => {
          navigate("/pharmacyManagement");
        }, 2500);
      }
    } catch (err) {
      console.log(err);
      setAlertSeverity("error");
      setAlertMessage(`Failed to edit pharmacy: ${err.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`Edit Pharmacy ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Name*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={itemDetails.name}
              onChange={(e) => handleFieldChange("name", e.target.value)}
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Id*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={itemDetails.pharmacyId}
              onChange={(e) => handleFieldChange("pharmacyId", e.target.value)}
              error={!!errors.pharmacyId}
              helperText={errors.pharmacyId}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Type*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={itemDetails.type}
                onChange={(e) => handleFieldChange("type", e.target.value)}
                name="type"
                error={!!errors.type}>
                <MenuItem value="public">Public</MenuItem>
                <MenuItem value="private">Private</MenuItem>
              </Select>
              {errors.type && (
                <Typography variant="caption" color="error">
                  {errors.type}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Address*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={itemDetails.address}
              onChange={(e) => handleFieldChange("address", e.target.value)}
              error={!!errors.address}
              helperText={errors.address}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Email*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={itemDetails.email}
              onChange={(e) => handleFieldChange("email", e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Phone*
            </Typography>
            <MuiTelInput
              inputProps={{ maxLength: 16 }}
              type="text"
              fullWidth
              defaultCountry={"us"}
              variant="filled"
              value={itemDetails.phone}
              onChange={(value, country) => handleFieldChange("phone", value)}
              error={!!errors.phone}
              helperText={errors.phone}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Contact person*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={itemDetails.contactPerson}
              onChange={(e) =>
                handleFieldChange("contactPerson", e.target.value)
              }
              error={!!errors.contactPerson}
              helperText={errors.contactPerson}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={() => updateHandler(itemDetails)}>
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}>
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}

export default EditPharmacy;
