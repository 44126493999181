import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import axios from "axios";
import { getVideo } from "./../../ApiConfig.jsx";
import { Language } from "@mui/icons-material";

function ViewVideo() {
  const { id } = useParams();

  const [videoDetails, setVideoDetails] = useState({});

  const fetchVideo = async (id) => {
    try {
      const data = await getVideo(id);
      if (data.status) {
        console.log(data.result);
        setVideoDetails({
          "Video Track Title": data.result.title,
          "Select Type": data.result.type,
          Artist: data.result.artist_name,
          Language: data.result.language,
          "No Of Viewers": data.result.noOfViewers,
          ...(data.result.type == "Paid" && { Amount: data.result.amount }),
          Video: data.result.video_url,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchVideo(id);
  }, [id]);

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`View Video ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(videoDetails).map(([field, value]) => (
            <React.Fragment key={field}>
              <Grid item xs={1.8}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  {field}:
                </Typography>
              </Grid>
              <Grid item xs={10.2}>
                {field === "Select Type" ? (
                  <Typography>{value}</Typography>
                ) : field === "Video" ? (
                  <video
                    controls
                    src={value}
                    alt="Video Thumbnail"
                    style={{
                      marginTop: "10px",
                      width: "500px",
                      height: "300px",
                    }}
                  />
                ) : (
                  <Typography>{value}</Typography>
                )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewVideo;
