import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  EditOutlined as EditOffIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";

import {
  Box,
  Button,
  IconButton,
  Snackbar,
  Tooltip,
  useTheme,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const Coursesmodule = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://jcgnapi.hasthiya.org/api/bibalModule/getAllBibleModulesWithDetails"
      );
      if (response.data.status) {
        setData(response.data.result);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error(error);
      setAlertSeverity("error");
      setAlertMessage("Failed to fetch data");
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = (id) => {
    console.log("Edit clicked for id:", id);
    navigate(`/module/edit/${id}`);
  };

  const handleViewClick = (row) => {
    navigate(`/module/view/${row.id}`, {
      state: {
        moduleName: row.bibleCourseModule,
        moduleCredit: row.bibleCourseModuleCredit,
      },
    });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "Module ID" },
    {
      field: "bibleCourseModule",
      headerName: "Module Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "bibleCourseModuleCredit",
      headerName: "Module Credits",
      flex: 1,
    },
    {
      field: "bibleCourseId",
      headerName: "Related Courses IDs",
      flex: 1,
      renderCell: (params) => (
        <span>{params.row.bibleCourseId ? params.row.bibleCourseId : "-"}</span>
      ),
    },
    // {
    //   field: "moduleDetailsId",
    //   headerName: "Module Details ID",
    //   flex: 1,
    //   renderCell: (params) => {
    //     const details = params.row.bibleModuleDetails;
    //     return (
    //       <span>{details && details.length > 0 ? details[0].id : "-"}</span>
    //     );
    //   },
    // },
    {
      field: "bibleModuleDetails",
      headerName: "Modules Data Available or Not",
      flex: 1,
      renderCell: (params) => (
        <span>
          {params.row.bibleModuleDetails &&
          params.row.bibleModuleDetails.length > 0
            ? "Yes"
            : "No"}
        </span>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At (DD/MM/YYYY)",
      flex: 1,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        const hasModuleDetails =
          params.row.bibleModuleDetails &&
          params.row.bibleModuleDetails.length > 0;

        // Extract the ID from the first item in the bibleModuleDetails array
        const moduleDetailsId = hasModuleDetails
          ? params.row.bibleModuleDetails[0].id
          : null;

        return (
          <Box>
            {hasModuleDetails ? (
              <>
                <Tooltip title="View">
                  <IconButton onClick={() => handleViewClick(params.row)}>
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit Module Details">
                  <Link to={`/module/editDetails/${moduleDetailsId}`}>
                    <IconButton>
                      <EditOffIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              </>
            ) : (
              <Tooltip title="Add Module Details">
                <Link to={`/module/addDetails/${params.row.id}`}>
                  <IconButton>
                    <AddIcon />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            <Tooltip title="Edit Module">
              <IconButton onClick={() => handleEditClick(params.row.id)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={() => handleDeleteClick(params.row.id)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];
  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this module!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deleteResponse = await axios.delete(
            `https://jcgnapi.hasthiya.org/api/bibalModule/deleteBibleModuleByID/${id}`
          );
          if (!deleteResponse.data.status) {
            throw new Error("Failed to delete module");
          }
          fetchData();
          Swal.fire("Deleted!", "The module has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting module:", error);
          Swal.fire(
            "Error!",
            "Failed to delete module. Please try again later.",
            "error"
          );
        }
      }
    });
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Module Management" subtitle="Listed all modules" />
        <Box>
          <Link to={"/module/create"} style={{ marginRight: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
            >
              Add New Module
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default Coursesmodule;
