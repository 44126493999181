import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Snackbar,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import Header from "../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { createPharmacy } from "./../../ApiConfig.jsx";

import { MuiTelInput } from "mui-tel-input";

const AddPharmacy = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const body = { ...values, status: "active" };
    try {
      const response = await createPharmacy(body);
      if (response.succes) {
        setAlertSeverity("success");
        setAlertMessage("Pharmacy added successfully");
        setTimeout(() => {
          navigate("/pharmacyManagement");
        }, 2500);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new Pharmacy : ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title="Add New Pharmacy" subtitle="" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} style={{ height: "41vh" }}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Id*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.pharmacyId}
                  name="pharmacyId"
                  error={!!touched.pharmacyId && !!errors.pharmacyId}
                  helperText={touched.pharmacyId && errors.pharmacyId}
                />
              </Box>
              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Type*</InputLabel>
                  <Select
                    variant="filled"
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="type"
                    error={!!touched.type && !!errors.type}
                    helperText={touched.type && errors.type}>
                    <MenuItem value="public">Public</MenuItem>
                    <MenuItem value="private">Private</MenuItem>
                  </Select>
                  {touched.type && errors.type && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {errors.type}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>

              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Address*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  name="address"
                  error={!!touched.address && !!errors.address}
                  helperText={touched.address && errors.address}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="mail"
                  label="Email*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
              </Box>
              <Box>
                <MuiTelInput
                  inputProps={{ maxLength: 16}}
                  fullWidth
                  variant="filled"
                  defaultCountry={"us"}
                  label="Phone*"
                  onBlur={handleBlur}
                  onChange={(value, country) =>
                    handleChange({ target: { name: "phone", value } })
                  }
                  value={values.phone}
                  name="phone"
                  error={!!touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone}
                  countryCodeEditable
                  autoFocus
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Contact Person*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.contactPerson}
                  name="contactPerson"
                  error={!!touched.contactPerson && !!errors.contactPerson}
                  helperText={touched.contactPerson && errors.contactPerson}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}>
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}>
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  pharmacyId: yup.string().required("Id is required"),
  type: yup.string().required("Type is required"),
  address: yup.string().required("Address is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup
    .string()
    .matches(/^\+\d(\s*\d){5,13}$/, "Phone number is invalid")
    .required("Phone number is required"),
  contactPerson: yup.string().required("Contact person is required"),
});

const initialValues = {
  name: "",
  pharmacyId: "",
  address: "",
  phone: "+",
  email: "",
  type: "",
  contactPerson: "",
};

export default AddPharmacy;
