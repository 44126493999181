import { Avatar, Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";

function ViewUser() {
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(
          `https://jcgnapi.hasthiya.org/user/getUserById/${id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }
        const userData = await response.json();

        const {
          email,
          streetNumber,
          streetName,
          APT,
          city,
          state,
          country,
          zipCode,
          imageUrl,
          Role,
          Professor,
          createdAt,
        } = userData.result;

        const formattedUserDetails = {
          email,
          streetNumber,
          streetName,
          APT,
          city,
          state,
          country,
          zipCode,
          imageUrl,
          firstname: Professor.firstname,
          middlename: Professor.middlename,
          lastname: Professor.lastname,
          profphone: Professor.profphone,
          perfferdfirstname: Professor.perfferdfirstname || "",
          nickname: Professor.nickname,
          level: Professor.level,
          field: Professor.field,
          role: Role.role,
          createdAt,
        };

        setUserDetails(formattedUserDetails);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [id]);

  const renderDetails = () => {
    if (!userDetails) return null;

    const detailItems = [
      { label: "Email", value: userDetails.email },
      { label: "Street Number", value: userDetails.streetNumber },
      { label: "Street Name", value: userDetails.streetName },
      { label: "APT", value: userDetails.APT },
      { label: "City", value: userDetails.city },
      { label: "State", value: userDetails.state },
      { label: "Country", value: userDetails.country },
      { label: "Zip Code", value: userDetails.zipCode },
      { label: "Firstname", value: userDetails.firstname },
      { label: "Middlename", value: userDetails.middlename },
      { label: "Lastname", value: userDetails.lastname },
      { label: "Prof Phone", value: userDetails.profphone },
      { label: "Preferred First Name", value: userDetails.perfferdfirstname },
      { label: "Nickname", value: userDetails.nickname },
      { label: "Level", value: userDetails.level },
      { label: "Field", value: userDetails.field },
      { label: "Role", value: userDetails.role },
      { label: "CreatedAt", value: formatCreatedAt(userDetails.createdAt) },
    ];

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Avatar
            alt="User"
            src={`/public/assets/${userDetails.imageUrl}`}
            sx={{ width: 150, height: 150 }}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          {detailItems.map((item) => (
            <Grid container spacing={1} key={item.label}>
              <Grid item xs={6} md={3}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1rem", marginBottom: 1 }}
                >
                  <strong>{item.label}</strong>
                </Typography>
              </Grid>
              <Grid item xs={6} md={9}>
                <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                  :{item.value}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    return date.toLocaleString("en-US", {
      weekday: "long",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };

  return (
    <Box p="20px">
      <Header
        title={`View Professor ID ${id}`}
        subtitle={userDetails ? `Role: ${userDetails.role}` : ""}
      />{" "}
      <Box m="10px 0 0 0" height="55vh" overflow="auto">
        {renderDetails()}{" "}
      </Box>
    </Box>
  );
}

export default ViewUser;
