import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";

function ViewAppointment() {
  const { id } = useParams();
  const [appointmentData, setAppointmentData] = useState(null);

  useEffect(() => {
    const fetchAppointmentData = async () => {
      try {
        const response = await fetch(
          `https://jcgnapi.hasthiya.org/api/appointment/getAppointmentById/${id}`
        );
        const responseData = await response.json();
        if (responseData.status) {
          setAppointmentData(responseData.result);
        } else {
          console.error(
            "Failed to fetch appointment data:",
            responseData.message
          );
        }
      } catch (error) {
        console.error("Error fetching appointment data:", error);
      }
    };

    fetchAppointmentData();
  }, [id]);

  const formatFieldName = (fieldName) => {
    const customFields = {
      type_service: "Type of Service",
      purpose_appointment: "Purpose of Appointment",
      location_appointment: "Location of Appointment",
      type_appointment: "Type of Appointment",
      createdAt: "Created At",
      updatedAt: "Updated At",
    };

    if (customFields[fieldName]) {
      return customFields[fieldName];
    }

    return fieldName
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header
        title={`View Appointment ${id}`}
        subtitle={appointmentData ? `Status ${appointmentData.status}` : ""}
      />
      <Box ml="40px">
        <Grid container spacing={2}>
          {appointmentData &&
            Object.entries(appointmentData).map(([field, value]) => (
              <React.Fragment key={field}>
                <Grid item xs={2}>
                  <Typography
                    variant="h5"
                    component="span"
                    fontWeight="bold"
                    mt={3}
                  >
                    {formatFieldName(field)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="h5"
                    component="span"
                    fontWeight="bold"
                    mt={3}
                  >
                    :
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h6" component="span" mt={3} mr={-5}>
                    {field === "createdAt" || field === "updatedAt"
                      ? formatDate(value)
                      : value}
                  </Typography>
                </Grid>
              </React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewAppointment;
