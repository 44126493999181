import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Header";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CreateNewCourse = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const { id } = useParams();
  const [modules, setModules] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const response = await axios.get(
          "https://jcgnapi.hasthiya.org/api/bibalModule/getAllBibleModules"
        );
        if (response.data.status) {
          setModules(response.data.result);
        }
      } catch (error) {
        console.error("Error fetching modules:", error);
      }
    };
    fetchModules();
  }, []);

  const handleAddModule = async (moduleId) => {
    try {
      setIsLoading(true);
      const result = await Swal.fire({
        title: "Are you sure?",
        text: `You want to add this module to the course ID ${id}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, add it!",
      });

      if (result.isConfirmed) {
        const body = { bibleCourseId: id };
        const response = await axios.put(
          `https://jcgnapi.hasthiya.org/api/bibalModule/addModuleToCourse/${moduleId}`,
          body
        );
        if (response.status) {
          setAlertSeverity("success");
          setAlertMessage("Module added to course successfully!");
          setTimeout(() => {
            navigate("/courses");
          }, 2500);
        }
      } else {
        setAlertSeverity("warning");
        setAlertMessage("Module not been added to the course.");
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add module to course: ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="75vh" overflow="auto">
      <Header
        title={`Add Modules to Course ID ${id}`}
        subtitle="Select modules that you want to add"
      />
      <Box
        display="flex"
        flexWrap="wrap"
        gap="20px"
        justifyContent="space-between"
        m="20px"
      >
        {modules.length > 0 ? (
          modules.map((module) => (
            <Card key={module.id} sx={{ width: isNonMobile ? "45%" : "100%" }}>
              <CardContent>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={9}>
                    <Typography variant="h6" component="div" fontWeight="bold">
                      Module ID: {module.id}
                    </Typography>
                    <Typography variant="h6" component="div" fontWeight="bold">
                      Module Name: {module.bibleCourseModule}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Module Credits: {module.bibleCourseModuleCredit}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} textAlign="right">
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => handleAddModule(module.id)}
                      sx={{
                        backgroundColor: "#6870fa",
                        color: "white",
                        fontSize: "12px",
                        "&:hover": {
                          backgroundColor: "#3e4396",
                        },
                      }}
                    >
                      Add Module to Course
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography>No modules available to add</Typography>
        )}
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          style={{ color: "white" }}
        >
          {alertSeverity === "success"
            ? "Success"
            : alertSeverity === "warning"
            ? "Warning"
            : "Error"}
          {": "}
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CreateNewCourse;
