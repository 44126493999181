import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import axios from "axios";
import { getEvent } from "./../../ApiConfig.jsx";


function ViewEvents() {
  const { id } = useParams();
  const [image, setImage] = useState("");
  const [userDetails, setUserDetails] = useState({});

    const fetchEvent = async (id) => {
      try {
        const data = await getEvent(id);
        if (data.status) {
          console.log(data.result);
           setImage(data.data.img_url);
           setUserDetails({
             "S.No": data.data.id,
             "Event Name": data.data.name,
             "Start Date": new Date(data.data.start_date)
               .toISOString()
               .split("T")[0],
             "End Date": new Date(data.data.end_date)
               .toISOString()
               .split("T")[0],
             "Start Time": data.data.start_time,
             Location: data.data.location,
             "Event Type": data.data.type,
             "Status": data.data.status,
             "Event Charges": data.data.charges,
           });
        }
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      fetchEvent(id);
    }, [id]);

  return (
    <Box m="20px" height="80vh" overflow="auto" paddingRight="20px">
      <Header title={`View Event ${id}`} subtitle="" />
      <Box ml="40px">
        <Grid container spacing={2}>
          {Object.entries(userDetails).map(([field, value]) => (
            <React.Fragment key={field}>
              <Grid item xs={3}>
                <Typography
                  variant="h5"
                  component="span"
                  fontWeight="bold"
                  mt={3}>
                  {field}:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h6" component="span" mt={3} mr={-5}>
                  {value}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}

          {image && (
                 <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}>
                <Grid item xs={3}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Image:
                  </Typography>
                </Grid>
                <img
                  style={{ maxWidth: "400px", maxHeight: "400px" }}
                  src={image}
                  alt=""
                />
              </div>
            </Grid>
        
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewEvents;
