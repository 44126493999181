export const countries = [
  { country: "Afghanistan (+93)" },
  { country: "Albania (+355)" },
  { country: "Algeria (+213)" },
  { country: "Andorra (+376)" },
  { country: "Angola (+244)" },
  { country: "Antigua and Barbuda (+1-268)" },
  { country: "Argentina (+54)" },
  { country: "Armenia (+374)" },
  { country: "Australia (+61)" },
  { country: "Austria (+43)" },
  { country: "Azerbaijan (+994)" },
  { country: "Bahamas, The (+1-242)" },
  { country: "Bahrain (+973)" },
  { country: "Bangladesh (+880)" },
  { country: "Barbados (+1-246)" },
  { country: "Belarus (+375)" },
  { country: "Belgium (+32)" },
  { country: "Belize (+501)" },
  { country: "Benin (+229)" },
  { country: "Bhutan (+975)" },
  { country: "Bolivia (+591)" },
  { country: "Bosnia and Herzegovina (+387)" },
  { country: "Botswana (+267)" },
  { country: "Brazil (+55)" },
  { country: "Brunei (+673)" },
  { country: "Bulgaria (+359)" },
  { country: "Burkina Faso (+226)" },
  { country: "Burma (+95)" },
  { country: "Burundi (+257)" },
  { country: "Cambodia (+855)" },
  { country: "Cameroon (+237)" },
  { country: "Canada (+1)" },
  { country: "Cape Verde (+238)" },
  { country: "Central African Republic (+236)" },
  { country: "Chad (+235)" },
  { country: "Chile (+56)" },
  { country: "China (+86)" },
  { country: "Colombia (+57)" },
  { country: "Comoros (+269)" },
  { country: "Congo, Republic of the (+242)" },
  { country: "Congo, Democratic Republic of the (+243)" },
  { country: "Costa Rica (+506)" },
  { country: "Croatia (+385)" },
  { country: "Cuba (+53)" },
  { country: "Cyprus (+357)" },
  { country: "Czech Republic (+420)" },
  { country: "Denmark (+45)" },
  { country: "Djibouti (+253)" },
  { country: "Dominica (+1-767)" },
  { country: "Dominican Republic (+1-809, +1-829, +1-849)" },
  { country: "East Timor (+670)" },
  { country: "Ecuador (+593)" },
  { country: "Egypt (+20)" },
  { country: "El Salvador (+503)" },
  { country: "Equatorial Guinea (+240)" },
  { country: "Eritrea (+291)" },
  { country: "Estonia (+372)" },
  { country: "Eswatini (+268)" },
  { country: "Ethiopia (+251)" },
  { country: "Fiji (+679)" },
  { country: "Finland (+358)" },
  { country: "France (+33)" },
  { country: "Gabon (+241)" },
  { country: "Gambia, The (+220)" },
  { country: "Georgia (+995)" },
  { country: "Germany (+49)" },
  { country: "Ghana (+233)" },
  { country: "Greece (+30)" },
  { country: "Grenada (+1-473)" },
  { country: "Guatemala (+502)" },
  { country: "Guinea (+224)" },
  { country: "Guinea-Bissau (+245)" },
  { country: "Guyana (+592)" },
  { country: "Haiti (+509)" },
  { country: "Honduras (+504)" },
  { country: "Hungary (+36)" },
  { country: "Iceland (+354)" },
  { country: "India (+91)" },
  { country: "Indonesia (+62)" },
  { country: "Iran (+98)" },
  { country: "Iraq (+964)" },
  { country: "Ireland (+353)" },
  { country: "Israel (+972)" },
  { country: "Italy (+39)" },
  { country: "Jamaica (+1-876)" },
  { country: "Japan (+81)" },
  { country: "Jordan (+962)" },
  { country: "Kazakhstan (+7)" },
  { country: "Kenya (+254)" },
  { country: "Kiribati (+686)" },
  { country: "Korea, North (+850)" },
  { country: "Korea, South (+82)" },
  { country: "Kosovo (+383)" },
  { country: "Kuwait (+965)" },
  { country: "Kyrgyzstan (+996)" },
  { country: "Laos (+856)" },
  { country: "Latvia (+371)" },
  { country: "Lebanon (+961)" },
  { country: "Lesotho (+266)" },
  { country: "Liberia (+231)" },
  { country: "Libya (+218)" },
  { country: "Liechtenstein (+423)" },
  { country: "Lithuania (+370)" },
  { country: "Luxembourg (+352)" },
  { country: "Madagascar (+261)" },
  { country: "Malawi (+265)" },
  { country: "Malaysia (+60)" },
  { country: "Maldives (+960)" },
  { country: "Mali (+223)" },
  { country: "Malta (+356)" },
  { country: "Marshall Islands (+692)" },
  { country: "Mauritania (+222)" },
  { country: "Mauritius (+230)" },
  { country: "Mexico (+52)" },
  { country: "Micronesia (+691)" },
  { country: "Moldova (+373)" },
  { country: "Monaco (+377)" },
  { country: "Mongolia (+976)" },
  { country: "Montenegro (+382)" },
  { country: "Morocco (+212)" },
  { country: "Mozambique (+258)" },
  { country: "Myanmar (+95)" },
  { country: "Namibia (+264)" },
  { country: "Nauru (+674)" },
  { country: "Nepal (+977)" },
  { country: "Netherlands, The (+31)" },
  { country: "New Zealand (+64)" },
  { country: "Nicaragua (+505)" },
  { country: "Niger (+227)" },
  { country: "Nigeria (+234)" },
  { country: "North Macedonia (+389)" },
  { country: "Norway (+47)" },
  { country: "Oman (+968)" },
  { country: "Pakistan (+92)" },
  { country: "Palau (+680)" },
  { country: "Panama (+507)" },
  { country: "Papua New Guinea (+675)" },
  { country: "Paraguay (+595)" },
  { country: "Peru (+51)" },
  { country: "Philippines (+63)" },
  { country: "Poland (+48)" },
  { country: "Portugal (+351)" },
  { country: "Qatar (+974)" },
  { country: "Romania (+40)" },
  { country: "Russia (+7)" },
  { country: "Rwanda (+250)" },
  { country: "Saint Kitts and Nevis (+1-869)" },
  { country: "Saint Lucia (+1-758)" },
  { country: "Saint Vincent and the Grenadines (+1-784)" },
  { country: "Samoa (+685)" },
  { country: "San Marino (+378)" },
  { country: "Sao Tome and Principe (+239)" },
  { country: "Saudi Arabia (+966)" },
  { country: "Senegal (+221)" },
  { country: "Serbia (+381)" },
  { country: "Seychelles (+248)" },
  { country: "Sierra Leone (+232)" },
  { country: "Singapore (+65)" },
  { country: "Slovakia (+421)" },
  { country: "Slovenia (+386)" },
  { country: "Solomon Islands (+677)" },
  { country: "Somalia (+252)" },
  { country: "South Africa (+27)" },
  { country: "South Sudan (+211)" },
  { country: "Spain (+34)" },
  { country: "Sri Lanka (+94)" },
  { country: "Sudan (+249)" },
  { country: "Suriname (+597)" },
  { country: "Sweden (+46)" },
  { country: "Switzerland (+41)" },
  { country: "Syria (+963)" },
  { country: "Taiwan (+886)" },
  { country: "Tajikistan (+992)" },
  { country: "Tanzania (+255)" },
  { country: "Thailand (+66)" },
  { country: "Togo (+228)" },
  { country: "Tonga (+676)" },
  { country: "Trinidad and Tobago (+1-868)" },
  { country: "Tunisia (+216)" },
  { country: "Turkey (+90)" },
  { country: "Turkmenistan (+993)" },
  { country: "Tuvalu (+688)" },
  { country: "Uganda (+256)" },
  { country: "Ukraine (+380)" },
  { country: "United Arab Emirates (+971)" },
  { country: "United Kingdom (+44)" },
  { country: "United States (+1)" },
  { country: "Uruguay (+598)" },
  { country: "Uzbekistan (+998)" },
  { country: "Vanuatu (+678)" },
  { country: "Vatican City (+379)" },
  { country: "Venezuela (+58)" },
  { country: "Vietnam (+84)" },
  { country: "Yemen (+967)" },
  { country: "Zambia (+260)" },
  { country: "Zimbabwe (+263)" },
  { country: "Abkhazia (+995-44)" },
  { country: "Cook Islands (+682)" },
  { country: "Kosovo (+383)" },
  { country: "Niue (+683)" },
  { country: "Northern Cyprus (+90)" },
  { country: "Somaliland (+252)" },
  { country: "South Ossetia (+995-34)" },
  { country: "Taiwan (+886)" },
  { country: "Transnistria (+373)" },
  { country: "Western Sahara (+212)" },
  { country: "State of Palestine (+970)" },
  { country: "Aland Islands (+358-18)" },
  { country: "Faroe Islands (+298)" },
  { country: "Svalbard (+47)" },
  { country: "Saint Barthelemy (+590)" },
  { country: "Saint Martin (+590)" },
  { country: "Curacao (+599)" },
  { country: "Sint Maarten (+1-721)" },
  { country: "Aruba (+297)" },
  { country: "South Sudan (+211)" },
  { country: "Christmas Island (+61)" },
  { country: "Cocos (Keeling) Islands (+61)" },
  { country: "Norfolk Island (+672)" },
  { country: "Bouvet Island (+47)" },
  { country: "Heard Island and McDonald Islands (+672)" },
  { country: "United States Minor Outlying Islands (+1)" },
  { country: "American Samoa (+1-684)" },
  { country: "Guam (+1-671)" },
  { country: "Northern Mariana Islands (+1-670)" },
  { country: "Puerto Rico (+1-787, +1-939)" },
  { country: "U.S. Virgin Islands (+1-340)" },
  { country: "Hong Kong (+852)" },
  { country: "Macau (+853)" },
  { country: "Falkland Islands (+500)" },
  { country: "South Georgia and the South Sandwich Islands (+500)" },
  { country: "French Southern and Antarctic Lands (+262)" },
  { country: "Clipperton Island (+262)" },
  { country: "French Polynesia (+689)" },
  { country: "Wallis and Futuna (+681)" },
  { country: "New Caledonia (+687)" },
  { country: "French Southern and Antarctic Lands (+262)" },
  { country: "Mayotte (+262)" },
  { country: "Saint Helena, Ascension and Tristan da Cunha (+290)" },
  { country: "Eritrea (+291)" },
  { country: "Monaco (+377)" },
  { country: "Saint Martin (+590)" },
  { country: "San Marino (+378)" },
  { country: "Sint Maarten (+1-721)" },
  { country: "Vatican City (+379)" },
  { country: "Western Sahara (+212)" },
  { country: "Timor-Leste (+670)" },
  { country: "Ascension Island (+247)" },
  { country: "Tristan da Cunha (+290)" },
  { country: "French Southern and Antarctic Lands (+262)" },
  { country: "United Kingdom (+44)" },
  { country: "United States of America (+1)" },
  { country: "Bonaire (+599)" },
  { country: "Saba (+599)" },
  { country: "Sint Eustatius (+599)" },
];
