import {
  Box,
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/Header";
import { getCourse, updateCourse } from "./../../ApiConfig.jsx";

function EditCourse() {
  const navigate = useNavigate();
  const { id } = useParams();
  const initialCourseDetails = {
    bibleCourseName: "",
  };

  const [courseDetails, setCourseDetails] = useState(initialCourseDetails);
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchCourse = async (id) => {
    try {
      const data = await getCourse(id);
      if (data.status) {
        setCourseDetails({
          bibleCourseName: data.result.bibleCourseName,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCourse(id);
  }, [id]);

  const handleFieldChange = (field, value) => {
    setCourseDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
    validateField(field, value);
  };

  const validateField = (field, value) => {
    const fieldSchema = yup.object().shape({
      bibleCourseName: yup.string().required("Course Name is required"),
    });

    fieldSchema
      .validateAt(field, { [field]: value })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: error.message }));
      });
  };

  const updateCourseDetails = async (courseDetails) => {
    const hasErrors = Object.values(errors).some(
      (errorMessage) => errorMessage !== ""
    );

    if (hasErrors) {
      console.log("Errors found. Cannot update.");
      return;
    }

    try {
      setIsLoading(true);

      const body = {
        bibleCourseName: courseDetails.bibleCourseName,
      };
      const res = await updateCourse(id, body);
      if (res.status) {
        setAlertSeverity("success");
        setAlertMessage("Course updated successfully");

        setTimeout(() => {
          navigate("/courses");
        }, 2000);
      }
    } catch (err) {
      console.log(err);
      setAlertSeverity("error");
      setAlertMessage(`Failed to update course: ${err.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`Edit Course ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Course Name *
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={courseDetails.bibleCourseName}
              onChange={(e) =>
                handleFieldChange("bibleCourseName", e.target.value)
              }
              error={!!errors.bibleCourseName}
              helperText={errors.bibleCourseName}
            />
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="end" mt="20px">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",

                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={() => updateCourseDetails(courseDetails)}
            >
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}

export default EditCourse;
