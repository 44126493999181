import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import * as yup from "yup";
import { getWorshipCenter, updateWorshipCenter } from "./../../ApiConfig.jsx";

function EditEvent() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [status, setStatus] = useState("active");
  const [userDetails, setUserDetails] = useState({});
  const [errors, setErrors] = useState({});

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchAudio = async (id) => {
    try {
      const data = await getWorshipCenter(id);
      if (data.status) {
        console.log(data.result);
        setStatus(data.result.status);
        setUserDetails({
          churchName: data.result.church,
          address: data.result.address,
          email: data.result.email,
          tel: data.result.tel,
          noOfUsers: data.result.noOfUsers,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAudio(id);
  }, [id]);

  const handleFieldChange = (field, value) => {
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [field]: value,
    }));
    validateField(field, value);
  };
  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const validateField = (field, value) => {
    const fieldSchema = yup.object().shape({
      churchName: yup.string().required("Name is required"),
      address: yup.string().required("Address is required"),
      email: yup.string().email("Invalid email").required("Email is required"),
      tel: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .max(11, "Phone number must be less than 12 digits")
        .required("Phone No. is required"),

      noOfUsers: yup
        .string()
        .required("No. of users required")
        .matches(/^\d+$/, "No. of users must contain only numbers"),
    });

    fieldSchema
      .validateAt(field, { [field]: value })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: error.message }));
      });
  };

  const updateWorshipCenterHandler = async (userDetails) => {
    if (Object.values(errors).every((error) => error === "")) {
      try {
     
        setIsLoading(true);
        const body = {
          address: userDetails.address,
          email: userDetails.email,
          tel: userDetails.tel,
          noOfUsers: userDetails.noOfUsers,
          status: status,
          church: userDetails.churchName,
        };
         const respondData = await updateWorshipCenter(id, body);
        
        if (respondData.status) {
          setAlertSeverity("success");
          setAlertMessage("Worship center edited successfully");
          setTimeout(() => {
            navigate("/worship-centers");
          }, 2500);
        }
      } catch (err) {
        console.log(err);
        setAlertSeverity("error");
        setAlertMessage(`Failed to edit worship center: ${err.message}`);
      } finally {
        setOpenSnackbar(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`Edit Worship Center ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(userDetails).map(([field, value]) => (
            <Grid item xs={12} md={6} key={field}>
              <Typography variant="subtitle1" fontWeight="bold">
                {field === "churchName" && "Church Name*"}
                {field === "address" && "Address*"}
                {field === "email" && "Email*"}
                {field === "tel" && "Tel*"}
                {field === "noOfUsers" && "No Of Users*"}
              </Typography>
              {field === "start_date" || field === "end_date" ? (
                <TextField
                  type="date"
                  fullWidth
                  variant="filled"
                  value={value}
                  onChange={(e) => handleFieldChange(field, e.target.value)}
                  error={!!errors[field]}
                  helperText={errors[field]}
                />
              ) : field === "start_time" ? (
                <TextField
                  type="time"
                  fullWidth
                  variant="filled"
                  value={value}
                  onChange={(e) => handleFieldChange(field, e.target.value)}
                  error={!!errors[field]}
                  helperText={errors[field]}
                />
              ) : field === "type" ? (
                <FormControl fullWidth variant="filled">
                  <Select
                    value={value}
                    onChange={(e) => handleFieldChange(field, e.target.value)}
                    name={field}
                    error={!!errors[field]}>
                    <MenuItem value="Type1">Free</MenuItem>
                    <MenuItem value="Type2">paid</MenuItem>
                  </Select>
                  {errors[field] && (
                    <Typography variant="caption" color="error">
                      {errors[field]}
                    </Typography>
                  )}
                </FormControl>
              ) : field === "image" ? (
                <Box>
                  <div
                    style={{
                      borderRadius: "4px 4px 0px 0px ",
                      borderBottom: "1px solid white",
                      padding: " 5px 10px",
                      backgroundColor: "#323948bf",
                    }}>
                    <InputLabel htmlFor="eventType">Upload an image</InputLabel>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) =>
                        handleFieldChange(field, e.target.files[0])
                      }
                    />
                  </div>
                </Box>
              ) : (
                <TextField
                  type="text"
                  fullWidth
                  variant="filled"
                  value={value}
                  onChange={(e) => handleFieldChange(field, e.target.value)}
                  error={!!errors[field]}
                  helperText={errors[field]}
                />
              )}
            </Grid>
          ))}

          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={() => updateWorshipCenterHandler(userDetails)}>
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}>
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}

export default EditEvent;
