import {
  AddToQueue,
  Bungalow,
  HealthAndSafety,
  LocalHospital,
  MedicalInformation,
  MedicalInformationOutlined,
  MedicalServices,
  Medication,
  People,
  Sick,
} from "@mui/icons-material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import CarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MicIcon from "@mui/icons-material/MicOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import PlaylistPlayOutlinedIcon from "@mui/icons-material/PlaylistPlayOutlined";
import SchoolIcon from "@mui/icons-material/School";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
const Item = ({ title, to, icon, selected, setSelected, children }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const handleSubmenuToggle = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  return (
    <>
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
        }}
        onClick={() => {
          setSelected(title);
          handleSubmenuToggle();
        }}
        icon={icon}
      >
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
      {isSubmenuOpen && children}
    </>
  );
};

const Subtopic = ({ title, to, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
        textAlign: "center",
      }}
      onClick={() => setSelected(title)}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "10px 30px 5px 10px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "30px 20 20px 20px",
              color: colors.grey[100],
              textAlign: "center",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="30px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  JCGN
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box
            pl={!isCollapsed ? "20px" : undefined}
            alignItems="left"
            sx={{ marginTop: "20px" }}
            width={!isCollapsed ? "calc(100% + 40px)" : undefined}
          >
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="User Management"
              to="/user"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Subscription Options"
              to="/subscription"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <Subtopic
                title="Subscribed Users"
                to="/subscribedusers"
                selected={selected}
                setSelected={setSelected}
              />{" "}
            </Item>
            <Item
              title="Dedication Logs"
              to="/dedication"
              icon={<CategoryOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Appointment Logs"
              to="/appointment"
              icon={<EventAvailableOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="News"
              to="/news"
              icon={<MicIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Activity Report"
              to="/activityreport"
              icon={<AnalyticsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Forms Submitted By User"
              to="/formsuser"
              icon={<ListAltOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              {/*               <Subtopic
                title="Forms Submitted By User"
                to="/formsuser"
                selected={selected}
                setSelected={setSelected}
              /> */}
              <Subtopic
                title="New Service Requests"
                to="/newserviceuser"
                selected={selected}
                setSelected={setSelected}
              />
            </Item>
            <Item
              title="Careers"
              to="/careers"
              icon={<BusinessCenterOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <Subtopic
                title="Career repliers from users"
                to="/careers/careerrepliers"
                icon={<BusinessCenterOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />{" "}
            </Item>
            <Item
              title="Payment Logs"
              to="/paymentlogs"
              icon={<PaymentOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pre Tour Registrations"
              to="/tour"
              icon={<CarOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <Subtopic
                title="Tour Registrations"
                to="/tour/register"
                icon={<BusinessCenterOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </Item>
            <Item
              title="Event Management"
              to="/event"
              icon={<EmojiEventsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Course Management"
              to="/courses"
              icon={<SchoolIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <Subtopic
                title="Module Management"
                to="/module "
                selected={selected}
                setSelected={setSelected}
              />
            </Item>
            <Item
              title="CMS Management"
              to="/cms"
              icon={<SettingsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Sermons Management"
              to="/sermons"
              icon={<PlaylistPlayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Templates"
              to="/templates"
              icon={<PlaylistAddCheckOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Membership Card Management"
              to="/membership"
              icon={<SettingsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Feedbacks"
              to="/feedback"
              icon={<FeedbackOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Worship Centers"
              to="/worship-centers"
              icon={<GroupOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Worship Service Management "
              to="/worship-service-management"
              icon={<SettingsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="Live Stream"
              to="/live"
              icon={<LiveTvOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Video Management"
              to="/video-management"
              icon={<VideoLibraryOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Audio Management"
              to="/audio-management"
              icon={<MicIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Artist Management"
              to="/artist-management"
              icon={<BusinessCenterOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Vote Management"
              to="/vote-management"
              icon={<HowToVoteIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="AfyaBora Bookings"
              to="/afyaborabookings"
              icon={<LocalPharmacyIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Burial Expenses Claim"
              to="/burialexpensesclaim"
              icon={<AccountBalanceIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Hospitals And Health Centers"
              to="/hospitalsAndHealthCenters"
              icon={<LocalHospital />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pharmacy"
              to="/pharmacyManagement"
              icon={<MedicalInformation />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Covered Care Services"
              to="/coveredCareServicesManagement"
              icon={<People />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Doctors"
              to="/doctorManagement"
              icon={<HealthAndSafety />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Specialist Doctor"
              to="/specialistDoctorManagement"
              icon={<MedicalServices />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Covered Medications And Drugs"
              to="/coveredMedicationsAndDrugsManagement"
              icon={<MedicalInformationOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Funerals and Mortuaries Homes"
              to="/funeralsAndMortuariesHomesManagement"
              icon={<AddToQueue />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Maladies And Conditions"
              to="/maladiesAndConditionsManagement"
              icon={<Sick />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Fee Schedules for Hospital Services"
              to="/feeSchedulesForHospitalServicesManagement"
              icon={<LocalHospital />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Fee Schedules for Medication"
              to="/feeScheduleForMedicationManagement"
              icon={<Medication />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Fee Schedules for Funeral Home"
              to="/feeSchedulesForFuneralHomeManagement"
              icon={<Bungalow />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Contact Us"
              to="/contactUs"
              icon={<PhoneIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Blogs"
              to="/blogs"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
