import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import axios from "axios";
import { getContent } from "./../../ApiConfig.jsx";


function ViewAudio() {
  const { id } = useParams();
  const [audioDetails, setAudioDetails] = useState({});
  
  const fetchContent = async (id) => {
    try {
      const data = await getContent(id);
      if (data.status) {
        console.log(data.result);
        setAudioDetails(data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchContent(id);
  }, [id]);

  return (
    <div>
      {" "}
      <Box m="20px" height="70vh" overflow="auto">
        <Header title={`View Content ${id}`} subtitle="" />
        <Box ml={"40px"}>
          <Grid container>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Page Title:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{audioDetails.pageTitle}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}>
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Parent:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{audioDetails.parent}</Typography>
              </Grid>
            </div>
          </Grid>
          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}>
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Description:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{audioDetails.description}</Typography>
              </Grid>
            </div>
          </Grid>

          {audioDetails.imageURL && (
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}>
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Image:
                  </Typography>
                </Grid>
                <img
                  style={{ maxWidth: "400px", maxHeight: "400px" }}
                  src={audioDetails.imageURL}
                  alt=""
                />
              </div>
            </Grid>
          )}
        </Box>
      </Box>
    </div>
  );
}

export default ViewAudio;
