import { Box, Button, Snackbar, TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/Header";

const EditModule = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const { id } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    credits: "",
  });

  useEffect(() => {
    const fetchModuleData = async () => {
      try {
        const response = await axios.get(
          `https://jcgnapi.hasthiya.org/api/bibalModule/getBibleModuleById/${id}`
        );
        const moduleData = response.data;
        setInitialValues({
          name: moduleData.result.bibleCourseModule,
          credits: moduleData.result.bibleCourseModuleCredit,
        });
      } catch (error) {
        setAlertSeverity("error");
        setAlertMessage(`Failed to fetch module data: ${error.message}`);
        setOpenSnackbar(true);
      }
    };

    fetchModuleData();
  }, [id]);

  const handleFormSubmit = async (values) => {
    try {
      setIsLoading(true);

      const body = {
        bibleCourseModule: values.name,
        bibleCourseModuleCredit: values.credits,
        bibleCourseId: 0,
      };
      const response = await axios.put(
        `https://jcgnapi.hasthiya.org/api/bibalModule/updateBibleModuleByID/${id}`,
        body
      );
      if (response.data.status) {
        setAlertSeverity("success");
        setAlertMessage("Module updated successfully");
        setTimeout(() => {
          navigate("/module");
        }, 2500);
      } else {
        throw new Error("Failed to update module");
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to update module: ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px">
      <Header title={`Edit Module ID ${id}`} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} height="41vh">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Module Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Module Credits"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.credits}
                  name="credits"
                  error={!!touched.credits && !!errors.credits}
                  helperText={touched.credits && errors.credits}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
              >
                {isLoading ? "Updating..." : "Update"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("Module Name is required"),
  credits: yup
    .number()
    .required("Module Credits are required")
    .min(1, "Credits must be at least 1"),
});

export default EditModule;
