import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { getContact } from "./../../ApiConfig.jsx";

function ViewContact() {
  const { id } = useParams();
  const [contactDetails, setContactDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchContact = async (id) => {
    try {
      const data = await getContact(id);
      if (data.status) {
        console.log(data.result);
        setContactDetails(data.result);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContact(id);
  }, [id]);

  if (loading) {
    return <Typography variant="h5">Loading...</Typography>;
  }

  if (!contactDetails) {
    return <Typography variant="h5">Contact not found</Typography>;
  }

  const capitalizeFirstLetter = (string) => {
    return (
      string.charAt(0).toUpperCase() +
      string
        .slice(1)
        .replace(/([A-Z])/g, " $1")
        .trim()
    );
  };

  const handleDownload = (fileUrl) => {
    if (fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;

      const cleanFileName = (url) => {
        const fileNameWithTimestamp = decodeURIComponent(url.split(".pdf")[0]);
        return fileNameWithTimestamp.trim() + ".pdf";
      };

      const fileName = cleanFileName(fileUrl);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("No file available for download.");
    }
  };

  const renderDownloadButtons = () => {
    if (
      Array.isArray(contactDetails.fileUrl) &&
      contactDetails.fileUrl.length > 0
    ) {
      return contactDetails.fileUrl.map((url, index) => (
        <Grid container key={index} spacing={2} marginTop={"8px"}>
          <Grid item xs={1.8}>
            <Typography variant="h5" component="span" fontWeight="bold">
              Download Contact Report {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h5" component="span" fontWeight="bold">
              :
            </Typography>
          </Grid>
          <Grid item xs={9.2}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "11px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={() => handleDownload(url)}
            >
              Download
            </Button>
          </Grid>
        </Grid>
      ));
    } else if (contactDetails.fileUrl) {
      return (
        <Grid container spacing={2} marginTop={"8px"} marginLeft={0.1}>
          <Grid item xs={1.8}>
            <Typography variant="h5" component="span" fontWeight="bold">
              Contact Report
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h5" component="span" fontWeight="bold">
              :
            </Typography>
          </Grid>
          <Grid item xs={9.2}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "11px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={() => handleDownload(contactDetails.fileUrl)}
            >
              Download Contact Report
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`View Contact-Us Report ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(contactDetails)
            .filter(([field]) => field !== "id" && field !== "fileUrl")
            .map(([field, value]) => (
              <React.Fragment key={field}>
                <Grid item xs={1.8}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    {capitalizeFirstLetter(field)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    :
                  </Typography>
                </Grid>
                <Grid item xs={9.2}>
                  <Typography>{value}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          {renderDownloadButtons()}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewContact;
