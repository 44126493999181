import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_FORM_FIELDS_BY_ID } from "../../ApiConfig";
import Header from "../../components/Header";

function NewFormsUserView() {
  const { id } = useParams();
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await fetch(GET_FORM_FIELDS_BY_ID(id));
        if (!response.ok) {
          throw new Error(`Failed to fetch form data for ID ${id}`);
        }
        const responseData = await response.json();
        if (responseData.success && responseData.form) {
          setFormData(responseData.form);
        } else {
          console.error("Failed to fetch form data:", responseData);
        }
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    fetchFormData();
  }, [id]);

  const renderFieldLabel = (fieldName) => {
    return fieldName
      .split(/(?=[A-Z])/)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
  };

  const renderFormattedValue = (value) => {
    if (value === null || value === "") {
      return null;
    } else if (typeof value === "object") {
      return JSON.stringify(value);
    } else if (typeof value === "string" && value.includes("T")) {
      const date = new Date(value);
      return date.toLocaleDateString() + " " + date.toLocaleTimeString();
    } else {
      return value;
    }
  };

  if (!formData) {
    return <Typography>Loading...</Typography>;
  }

  const { addAnotherArea, ...otherFields } = formData;
  const filteredFields = Object.entries(otherFields).filter(
    ([_, value]) => value !== null && value !== ""
  );

  // Only push 'addAnotherArea' if it's not null or empty
  if (addAnotherArea !== null && addAnotherArea !== "") {
    filteredFields.push(["addAnotherArea", addAnotherArea]);
  }

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`View Request User ID ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {filteredFields.map(([field, value]) => (
            <Grid item xs={12} key={field}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography
                    variant="h5"
                    component="span"
                    fontWeight="bold"
                    mt={3}
                  >
                    {renderFieldLabel(field)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="h5"
                    component="span"
                    fontWeight="bold"
                    mt={3}
                  >
                    :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h6" component="span" mt={3}>
                    {renderFormattedValue(value)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default NewFormsUserView;
