import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";

function ViewActivityReport() {
  const { id } = useParams();
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await fetch(
          `https://jcgnapi.hasthiya.org/activityForm/getFormFieldsById/${id}`
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch form data for ID ${id}`);
        }
        const responseData = await response.json();
        if (responseData.success && responseData.form) {
          setFormData(responseData.form);
        } else {
          console.error("Failed to fetch form data:", responseData);
        }
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    fetchFormData();
  }, [id]);

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatCurrency = (amountString) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(Number(amountString.replace(/[^0-9.-]+/g, "")));
  };

  const formatFieldLabel = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .replace(" Aa", " A"); // Fix for double 'A' in "Head AQuarters"
  };

  const handleDownloadResume = (reportFile) => {
    const anchor = document.createElement("a");
    anchor.href = reportFile;
    anchor.download = "Activity_Report.pdf";
    anchor.click();
  };

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    return date.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };

  const formatUpdatedAt = (updatedAt) => {
    const date = new Date(updatedAt);
    return date.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };

  const renderValue = (field, value) => {
    if (value === null) {
      return "N/A";
    } else if (field.toLowerCase() === "createdat") {
      return formatCreatedAt(value);
    } else if (field.toLowerCase() === "updatedat") {
      return formatUpdatedAt(value);
    } else if (
      field.toLowerCase() === "periodfrom" ||
      field.toLowerCase() === "periodto"
    ) {
      return formatDateTime(value);
    } else if (typeof value === "string" && value.includes("T")) {
      return field.toLowerCase().includes("date")
        ? formatDateTime(value)
        : value;
    } else if (
      field.toLowerCase().includes("amount") ||
      field.toLowerCase().includes("budget") ||
      field.toLowerCase().includes("balance")
    ) {
      return formatCurrency(value);
    } else if (field.toLowerCase() === "reportfile" && value === null) {
      return "No document has been uploaded";
    } else {
      return value;
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`View Activity Report ID ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {formData &&
            Object.entries(formData).map(([field, value]) => (
              <Grid item xs={12} key={field}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography
                      variant="h5"
                      component="span"
                      fontWeight="bold"
                      mt={3}
                    >
                      {formatFieldLabel(field)}
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={1}>
                    <Typography fontWeight="bold">:</Typography>{" "}
                  </Grid>
                  <Grid item xs={8}>
                    {field.toLowerCase() === "reportfile" ? (
                      value ? (
                        <Button
                          onClick={() => handleDownloadResume(value)}
                          sx={{
                            backgroundColor: "#6870fa",
                            color: "white",
                            fontSize: "10px",
                            "&:hover": {
                              backgroundColor: "#3e4396",
                            },
                          }}
                        >
                          Download Activity report Here
                        </Button>
                      ) : (
                        "No document has been uploaded"
                      )
                    ) : (
                      renderValue(field, value)
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewActivityReport;
