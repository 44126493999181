import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, IconButton, Snackbar, Tooltip, useTheme } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteFeedbacks, getAllFeedbacks } from "../../ApiConfig";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const Feedbacks = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const GetAllFeedbacks = async () => {
    try {
      const response = await getAllFeedbacks();

      if (response.status) {
        const sortedData = response.result.sort((a, b) => b.id - a.id);
        setData(sortedData);
      } else {
        setAlertSeverity("error");
        setAlertMessage("Failed to fetch feedbacks.");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error fetching feedback:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to fetch feedbacks: " + error.message);
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    GetAllFeedbacks();
  }, []);

  const handleViewClick = (id) => {
    navigate(`/feedback/view-feedbackById/${id}`);
  };

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this feedback!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteFeedbacks(id);
          if (!response.status) {
            throw new Error("Failed to delete feedback");
          }
          GetAllFeedbacks();
          setAlertSeverity("success");
          setAlertMessage("Feedback deleted successfully");
        } catch (error) {
          console.log(error);
          setAlertSeverity("error");
          setAlertMessage(`Failed to delete feedback: ${error.message}`);
        } finally {
          setOpenSnackbar(true);
        }
      }
    });
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "feedBackType", headerName: "Feedback Type", flex: 1 },
    { field: "description", headerName: "Description", flex: 2 },
    {
      field: "createdAt",
      headerName: "Date Submitted",
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <IconButton onClick={() => handleViewClick(params.row.id)}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Header
          title="Feedbacks"
          subtitle="Showing All The Feedbacks Recieved"
        />
      </Box>

      <Box
        m="-20px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default Feedbacks;
