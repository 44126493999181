import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";

function ViewBurialDetails() {
  const { id } = useParams();
  const [burialDetails, setBurialDetails] = useState({
    id: "",
    docname: "",
    issuedby: "",
    claimAmount: "",
    submitDate: "",
    recivedDate: "",
    approvedDate: "",
    amount: "",
    claimStatus: "",
    createdAt: "",
    updatedAt: "",
  });

  useEffect(() => {
    fetchBurialDetails();
  }, [id]);

  const fetchBurialDetails = async () => {
    try {
      const response = await fetch(
        `https://jcgnapi.hasthiya.org/bural/getAccpetBuralById/${id}`
      );
      const responseData = await response.json();
      if (responseData.status) {
        const modifiedData = {
          ...responseData.result,
          submitDate: responseData.result.submitDate.split("T")[0],
          recivedDate: responseData.result.recivedDate.split("T")[0],
          approvedDate: responseData.result.approvedDate.split("T")[0],
          createdAt: responseData.result.createdAt.split("T")[0],
          updatedAt: responseData.result.updatedAt.split("T")[0],
          claimStatus: capitalizeFirstLetter(responseData.result.claimStatus),
        };
        setBurialDetails(modifiedData);
      } else {
        console.error("Failed to fetch burial details:", responseData.message);
      }
    } catch (error) {
      console.error("Error fetching burial details:", error);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (str) => str.toUpperCase());
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`View Burial ID: ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(burialDetails).map(([field, value]) => (
            <React.Fragment key={field}>
              <Grid item xs={3}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  {capitalizeFirstLetter(field)}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{value}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewBurialDetails;
