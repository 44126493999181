import { SignalWifiStatusbarNullTwoTone, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  InputLabel,
  Snackbar,
  TextField,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import Header from "../../components/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { createContent } from "./../../ApiConfig.jsx";



const CreateNewContent = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate()

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values) => {

    console.log(values);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("pageTitle", values.name);
    formData.append("parent", values.parent);
    formData.append("description", values.description);
    formData.append("cms-image", values.image);
    formData.append("status", "active");

    try {
      const response = await createContent(formData);
      if (response.status) {
        navigate("/cms");
        setAlertSeverity("success");
        setAlertMessage("Content added successfully");
        setTimeout(() => {
          navigate("/cms");
        }, 2500);

      } else {
        setAlertSeverity("error");
        setAlertMessage(`Failed to add new content`);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new content`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px">
      <Header title="Create Content" subtitle="" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} height="41vh">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Parent*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.parent}
                  name="parent"
                  error={!!touched.parent && !!errors.parent}
                  helperText={touched.parent && errors.parent}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Description*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  name="description"
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                />
              </Box>
              <Box>
                <InputLabel htmlFor="eventType">Upload an Image*</InputLabel>
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  onBlur={handleBlur}
                  onChange={(event) => handleChange({ target: { name: 'image', value: event.currentTarget.files[0] } })}
                  name="image"
                  error={!!touched.image && !!errors.image}
                  helperText={touched.image && errors.image}
                />
              </Box>

            </Box>
            {/* Submit Button */}
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  marginRight: 2,
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
              >
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
          >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  parent: yup.string().required("Parent is required"),
  description: yup.string().required("Description is required"),
  image: yup.mixed().required("Image is required").test("fileType", "Please enter a valid image type", (value) => {
    return !value || (value && value.type.startsWith("image/"));
  }),
});

const initialValues = {
  name: "",
  parent: "",
  description: "",
  image: null
};

export default CreateNewContent;
