import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import { getFeeSchedulesForFuneralHomeById } from "./../../ApiConfig.jsx";

function ViewFeeSchedulesForFuneralHome() {
  const { id } = useParams();
  const [itemDetails, setItemDetails] = useState({});
  const fetchData = async (id) => {
    try {
      const data = await getFeeSchedulesForFuneralHomeById(id);
      if (data.status) {
        console.log(data.result);
        setItemDetails({
          Service: data.result.services,
          "Type of service": data.result.typeOfService,
          "Funeral name": data.result.funeralName,
          Covered: data.result.isCovered ? "Yes" : "No",
          "Posted date": new Date(data.result.createdAt)
            .toISOString()
            .split("T")[0],
          Fee: data.result.fee,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header
        title={`View Fee Schedule for Funeral Home ${id}`}
        subtitle=""
      />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(itemDetails).map(([field, value]) => (
            <React.Fragment key={field}>
              <Grid item xs={3}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  {field}:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>{value}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewFeeSchedulesForFuneralHome;
