import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Snackbar,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { getSermon, updateSermon } from "./../../ApiConfig.jsx";
import { SaveAlt as SaveAltIcon } from "@mui/icons-material";

const fieldSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  language: yup.string().required("Language is required"),
  preacher: yup.string().required("Preacher is required"),
  type: yup.string().required("Type is required"),
  videoType: yup.string().required("Video type is required"),
  pdfType: yup.string().required("Note type is required"),
  duration: yup
    .string()
    .required("Duration is required")
    .matches(/^\d+$/, "Duration must contain only numbers"),
  video: yup.mixed(),
  pdf: yup.mixed(),
  videoAmount: yup.string().when("videoType", {
    is: "paid",
    then: yup
      .string()
      .required("Amount is required")
      .matches(/^\d+$/, "Amount must contain only numbers"),
    otherwise: yup.string().nullable(),
  }),
  noteAmount: yup.string().when("pdfType", {
    is: "paid",
    then: yup
      .string()
      .required("Amount is required")
      .matches(/^\d+$/, "Amount must contain only numbers"),
    otherwise: yup.string().nullable(),
  }),
});

function EditAudio() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [status, setStatus] = useState("Inactive");
  const [addedDate, setAddedDate] = useState("");
  const [videoFile, setVideoFile] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [currentVideo, setCurrentVideo] = useState("");
  const [currentPDF, setCurrentPDF] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const initialUserDetails = {
    title: "",
    description: "",
    language: "",
    preacher: "",
    type: "",
    videoType: "",
    pdfType: "",
    duration: "",
    videoAmount: "",
    noteAmount: "",
    video: null,
    pdf: null,
  };

  const [userDetails, setUserDetails] = useState(initialUserDetails);
  const [errors, setErrors] = useState({});

  const fetchAudio = async (id) => {
    try {
      const data = await getSermon(id);
      if (data.status) {
        console.log(data.result);
        setStatus(data.result.status);
        setAddedDate(data.result.addedDate);
        setCurrentPDF(data.result.fileUrl);
        setCurrentVideo(data.result.videoUrl);
        setUserDetails({
          title: data.result.title,
          description: data.result.description,
          language: data.result.language,
          preacher: data.result.preacther,
          type: data.result.type,
          duration: data.result.duration,
          videoType: data.result.video_payment_method,
          pdfType: data.result.note_payment_method,
          videoAmount: data.result.videoAmount,
          noteAmount: data.result.noteAmount,
          video: null,
          pdf: null,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAudio(id);
  }, [id]);

  const handleFieldChange = (field, value) => {
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [field]: value,
    }));
    validateField(field, value);
  };

  const validateField = (field, value) => {
    fieldSchema
      .validateAt(field, { ...userDetails, [field]: value })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: error.message }));
      });
  };

  const updateVideo = async () => {
    try {
      await fieldSchema.validate(userDetails, { abortEarly: false });
      setIsLoading(true);

      const formData = new FormData();
      formData.append("title", userDetails.title);
      formData.append("description", userDetails.description);
      formData.append("video", videoFile);
      formData.append("pdf", pdfFile);
      formData.append("addedDate", addedDate);
      formData.append("status", status);
      formData.append("language", userDetails.language);
      formData.append("duration", userDetails.duration);
      formData.append("preacther", userDetails.preacher);
      formData.append("type", userDetails.type);
      formData.append("video_payment_method", userDetails.videoType);
      formData.append("note_payment_method", userDetails.pdfType);
      formData.append("noteAmount", userDetails.noteAmount?userDetails.noteAmount:"0");
      formData.append("videoAmount", userDetails.videoAmount?userDetails.videoAmount:"0");

      const res = await updateSermon(id, formData);
      if (res.status) {
        setAlertSeverity("success");
        setAlertMessage("Sermon updated successfully");
        setTimeout(() => {
          navigate("/sermons");
        }, 3000);
      }
    } catch (err) {
      console.log(err);
      if (err.inner) {
        const formErrors = err.inner.reduce(
          (acc, validationError) => ({
            ...acc,
            [validationError.path]: validationError.message,
          }),
          {}
        );
        setErrors(formErrors);
      } else {
        setAlertSeverity("error");
        setAlertMessage(`Failed to update sermon: ${err.message}`);
        setOpenSnackbar(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`Edit Sermons ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Title*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.title}
              onChange={(e) => handleFieldChange("title", e.target.value)}
              error={!!errors.title}
              helperText={errors.title}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Description*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.description}
              onChange={(e) => handleFieldChange("description", e.target.value)}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Preacher*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.preacher}
              onChange={(e) => handleFieldChange("preacher", e.target.value)}
              error={!!errors.preacher}
              helperText={errors.preacher}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Duration(Min)*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.duration}
              onChange={(e) => handleFieldChange("duration", e.target.value)}
              error={!!errors.duration}
              helperText={errors.duration}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Type*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={userDetails.type}
                onChange={(e) => handleFieldChange("type", e.target.value)}
                name="type"
                error={!!errors.type}>
                <MenuItem value="Online">Online</MenuItem>
                <MenuItem value="Physical">Physical</MenuItem>
              </Select>
              {errors.type && (
                <Typography variant="caption" color="error">
                  {errors.type}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Language*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={userDetails.language}
                onChange={(e) => handleFieldChange("language", e.target.value)}
                name="language"
                error={!!errors.language}>
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="French">French</MenuItem>
              </Select>
              {errors.language && (
                <Typography variant="caption" color="error">
                  {errors.language}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>Video*</div>
              <TextField
                fullWidth
                variant="filled"
                type="file"
                accept="video/*"
                onChange={(e) => setVideoFile(e.target.files[0])}
                name="video"
                error={!!errors.video}
                helperText={errors.video}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Video type*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={userDetails.videoType}
                onChange={(e) => handleFieldChange("videoType", e.target.value)}
                name="videoType"
                error={!!errors.videoType}>
                <MenuItem value="free">Free</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
              </Select>
              {errors.videoType && (
                <Typography variant="caption" color="error">
                  {errors.videoType}
                </Typography>
              )}
            </FormControl>
          </Grid>
          {userDetails.videoType == "paid" && (
            <Grid item xs={12} md={6}>
              {" "}
              <Box>
                <div style={{ fontWeight: "bold" }}>Video amount*</div>
                <TextField
                  type="text"
                  fullWidth
                  variant="filled"
                  value={userDetails.videoAmount}
                  onChange={(e) =>
                    handleFieldChange("videoAmount", e.target.value)
                  }
                  error={!!errors.videoAmount}
                  helperText={errors.videoAmount}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>Note*</div>
              <TextField
                fullWidth
                variant="filled"
                type="file"
                accept="pdf/*"
                onChange={(e) => setPdfFile(e.target.files[0])}
                name="pdf"
                error={!!errors.pdf}
                helperText={errors.pdf}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Note type*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={userDetails.pdfType}
                onChange={(e) => handleFieldChange("pdfType", e.target.value)}
                name="pdfType"
                error={!!errors.pdfType}>
                <MenuItem value="free">Free</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
              </Select>
              {errors.pdfType && (
                <Typography variant="caption" color="error">
                  {errors.pdfType}
                </Typography>
              )}
            </FormControl>
          </Grid>
          {userDetails.pdfType == "paid" && (
            <Grid item xs={12} md={6}>
              {" "}
              <Box>
                <div style={{ fontWeight: "bold" }}>Note Amount*</div>
                <TextField
                  type="text"
                  fullWidth
                  variant="filled"
                  value={userDetails.noteAmount}
                  onChange={(e) =>
                    handleFieldChange("noteAmount", e.target.value)
                  }
                  error={!!errors.noteAmount}
                  helperText={errors.noteAmount}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>Current PDF</div>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  marginRight: 2,
                  marginBottom: 5,
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
                startIcon={<SaveAltIcon />}
                href={currentPDF}
                download
                style={{
                  textTransform: "none",
                }}>
                Download File
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>Current Video</div>
              <video
                controls
                src={currentVideo}
                alt="Video Thumbnail"
                style={{
                  marginTop: "5px",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={() => updateVideo(userDetails)}>
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}>
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}

export default EditAudio;
