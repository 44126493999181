import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Snackbar,
  IconButton,
  Typography,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import {  useState } from "react";
import * as yup from "yup";
import Header from "../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { createCoveredMedicationsAndDrugs } from "./../../ApiConfig.jsx";

const AddCoveredMedicationsAndDrugs = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values) => {

    setIsLoading(true);
    const body = {
      ...values,
      status: "active",
      isCovered: values.isCovered === "true",
    };
    try {
      const response = await createCoveredMedicationsAndDrugs(body);
      if (response.success==="true") {
        setAlertSeverity("success");
        setAlertMessage("Medication added successfully");
        setTimeout(() => {
          navigate("/coveredMedicationsAndDrugsManagement");
        }, 2500);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new medication : ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title="Add New Medications" subtitle="" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} style={{ height: "41vh" }}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Reference*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.reference}
                  name="reference"
                  error={!!touched.reference && !!errors.reference}
                  helperText={touched.reference && errors.reference}
                />
              </Box>

              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Is covered*</InputLabel>
                  <Select
                    variant="filled"
                    value={values.isCovered}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="isCovered"
                    error={!!touched.isCovered && !!errors.isCovered}
                    helperText={touched.isCovered && errors.isCovered}>
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                  {touched.isCovered && errors.isCovered && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {errors.isCovered}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Drug category*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.drugCategory}
                  name="drugCategory"
                  error={!!touched.drugCategory && !!errors.drugCategory}
                  helperText={touched.drugCategory && errors.drugCategory}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}>
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}>
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("Service is required"),
  reference: yup.string().required("Reference is required"),
  drugCategory: yup.string().required("Drug category name is required"),
  isCovered: yup.string().required("Covered is required"),
});

const initialValues = {
  name: "",
  reference: "",
  drugCategory: "",
  isCovered: "",
};

export default AddCoveredMedicationsAndDrugs;
