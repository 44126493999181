import { Box, Button, Snackbar, TextField, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { FieldArray, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/Header";

const validationSchema = yup.object().shape({
  course_info_instructer_info_name: yup.string().required("Required"),
  course_info_instructer_info_office: yup.string().required("Required"),
  course_info_instructer_info_office_hours: yup.string().required("Required"),
  course_info_instructer_info_tele: yup.string().required("Required"),
  course_info_instructer_info_mail: yup
    .string()
    .email("Invalid email")
    .required("Required"),
  course_info_course_description: yup.string().required("Required"),
  course_info_couse_structure: yup.string().required("Required"),
  course_info_student_learning_outcomes_out: yup.string().required("Required"),

  course_info_required_text: yup
    .array()
    .of(
      yup
        .string()
        .required("Required Text is required")
        .min(2, "Required Text must be at least 2 characters")
    )
    .min(1, "At least one Required Text is required"),
  course_info_course_requirements: yup
    .array()
    .of(
      yup
        .string()
        .required("Course Requirement is required")
        .min(2, "Course Requirement must be at least 2 characters")
    )
    .min(1, "At least one Course Requirement is required"),

  student_learning_topic_outline: yup.array().of(
    yup.object().shape({
      student_learning_topic_week: yup.string().required("Required"),
      student_learning_topic_topic: yup.string().required("Required"),
      student_learning_topic_readings: yup.string().required("Required"),
      student_learning_topic_activities: yup.string().required("Required"),
      student_learning_topic_due_date: yup.string().required("Required"),
    })
  ),
  grading_policy: yup.array().of(
    yup.object().shape({
      grading_policy_Letter_grade_grade: yup.string().required("Required"),
      grading_policy_Letter_grade_percentage: yup.string().required("Required"),
      grading_policy_Letter_grade_performance: yup
        .string()
        .required("Required"),
    })
  ),
});

const CourseInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const navigate = useNavigate();
  const { id } = useParams();
  const initialValues = {
    course_info_instructer_info_name: "",
    course_info_instructer_info_office: "",
    course_info_instructer_info_office_hours: "",
    course_info_instructer_info_tele: "",
    course_info_instructer_info_mail: "",
    course_info_course_description: "",
    course_info_required_text: [""],
    course_info_course_requirements: [""],
    course_info_couse_structure: "",
    bibleModuleId: id,
    course_info_student_learning_outcomes_out: "",
    student_learning_topic_outline: [
      {
        student_learning_topic_week: "",
        student_learning_topic_topic: "",
        student_learning_topic_readings: "",
        student_learning_topic_activities: "",
        student_learning_topic_due_date: "",
      },
    ],
    grading_policy: [
      {
        grading_policy_Letter_grade_grade: "",
        grading_policy_Letter_grade_percentage: "",
        grading_policy_Letter_grade_performance: "",
      },
    ],
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://jcgnapi.hasthiya.org/api/bibalModule/createBibleModuleDetail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );
      const result = await response.json();
      console.log("Success:", result);

      setIsLoading(false);
      setAlertMessage("Form submitted successfully!");
      setAlertSeverity("success");
      setOpenSnackbar(true);

      setTimeout(() => {
        navigate("/module");
      }, 3000);
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      setAlertMessage("Form submission failed.");
      setAlertSeverity("error");
      setOpenSnackbar(true);
    }
  };

  return (
    <Box m="20px" height="75vh" overflow="auto">
      <Header title={`Add Module Details to Module ID ${id}`} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <h2>Course Information</h2>

            <TextField
              fullWidth
              label="Instructor Name"
              name="course_info_instructer_info_name"
              value={values.course_info_instructer_info_name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.course_info_instructer_info_name &&
                Boolean(errors.course_info_instructer_info_name)
              }
              helperText={
                touched.course_info_instructer_info_name &&
                errors.course_info_instructer_info_name
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Office"
              name="course_info_instructer_info_office"
              value={values.course_info_instructer_info_office}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.course_info_instructer_info_office &&
                Boolean(errors.course_info_instructer_info_office)
              }
              helperText={
                touched.course_info_instructer_info_office &&
                errors.course_info_instructer_info_office
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Office Hours"
              name="course_info_instructer_info_office_hours"
              value={values.course_info_instructer_info_office_hours}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.course_info_instructer_info_office_hours &&
                Boolean(errors.course_info_instructer_info_office_hours)
              }
              helperText={
                touched.course_info_instructer_info_office_hours &&
                errors.course_info_instructer_info_office_hours
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Telephone"
              name="course_info_instructer_info_tele"
              value={values.course_info_instructer_info_tele}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.course_info_instructer_info_tele &&
                Boolean(errors.course_info_instructer_info_tele)
              }
              helperText={
                touched.course_info_instructer_info_tele &&
                errors.course_info_instructer_info_tele
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Email"
              name="course_info_instructer_info_mail"
              value={values.course_info_instructer_info_mail}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.course_info_instructer_info_mail &&
                Boolean(errors.course_info_instructer_info_mail)
              }
              helperText={
                touched.course_info_instructer_info_mail &&
                errors.course_info_instructer_info_mail
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Course Description"
              name="course_info_course_description"
              value={values.course_info_course_description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.course_info_course_description &&
                Boolean(errors.course_info_course_description)
              }
              helperText={
                touched.course_info_course_description &&
                errors.course_info_course_description
              }
              margin="normal"
              multiline
              rows={4}
            />
            <TextField
              fullWidth
              label="Course Structure"
              name="course_info_couse_structure"
              value={values.course_info_couse_structure}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.course_info_couse_structure &&
                Boolean(errors.course_info_couse_structure)
              }
              helperText={
                touched.course_info_couse_structure &&
                errors.course_info_couse_structure
              }
              margin="normal"
              multiline
              rows={4}
            />
            <TextField
              fullWidth
              label="Learning Outcomes"
              name="course_info_student_learning_outcomes_out"
              value={values.course_info_student_learning_outcomes_out}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.course_info_student_learning_outcomes_out &&
                Boolean(errors.course_info_student_learning_outcomes_out)
              }
              helperText={
                touched.course_info_student_learning_outcomes_out &&
                errors.course_info_student_learning_outcomes_out
              }
              margin="normal"
              multiline
              rows={4}
            />

            <h2>Student Learning Topics</h2>
            <FieldArray name="course_info_required_text">
              {({ push, remove }) => (
                <Box>
                  {values.course_info_required_text.map((text, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      marginTop="20px"
                    >
                      <TextField
                        fullWidth
                        label={`Required Text ${index + 1}`}
                        name={`course_info_required_text.${index}`}
                        value={text}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.course_info_required_text &&
                          touched.course_info_required_text[index] &&
                          Boolean(
                            errors.course_info_required_text &&
                              errors.course_info_required_text[index]
                          )
                        }
                        helperText={
                          touched.course_info_required_text &&
                          touched.course_info_required_text[index] &&
                          errors.course_info_required_text &&
                          errors.course_info_required_text[index]
                        }
                      />
                      <Button
                        type="button"
                        variant="contained"
                        sx={{
                          backgroundColor: "#6870fa",
                          margin: "5px",
                        }}
                        onClick={() => remove(index)}
                      >
                        Remove
                      </Button>
                    </Box>
                  ))}
                  <Button
                    type="button"
                    variant="contained"
                    sx={{
                      backgroundColor: "#6870fa",
                      margin: "5px",
                    }}
                    onClick={() => push("")}
                  >
                    Add Text
                  </Button>
                </Box>
              )}
            </FieldArray>

            <FieldArray name="course_info_course_requirements">
              {({ push, remove }) => (
                <Box>
                  {values.course_info_course_requirements.map((req, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      mt="20px"
                    >
                      <TextField
                        fullWidth
                        label={`Course Requirement ${index + 1}`}
                        name={`course_info_course_requirements.${index}`}
                        value={req}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.course_info_course_requirements &&
                          touched.course_info_course_requirements[index] &&
                          Boolean(
                            errors.course_info_course_requirements &&
                              errors.course_info_course_requirements[index]
                          )
                        }
                        helperText={
                          touched.course_info_course_requirements &&
                          touched.course_info_course_requirements[index] &&
                          errors.course_info_course_requirements &&
                          errors.course_info_course_requirements[index]
                        }
                      />
                      <Button
                        type="button"
                        variant="contained"
                        sx={{
                          backgroundColor: "#6870fa",
                          margin: "5px",
                        }}
                        onClick={() => remove(index)}
                      >
                        {" "}
                        Remove
                      </Button>
                    </Box>
                  ))}
                  <Button
                    type="button"
                    variant="contained"
                    sx={{
                      backgroundColor: "#6870fa",
                      margin: "5px",
                    }}
                    onClick={() => push("")}
                  >
                    Add Requirement
                  </Button>
                </Box>
              )}
            </FieldArray>

            <FieldArray
              name="student_learning_topic_outline"
              render={(arrayHelpers) => (
                <div>
                  {values.student_learning_topic_outline.map((topic, index) => (
                    <div key={index}>
                      <h3>Topic {index + 1}</h3>
                      <TextField
                        fullWidth
                        label="Week"
                        name={`student_learning_topic_outline[${index}].student_learning_topic_week`}
                        value={topic.student_learning_topic_week}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.student_learning_topic_outline &&
                          touched.student_learning_topic_outline[index] &&
                          Boolean(
                            errors.student_learning_topic_outline &&
                              errors.student_learning_topic_outline[index] &&
                              errors.student_learning_topic_outline[index]
                                .student_learning_topic_week
                          )
                        }
                        helperText={
                          touched.student_learning_topic_outline &&
                          touched.student_learning_topic_outline[index] &&
                          errors.student_learning_topic_outline &&
                          errors.student_learning_topic_outline[index] &&
                          errors.student_learning_topic_outline[index]
                            .student_learning_topic_week
                        }
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Topic"
                        name={`student_learning_topic_outline[${index}].student_learning_topic_topic`}
                        value={topic.student_learning_topic_topic}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.student_learning_topic_outline &&
                          touched.student_learning_topic_outline[index] &&
                          Boolean(
                            errors.student_learning_topic_outline &&
                              errors.student_learning_topic_outline[index] &&
                              errors.student_learning_topic_outline[index]
                                .student_learning_topic_topic
                          )
                        }
                        helperText={
                          touched.student_learning_topic_outline &&
                          touched.student_learning_topic_outline[index] &&
                          errors.student_learning_topic_outline &&
                          errors.student_learning_topic_outline[index] &&
                          errors.student_learning_topic_outline[index]
                            .student_learning_topic_topic
                        }
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Readings"
                        name={`student_learning_topic_outline[${index}].student_learning_topic_readings`}
                        value={topic.student_learning_topic_readings}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.student_learning_topic_outline &&
                          touched.student_learning_topic_outline[index] &&
                          Boolean(
                            errors.student_learning_topic_outline &&
                              errors.student_learning_topic_outline[index] &&
                              errors.student_learning_topic_outline[index]
                                .student_learning_topic_readings
                          )
                        }
                        helperText={
                          touched.student_learning_topic_outline &&
                          touched.student_learning_topic_outline[index] &&
                          errors.student_learning_topic_outline &&
                          errors.student_learning_topic_outline[index] &&
                          errors.student_learning_topic_outline[index]
                            .student_learning_topic_readings
                        }
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Activities"
                        name={`student_learning_topic_outline[${index}].student_learning_topic_activities`}
                        value={topic.student_learning_topic_activities}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.student_learning_topic_outline &&
                          touched.student_learning_topic_outline[index] &&
                          Boolean(
                            errors.student_learning_topic_outline &&
                              errors.student_learning_topic_outline[index] &&
                              errors.student_learning_topic_outline[index]
                                .student_learning_topic_activities
                          )
                        }
                        helperText={
                          touched.student_learning_topic_outline &&
                          touched.student_learning_topic_outline[index] &&
                          errors.student_learning_topic_outline &&
                          errors.student_learning_topic_outline[index] &&
                          errors.student_learning_topic_outline[index]
                            .student_learning_topic_activities
                        }
                        margin="normal"
                      />
                      <Typography
                        sx={{ marginTop: "10px", marginBottom: "-10px" }}
                      >
                        Due Date
                      </Typography>

                      <TextField
                        fullWidth
                        type="date"
                        name={`student_learning_topic_outline[${index}].student_learning_topic_due_date`}
                        value={topic.student_learning_topic_due_date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.student_learning_topic_outline &&
                          touched.student_learning_topic_outline[index] &&
                          Boolean(
                            errors.student_learning_topic_outline &&
                              errors.student_learning_topic_outline[index] &&
                              errors.student_learning_topic_outline[index]
                                .student_learning_topic_due_date
                          )
                        }
                        helperText={
                          touched.student_learning_topic_outline &&
                          touched.student_learning_topic_outline[index] &&
                          errors.student_learning_topic_outline &&
                          errors.student_learning_topic_outline[index] &&
                          errors.student_learning_topic_outline[index]
                            .student_learning_topic_due_date
                        }
                        margin="normal"
                      />
                      <Button
                        type="button"
                        variant="contained"
                        sx={{
                          backgroundColor: "#6870fa",
                          margin: "5px",
                        }}
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        Remove Topic
                      </Button>
                    </div>
                  ))}
                  <Button
                    type="button"
                    variant="contained"
                    sx={{
                      backgroundColor: "#6870fa",
                      margin: "5px",
                    }}
                    onClick={() =>
                      arrayHelpers.push({
                        student_learning_topic_week: "",
                        student_learning_topic_topic: "",
                        student_learning_topic_readings: "",
                        student_learning_topic_activities: "",
                        student_learning_topic_due_date: "",
                      })
                    }
                  >
                    Add Topic
                  </Button>
                </div>
              )}
            />

            <h2>Grading Policy</h2>
            <FieldArray
              name="grading_policy"
              render={(arrayHelpers) => (
                <div>
                  {values.grading_policy.map((grade, index) => (
                    <div key={index}>
                      <h3>Grade {index + 1}</h3>
                      <TextField
                        fullWidth
                        label="Grade"
                        name={`grading_policy[${index}].grading_policy_Letter_grade_grade`}
                        value={grade.grading_policy_Letter_grade_grade}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.grading_policy &&
                          touched.grading_policy[index] &&
                          Boolean(
                            errors.grading_policy &&
                              errors.grading_policy[index] &&
                              errors.grading_policy[index]
                                .grading_policy_Letter_grade_grade
                          )
                        }
                        helperText={
                          touched.grading_policy &&
                          touched.grading_policy[index] &&
                          errors.grading_policy &&
                          errors.grading_policy[index] &&
                          errors.grading_policy[index]
                            .grading_policy_Letter_grade_grade
                        }
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Percentage"
                        name={`grading_policy[${index}].grading_policy_Letter_grade_percentage`}
                        value={grade.grading_policy_Letter_grade_percentage}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.grading_policy &&
                          touched.grading_policy[index] &&
                          Boolean(
                            errors.grading_policy &&
                              errors.grading_policy[index] &&
                              errors.grading_policy[index]
                                .grading_policy_Letter_grade_percentage
                          )
                        }
                        helperText={
                          touched.grading_policy &&
                          touched.grading_policy[index] &&
                          errors.grading_policy &&
                          errors.grading_policy[index] &&
                          errors.grading_policy[index]
                            .grading_policy_Letter_grade_percentage
                        }
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Performance"
                        name={`grading_policy[${index}].grading_policy_Letter_grade_performance`}
                        value={grade.grading_policy_Letter_grade_performance}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.grading_policy &&
                          touched.grading_policy[index] &&
                          Boolean(
                            errors.grading_policy &&
                              errors.grading_policy[index] &&
                              errors.grading_policy[index]
                                .grading_policy_Letter_grade_performance
                          )
                        }
                        helperText={
                          touched.grading_policy &&
                          touched.grading_policy[index] &&
                          errors.grading_policy &&
                          errors.grading_policy[index] &&
                          errors.grading_policy[index]
                            .grading_policy_Letter_grade_performance
                        }
                        margin="normal"
                      />

                      <Button
                        type="button"
                        variant="contained"
                        sx={{
                          backgroundColor: "#6870fa",
                          margin: "5px",
                        }}
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        Remove Grade
                      </Button>
                    </div>
                  ))}
                  <Button
                    type="button"
                    variant="contained"
                    sx={{
                      backgroundColor: "#6870fa",
                      margin: "5px",
                    }}
                    onClick={() =>
                      arrayHelpers.push({
                        grading_policy_Letter_grade_grade: "",
                        grading_policy_Letter_grade_percentage: "",
                        grading_policy_Letter_grade_performance: "",
                      })
                    }
                  >
                    Add Grade
                  </Button>
                </div>
              )}
            />
            <Box display="flex" justifyContent="end" m="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
                disabled={isLoading}
              >
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default CourseInfo;
