import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/Header";
import { createEvent } from "./../../ApiConfig.jsx";

const CreateNewEvent = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    const formData = new FormData();
    formData.append("type", values.eventType);

    if (values.eventType == "Paid") {
      formData.append("charges", values.eventCharges);
    } else {
      formData.append("charges", "0");
    }
    setIsLoading(true);
    formData.append("name", values.eventName);
    formData.append("start_date", values.startDate);
    formData.append("start_time", values.startTime);
    formData.append("end_date", values.endDate);
    formData.append("location", values.eventLocation);
    formData.append("numOfParticipant", values.eventParticipants);
    formData.append("status", values.status);
    formData.append("event-image", values.image);

    try {
      const response = await createEvent(formData);
      if (response.data.status) {
        setAlertSeverity("success");
        setAlertMessage("Event added successfully");
        setTimeout(() => {
          navigate("/event");
        }, 2500);
      }
    } catch (error) {
      console.log(error);
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new event : ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title="Create Event" subtitle="Create a New Event" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} height="41vh">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Event Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.eventName}
                  name="eventName"
                  error={!!touched.eventName && !!errors.eventName}
                  helperText={touched.eventName && errors.eventName}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Start Date"
                  InputLabelProps={{ shrink: true }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.startDate}
                  name="startDate"
                  error={!!touched.startDate && !!errors.startDate}
                  helperText={touched.startDate && errors.startDate}
                />
              </Box>
              <Box>
                <TextField
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      colorScheme: "light",
                    },
                  }}
                  fullWidth
                  variant="filled"
                  type="date"
                  label="End Date"
                  InputLabelProps={{ shrink: true }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.endDate}
                  name="endDate"
                  error={!!touched.endDate && !!errors.endDate}
                  helperText={touched.endDate && errors.endDate}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="time"
                  label="Start Time"
                  InputLabelProps={{ shrink: true }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.startTime}
                  name="startTime"
                  error={!!touched.startTime && !!errors.startTime}
                  helperText={touched.startTime && errors.startTime}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Event Location"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.eventLocation}
                  name="eventLocation"
                  error={!!touched.eventLocation && !!errors.eventLocation}
                  helperText={touched.eventLocation && errors.eventLocation}
                />
              </Box>

              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Event Paricipants"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.eventParticipants}
                  name="eventParticipants"
                  error={
                    !!touched.eventParticipants && !!errors.eventParticipants
                  }
                  helperText={
                    touched.eventParticipants && errors.eventParticipants
                  }
                />
              </Box>

              <Box>
                <InputLabel htmlFor="eventType">Event Type*</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Event Type"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.eventType}
                  name="eventType"
                  error={!!touched.eventType && !!errors.eventType}
                  helperText={touched.eventType && errors.eventType}
                >
                  <MenuItem value="Select Event Type" selected disabled>
                    Select Event Type
                  </MenuItem>
                  <MenuItem value="Free">Free</MenuItem>
                  <MenuItem value="Paid">Paid</MenuItem>
                </Select>
                {touched.eventType && errors.eventType && (
                  <FormHelperText
                    style={{ color: "#f44336", paddingLeft: "15px" }}
                  >
                    {errors.eventType}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <InputLabel htmlFor="status">Status*</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Status"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.status}
                  name="status"
                  error={!!touched.status && !!errors.status}
                  helperText={touched.status && errors.status}
                >
                  <MenuItem value="Select Event Type" selected disabled>
                    Select status
                  </MenuItem>
                  <MenuItem value="Upcoming">Upcoming</MenuItem>
                  <MenuItem value="Booked/Active">Booked/Active</MenuItem>
                  <MenuItem value="Cancelled">Cancelled</MenuItem>
                  <MenuItem value="Last Events">Last Events</MenuItem>
                </Select>
                {touched.status && errors.status && (
                  <FormHelperText
                    style={{ color: "#f44336", paddingLeft: "15px" }}
                  >
                    {errors.status}
                  </FormHelperText>
                )}
              </Box>

              {values.eventType == "Paid" && (
                <Box>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Event Charges"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.eventCharges}
                    name="eventCharges"
                    error={!!touched.eventCharges && !!errors.eventCharges}
                    helperText={touched.eventCharges && errors.eventCharges}
                  />
                </Box>
              )}
              <Box>
                <InputLabel htmlFor="eventType">Upload an Image*</InputLabel>
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  onChange={(event) =>
                    handleChange({
                      target: {
                        name: "image",
                        value: event.currentTarget.files[0],
                      },
                    })
                  }
                  name="image"
                  error={!!touched.image && !!errors.image}
                  helperText={touched.image && errors.image}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
              >
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  eventName: yup.string().required("Event Name is required"),
  status: yup.string().required("Status is required"),

  startDate: yup
    .date()
    .min(new Date(), "Start Date must be in the future")
    .required("Start Date is required"),
  endDate: yup
    .date()
    .min(yup.ref("startDate"), "End Date must be after Start Date")
    .required("End Date is required"),
  startTime: yup.string().required("Start Time is required"),
  eventLocation: yup.string().required("Event Location is required"),
  eventParticipants: yup
    .string()
    .required("Event Participants amount is required"),
  eventType: yup.string().required("Event Type is required"),
  eventCharges: yup.string().when("eventType", {
    is: (val) => val === "Paid",
    then: yup
      .string()
      .required("Amount is required")
      .matches(/^\d+$/, "Amount must contain only numbers"),
    otherwise: yup.string(),
  }),
  image: yup
    .mixed()
    .required("Image is required")
    .test("fileType", "Please enter a valid image type", (value) => {
      return !value || (value && value.type.startsWith("image/"));
    }),
});

const initialValues = {
  status: "",
  eventName: "",
  startDate: "",
  endDate: "",
  startTime: "",
  eventLocation: "",
  eventParticipants: "",
  eventType: "",
  eventCharges: "",
  image: null,
};

export default CreateNewEvent;
