import { Box, Divider, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";

const formatFieldName = (field) => {
  return field
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .replace(/_/g, " ");
};

const filterUnnecessaryFields = (details) => {
  const filtered = {};
  for (const [key, value] of Object.entries(details)) {
    if (
      key !== "id" &&
      !key.includes("bibleModuleId") &&
      !key.includes("bibleModuleDataId")
    ) {
      if (typeof value === "object" && !Array.isArray(value)) {
        filtered[key] = filterUnnecessaryFields(value);
      } else if (Array.isArray(value)) {
        filtered[key] = value.map((item) => filterUnnecessaryFields(item));
      } else {
        filtered[key] = value;
      }
    }
  }
  return filtered;
};

const getLastCreatedAtUpdatedAt = (details) => {
  const createdAtDates = [];
  const updatedAtDates = [];

  const extractDates = (obj) => {
    for (const [key, value] of Object.entries(obj)) {
      if (key === "createdAt" && value) createdAtDates.push(new Date(value));
      if (key === "updatedAt" && value) updatedAtDates.push(new Date(value));
      if (typeof value === "object") {
        if (Array.isArray(value)) {
          value.forEach((item) => extractDates(item));
        } else {
          extractDates(value);
        }
      }
    }
  };

  extractDates(details);

  return {
    createdAt: new Date(Math.max(...createdAtDates)).toLocaleDateString(),
    updatedAt: new Date(Math.max(...updatedAtDates)).toLocaleDateString(),
  };
};

function ViewCourse() {
  const { id } = useParams();
  const [courseDetails, setCourseDetails] = useState(null);

  useEffect(() => {
    const getCourseData = async () => {
      try {
        const res = await axios.get(
          `https://jcgnapi.hasthiya.org/api/bible-course/getBibleFullDetailsByCourseId/${id}`
        );
        if (res.data.status) {
          const { result } = res.data;
          setCourseDetails(result);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getCourseData();
  }, [id]);

  const formatDate = (dateString) => {
    if (dateString) {
      return new Date(dateString).toLocaleDateString();
    }
    return "N/A";
  };

  const renderDetails = (details) => {
    const { createdAt: lastCreatedAt, updatedAt: lastUpdatedAt } =
      getLastCreatedAtUpdatedAt(details);
    const filteredDetails = filterUnnecessaryFields(details);

    return Object.entries(filteredDetails).map(([key, value]) => {
      if (typeof value === "object" && !Array.isArray(value)) {
        return (
          <React.Fragment key={key}>
            {Object.entries(value).map(([subKey, subValue]) => (
              <Grid container spacing={2} alignItems="flex-start" key={subKey}>
                <Grid item xs={4}>
                  <Typography variant="h6" fontWeight="semi-bold">
                    {formatFieldName(subKey)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {subKey === "createdAt" || subKey === "updatedAt"
                      ? formatDate(
                          subValue === lastCreatedAt ? lastCreatedAt : subValue
                        )
                      : subValue}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            <Divider sx={{ my: 2 }} />
          </React.Fragment>
        );
      } else if (Array.isArray(value)) {
        return (
          <React.Fragment key={key}>
            {value.map((item, index) => (
              <React.Fragment key={index}>
                <Typography variant="h6" fontWeight="bold" mt={2}>
                  {formatFieldName(key)} {index + 1}
                </Typography>
                {renderDetails(item)}
                <Divider sx={{ my: 2 }} />
              </React.Fragment>
            ))}
          </React.Fragment>
        );
      } else {
        return (
          <Grid container spacing={2} alignItems="flex-start" key={key}>
            <Grid item xs={3}>
              <Typography
                fontSize="15px"
                fontWeight="semibold"
                marginBottom={"8px"}
              >
                {formatFieldName(key)}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body1">:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {key === "createdAt" || key === "updatedAt"
                  ? formatDate(value === lastCreatedAt ? lastCreatedAt : value)
                  : value}
              </Typography>
            </Grid>
            <Divider sx={{ my: 2 }} />
          </Grid>
        );
      }
    });
  };

  return (
    <Box m="20px" height="80vh" overflow="auto" paddingRight="20px">
      <Header title={`View Course ID ${id}`} subtitle="" />
      <Box ml="40px">
        {courseDetails ? (
          renderDetails(courseDetails)
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Box>
    </Box>
  );
}

export default ViewCourse;
