import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Snackbar,
  Tooltip,
  useTheme,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteCourse } from "../../ApiConfig.jsx";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const CoursesManagement = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://jcgnapi.hasthiya.org/api/bible-course/getAllBibleCourses"
      );
      if (response.data.status) {
        const courses = response.data.result.map((course) => {
          const totalCredits = course.bibleModules.reduce(
            (sum, module) => sum + parseInt(module.bibleCourseModuleCredit, 10),
            0
          );
          const moduleNames = course.bibleModules
            .map((module) => module.bibleCourseModule)
            .join(", ");
          return {
            id: course.id,
            bibleCourseName: course.bibleCourseName,
            bibleCourseModuleCredit: totalCredits,
            bibleCourseModule: moduleNames,
            createdAt: new Date(course.createdAt).toLocaleDateString(),
          };
        });
        setData(courses);
      } else {
        throw new Error("Failed to fetch courses");
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
      setAlertSeverity("error");
      setAlertMessage(`Failed to fetch courses: ${error.message}`);
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = (id) => {
    console.log("Edit clicked for id:", id);
    navigate(`/course/edit/${id}`);
  };

  const handleAddModulesClick = (id) => {
    console.log("Add clicked for id:", id);
    navigate(`/course/addModules/${id}`);
  };
  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this course!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deleteResponse = await deleteCourse(id);
          if (!deleteResponse.status) {
            throw new Error("Failed to delete course");
          }
          fetchData();
          Swal.fire("Deleted!", "The course has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting course:", error);
          Swal.fire(
            "Error!",
            "Failed to delete course. Please try again later.",
            "error"
          );
        }
      }
    });
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "id", headerName: "Course ID" },
    {
      field: "bibleCourseName",
      headerName: "Course Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "bibleCourseModuleCredit",
      headerName: "Total Credits",
      flex: 1,
    },
    {
      field: "bibleCourseModule",
      headerName: "Course Modules",
      flex: 1,
    },

    {
      field: "createdAt",
      headerName: "Created At (DD/MM/YYYY)",
      flex: 1,
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <Link to={`/course/view/${params.row.id}`}>
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Add Modules">
            <IconButton onClick={() => handleAddModulesClick(params.row.id)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={() => handleEditClick(params.row.id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Header title="Course Management" subtitle="Listed all courses" />
        <Box>
          <Link to={"/course/create"} style={{ marginRight: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
            >
              Add New Course
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.grey[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default CoursesManagement;
