import { Avatar, Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";

function ViewStudent() {
  const { id } = useParams();
  const [studentDetails, setStudentDetails] = useState(null);

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const response = await fetch(
          `https://jcgnapi.hasthiya.org/user/getUserById/${id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch student details");
        }
        const studentData = await response.json();

        setStudentDetails(studentData.result);
      } catch (error) {
        console.error("Error fetching student details:", error);
      }
    };

    fetchStudentDetails();
  }, [id]);

  const renderDetails = () => {
    if (!studentDetails) return null;

    const {
      email,
      Student: {
        legalfirstname,
        legalmiddlename,
        legallastname,
        Role,
        prefferedfirstname,
        studentnickname,
        initialprogremme,
        advicerphonenumber,
        adviserselect,
        adviseremail,
        studentcellphone,
        studentphone,
        prevnumber,
        prevstreetname,
        prevapartment,
        prevcity,
        prevstate,
        prevcountry,
        prevzipcode,
        samemailornot,
        mailnumber,
        mailstreetname,
        mailapartment,
        mailcity,
        mailstate,
        mailcountry,
        mailzipcode,
        role,
        studentdob,
        studentgender,
        studentcitizenship,
        studentmaritalstatus,
        hispanikornot,
        identity,
        didyourecieve,
        ministerornot,
        localchurchpossition,
        howlong,
        churchname,
        churchnumber,
        churchstreetname,
        churchapt,
        churchcity,
        churchstate,
        churchcountry,
        churchzipcode,
        pastername,
        pasterphonenumber,
        pasteremail,
        pasteraddress,
        recomendationfullname,
        churchrecomendation,
        letterurl,
        explaination,
        education,
        highschoolselection,
        doplomacopyurl,
        schoolname,
        schoolnumber,
        schoolstreetname,
        schoolapt,
      },
      createdAt,
    } = studentDetails;

    const detailItems = [
      { label: "Email", value: email },
      { label: "Legal First Name", value: legalfirstname },
      { label: "Legal Middle Name", value: legalmiddlename },
      { label: "Legal Last Name", value: legallastname },
      { label: "Preferred First Name", value: prefferedfirstname },
      { label: "Nickname", value: studentnickname },
      { label: "Role", value: Role?.role || "Student" },
      { label: "Initial Program", value: initialprogremme },
      { label: "Adviser Phone Number", value: advicerphonenumber },
      { label: "Adviser Select", value: adviserselect },
      { label: "Adviser Email", value: adviseremail },
      { label: "Student Cellphone", value: studentcellphone },
      { label: "Student Phone", value: studentphone },
      { label: "Previous Street Number", value: prevnumber },
      { label: "Previous Street Name", value: prevstreetname },
      { label: "Previous Apartment", value: prevapartment },
      { label: "Previous City", value: prevcity },
      { label: "Previous State", value: prevstate },
      { label: "Previous Country", value: prevcountry },
      { label: "Previous Zip Code", value: prevzipcode },
      { label: "Same Email or Not", value: samemailornot },
      { label: "Mail Number", value: mailnumber },
      { label: "Mail Street Name", value: mailstreetname },
      { label: "Mail Apartment", value: mailapartment },
      { label: "Mail City", value: mailcity },
      { label: "Mail State", value: mailstate },
      { label: "Mail Country", value: mailcountry },
      { label: "Mail Zip Code", value: mailzipcode },
      { label: "Date of Birth", value: studentdob },
      { label: "Gender", value: studentgender },
      { label: "Citizenship", value: studentcitizenship },
      { label: "Marital Status", value: studentmaritalstatus },
      { label: "Hispanic or Not", value: hispanikornot },
      { label: "Identity", value: identity },
      { label: "Did You Receive", value: didyourecieve },
      { label: "Minister or Not", value: ministerornot },
      { label: "Local Church Position", value: localchurchpossition },
      { label: "How Long", value: howlong },
      { label: "Church Name", value: churchname },
      { label: "Church Number", value: churchnumber },
      { label: "Church Street Name", value: churchstreetname },
      { label: "Church Apartment", value: churchapt },
      { label: "Church City", value: churchcity },
      { label: "Church State", value: churchstate },
      { label: "Church Country", value: churchcountry },
      { label: "Church Zip Code", value: churchzipcode },
      { label: "Pastor Name", value: pastername },
      { label: "Pastor Phone Number", value: pasterphonenumber },
      { label: "Pastor Email", value: pasteremail },
      { label: "Pastor Address", value: pasteraddress },
      { label: "Recommendation Full Name", value: recomendationfullname },
      { label: "Church Recommendation", value: churchrecomendation },
      { label: "Letter URL", value: letterurl },
      { label: "Explanation", value: explaination },
      { label: "Education", value: education },
      { label: "High School Selection", value: highschoolselection },
      { label: "Diploma Copy URL", value: doplomacopyurl },
      { label: "School Name", value: schoolname },
      { label: "School Number", value: schoolnumber },
      { label: "School Street Name", value: schoolstreetname },
      { label: "School Apartment", value: schoolapt },

      { label: "Created At", value: formatCreatedAt(createdAt) },
    ];

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Avatar
            alt="Student"
            src={`/public/assets/${studentDetails.imageUrl}`}
            sx={{ width: 150, height: 150 }}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          {detailItems.map((item) => (
            <Grid container spacing={1} key={item.label}>
              <Grid item xs={6} md={3}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1rem", marginBottom: 1 }}
                >
                  <strong>{item.label}</strong>
                </Typography>
              </Grid>

              <Grid item xs={6} md={9}>
                <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                  : {item.value}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    return date.toLocaleString("en-US", {
      weekday: "long",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };

  return (
    <Box p="20px">
      <Header
        title={`View Student ID ${id}`}
        subtitle={studentDetails ? `Role: ${studentDetails.Role.role}` : ""}
      />
      <Box m="10px 0 0 0" height="55vh" overflow="auto">
        {renderDetails()}
      </Box>
    </Box>
  );
}

export default ViewStudent;
