import { Box, Button, Snackbar, TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
const EditModuleDetailsById = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const [moduleData, setModuleData] = useState({
    course_info_instructer_info_name: "",
    course_info_instructer_info_office: "",
    course_info_instructer_info_office_hours: "",
    course_info_instructer_info_tele: "",
    course_info_instructer_info_mail: "",
    course_info_course_description: "",
    course_info_required_text: [],
    course_info_course_requirements: [],
    course_info_couse_structure: "",
    course_info_student_learning_outcomes_out: "",
    student_learning_topic_outline: [],
    grading_policy: [],
  });

  useEffect(() => {
    const fetchModuleDetails = async () => {
      try {
        const response = await axios.get(
          `https://jcgnapi.hasthiya.org/api/bibalModule/getModuleDetailsByModuleDetailID/${id}`
        );
        console.log(response.data);
        if (response.data.status) {
          setModuleData(response.data.result[0]);
        } else {
          console.error("Error fetching data: ", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchModuleDetails();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModuleData({ ...moduleData, [name]: value });
  };

  const handleTextArrayChange = (e, index, field) => {
    const newArray = [...moduleData[field]];
    newArray[index][e.target.name] = e.target.value;
    setModuleData({ ...moduleData, [field]: newArray });
  };

  const addNewField = (field) => {
    setModuleData({
      ...moduleData,
      [field]: [
        ...moduleData[field],
        {
          id: moduleData[field].length + 1,
          [field.slice(0, -1)]: "",
        },
      ],
    });
  };

  const removeField = (index, field) => {
    const newArray = [...moduleData[field]];
    newArray.splice(index, 1);
    setModuleData({ ...moduleData, [field]: newArray });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.put(
        `https://jcgnapi.hasthiya.org/api/bibalModule/updateBibleModuleDetailByID/${id}`,
        moduleData
      );
      if (response.data.status) {
        setIsLoading(true);
        setAlertSeverity("success");
        setAlertMessage("Module details updated successfully!");

        setTimeout(() => {
          navigate("/module");
        }, 2500);
      } else {
        setAlertSeverity("error");
        setAlertMessage(
          "Error updating module details: " + response.data.message
        );
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage("Error updating data: " + error.message);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Box m="20px" height="75vh" overflow="auto">
        <Header title={`Edit Module Details for Module ID ${id}`} />
        {moduleData ? (
          <div>
            <h2>Instructor Information</h2>
            <TextField
              label="Name"
              name="course_info_instructer_info_name"
              value={moduleData.course_info_instructer_info_name}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Office"
              name="course_info_instructer_info_office"
              value={moduleData.course_info_instructer_info_office}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Office Hours"
              name="course_info_instructer_info_office_hours"
              value={moduleData.course_info_instructer_info_office_hours}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Telephone"
              name="course_info_instructer_info_tele"
              value={moduleData.course_info_instructer_info_tele}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              name="course_info_instructer_info_mail"
              value={moduleData.course_info_instructer_info_mail}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />

            <h2>Course Description</h2>
            <TextField
              label="Course Description"
              name="course_info_course_description"
              value={moduleData.course_info_course_description}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />

            <h2>Required Texts</h2>
            {moduleData.course_info_required_text.map((text, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  label={`Required Text ${index + 1}`}
                  name="course_info_required_text"
                  value={text.course_info_required_text}
                  onChange={(e) =>
                    handleTextArrayChange(e, index, "course_info_required_text")
                  }
                  fullWidth
                  margin="normal"
                />
                {/* <IconButton
                  onClick={() =>
                    removeField(index, "course_info_required_text")
                  }
                >
                  <Remove />
                </IconButton> */}
              </div>
            ))}
            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => addNewField("course_info_required_text")}
              startIcon={<Add />}
            >
              Add Required Text
            </Button> */}

            <h2>Course Requirements</h2>
            {moduleData.course_info_course_requirements.map(
              (requirement, index) => (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <TextField
                    label={`Requirement ${index + 1}`}
                    name="course_info_course_requirements"
                    value={requirement.course_info_course_requirements}
                    onChange={(e) =>
                      handleTextArrayChange(
                        e,
                        index,
                        "course_info_course_requirements"
                      )
                    }
                    fullWidth
                    margin="normal"
                  />
                  {/* <IconButton
                    onClick={() =>
                      removeField(index, "course_info_course_requirements")
                    }
                  >
                    <Remove />
                  </IconButton> */}
                </div>
              )
            )}
            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => addNewField("course_info_course_requirements")}
              startIcon={<Add />}
            >
              Add Course Requirement
            </Button> */}

            <h2>Course Structure</h2>
            <TextField
              label="Course Structure"
              name="course_info_couse_structure"
              value={moduleData.course_info_couse_structure}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />

            <h2>Student Learning Outcomes</h2>
            <TextField
              label="Student Learning Outcomes"
              name="course_info_student_learning_outcomes_out"
              value={moduleData.course_info_student_learning_outcomes_out}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />

            <h2>Student Learning Topic Outline</h2>
            {moduleData.student_learning_topic_outline.map((topic, index) => (
              <div key={index} style={{ marginBottom: "20px" }}>
                <TextField
                  label={`Week ${index + 1}`}
                  name="student_learning_topic_week"
                  value={topic.student_learning_topic_week}
                  onChange={(e) =>
                    handleTextArrayChange(
                      e,
                      index,
                      "student_learning_topic_outline"
                    )
                  }
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Topic"
                  name="student_learning_topic_topic"
                  value={topic.student_learning_topic_topic}
                  onChange={(e) =>
                    handleTextArrayChange(
                      e,
                      index,
                      "student_learning_topic_outline"
                    )
                  }
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Readings"
                  name="student_learning_topic_readings"
                  value={topic.student_learning_topic_readings}
                  onChange={(e) =>
                    handleTextArrayChange(
                      e,
                      index,
                      "student_learning_topic_outline"
                    )
                  }
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Activities"
                  name="student_learning_topic_activities"
                  value={topic.student_learning_topic_activities}
                  onChange={(e) =>
                    handleTextArrayChange(
                      e,
                      index,
                      "student_learning_topic_outline"
                    )
                  }
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Due Date"
                  type="date"
                  name="student_learning_topic_due_date"
                  value={topic.student_learning_topic_due_date}
                  onChange={(e) =>
                    handleTextArrayChange(
                      e,
                      index,
                      "student_learning_topic_outline"
                    )
                  }
                  fullWidth
                  margin="normal"
                />
                {/* <IconButton
                  onClick={() =>
                    removeField(index, "student_learning_topic_outline")
                  }
                >
                  <Remove />
                </IconButton> */}
              </div>
            ))}
            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => addNewField("student_learning_topic_outline")}
              startIcon={<Add />}
            >
              Add Learning Topic
            </Button> */}

            <h2>Grading Policy</h2>
            {moduleData.grading_policy.map((policy, index) => (
              <div key={index} style={{ marginBottom: "20px" }}>
                <TextField
                  label="Letter Grade"
                  name="grading_policy_Letter_grade_grade"
                  value={policy.grading_policy_Letter_grade_grade}
                  onChange={(e) =>
                    handleTextArrayChange(e, index, "grading_policy")
                  }
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Percentage"
                  name="grading_policy_Letter_grade_percentage"
                  value={policy.grading_policy_Letter_grade_percentage}
                  onChange={(e) =>
                    handleTextArrayChange(e, index, "grading_policy")
                  }
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Performance"
                  name="grading_policy_Letter_grade_performance"
                  value={policy.grading_policy_Letter_grade_performance}
                  onChange={(e) =>
                    handleTextArrayChange(e, index, "grading_policy")
                  }
                  fullWidth
                  margin="normal"
                />
                {/* <IconButton
                  onClick={() => removeField(index, "grading_policy")}
                >
                  <Remove />
                </IconButton> */}
              </div>
            ))}
            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => addNewField("grading_policy")}
              startIcon={<Add />}
            >
              Add Grading Policy
            </Button> */}
            <Box display="flex" justifyContent="end" m="20px">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
              >
                {isLoading ? "Updating..." : "Update"}
              </Button>
            </Box>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default EditModuleDetailsById;
