import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";
function ViewPayment() {
  const { id } = useParams();
  const theme = useTheme();
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    fetchPaymentDetails();
  }, [id]);

  const fetchPaymentDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://jcgnapi.hasthiya.org/payment/payment-history?userId=${id}`
      );
      const responseData = await response.json();
      if (responseData.status) {
        setPaymentDetails(responseData.result);
      } else {
        setError("Failed to fetch payment details: " + responseData.message);
      }
    } catch (error) {
      setError("Error fetching payment details: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const renderMetadata = (metadata) => {
    if (!metadata || Object.keys(metadata).length === 0) {
      return <Typography>No metadata available</Typography>;
    }

    return (
      <Grid container spacing={2}>
        {Object.entries(metadata).map(([key, value]) => {
          if (key.toLowerCase() === "imageurl") {
            return (
              <Grid item xs={12} key={key}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {capitalizeFirstLetter(key)}:
                </Typography>
                <Box
                  component="img"
                  src={value}
                  alt="Signature"
                  sx={{ width: 60, height: 60, objectFit: "cover" }}
                />
              </Grid>
            );
          }
          return (
            <Grid item xs={6} sm={4} md={3} key={key}>
              <Typography variant="subtitle1" fontWeight="bold">
                {capitalizeFirstLetter(key)}:
              </Typography>
              <Typography>{value}</Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box m="20px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`View Payment User ID: ${id}`} subtitle="" />
      <Box ml={"40px"}>
        {paymentDetails.length > 0 ? (
          paymentDetails.map((payment, index) => (
            <Paper key={payment.id} elevation={3} sx={{ p: 3, mb: 4 }}>
              <Typography
                variant="h6"
                fontWeight="bold"
                mb={2}
                color={colors.blueAccent[400]}
              >
                Payment {index + 1}
              </Typography>
              <Grid container spacing={2}>
                {Object.entries(payment).map(([field, value]) => {
                  if (field === "metadata") {
                    return (
                      <Grid item xs={12} key={field}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          mt={2}
                          mb={1}
                        >
                          Metadata:
                        </Typography>
                        {renderMetadata(value)}
                      </Grid>
                    );
                  }
                  if (field === "date") {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={field}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          {capitalizeFirstLetter(field)}:
                        </Typography>
                        <Typography>{formatDate(value)}</Typography>
                      </Grid>
                    );
                  }
                  return (
                    <Grid item xs={12} sm={6} md={4} key={field}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {capitalizeFirstLetter(field)}:
                      </Typography>
                      <Typography>{value}</Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Paper>
          ))
        ) : (
          <Typography variant="h6">No payment details found.</Typography>
        )}
      </Box>
    </Box>
  );
}

export default ViewPayment;
