import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import { getQuestionById } from "./../../ApiConfig.jsx";

const FileView = ({ url, fileType }) => {
  console.log("url", url);
  console.log("file", fileType);

  if (!url) {
    return null;
  }

  const imageStyles = {
    maxWidth: "100%",
    maxHeight: "400px",
    imageRendering: "pixelated",
  };

  switch (fileType) {
    case "pdf":
    case "doc":
    case "docx":
      return (
        <div style={{ width: "100%", height: "400px" }}>
          <iframe
            src={url}
            style={{ width: "100%", height: "100%" }}
            title="PDF-Viewer"
          >
            Your browser does not support iframes.
          </iframe>
        </div>
      );
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return <img src={url} alt="file-preview" style={imageStyles} />;
    case "mp4":
    case "webm":
      return (
        <video controls width="100%" height="400px">
          <source src={url} type={`video/${fileType}`} />
          Your browser does not support the video tag.
        </video>
      );
    default:
      return <p>Unsupported file type</p>;
  }
};

function ViewQuestion() {
  const { id } = useParams();
  const [questionDetails, setQuestionDetails] = useState({});

  const GetQuestionById = async (id) => {
    try {
      const data = await getQuestionById(id);
      if (data.result.result.status) {
        setQuestionDetails(data.result.result.result);
      }
    } catch (error) {
      console.error("Error fetching question:", error);
    }
  };

  useEffect(() => {
    GetQuestionById(id);
  }, [id]);

  const getFileTypeFromName = (name) => {
    if (typeof name === "string" && name.includes(".")) {
      const parts = name.split(".");
      const extension = parts[parts.length - 1];
      return extension;
    }
    return null;
  };

  return (
    <div>
      {" "}
      <Box m="20px" height="70vh" overflow="auto">
        <Header title={`View Question ${id}`} subtitle="" />
        <Box ml={"40px"}>
          <Grid container>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Question:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{questionDetails.question}</Typography>
              </Grid>
            </div>
          </Grid>

          {questionDetails.description && (
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Description:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{questionDetails.description}</Typography>
                </Grid>
              </div>
            </Grid>
          )}
          {questionDetails.fileURL && (
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Files:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <FileView
                    url={questionDetails?.fileURL}
                    fileType={getFileTypeFromName(questionDetails?.file?.name)}
                  />
                </Grid>
              </div>
            </Grid>
          )}

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "30px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Answers
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Vote count
                </Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span">
                  {questionDetails.voteOption1?.charAt(0).toUpperCase() +
                    questionDetails.voteOption1?.slice(1)}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{questionDetails.vote1}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span">
                  {questionDetails.voteOption2?.charAt(0).toUpperCase() +
                    questionDetails.voteOption2?.slice(1)}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{questionDetails.vote2}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span">
                  {questionDetails.voteOption3?.charAt(0).toUpperCase() +
                    questionDetails.voteOption3?.slice(1)}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{questionDetails.vote3}</Typography>
              </Grid>
            </div>
          </Grid>
          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span">
                  {questionDetails.voteOption4?.charAt(0).toUpperCase() +
                    questionDetails.voteOption4?.slice(1)}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{questionDetails.vote4}</Typography>
              </Grid>
            </div>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

export default ViewQuestion;
