import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { createMembershipCard } from "../../ApiConfig";
import Header from "../../components/Header";

const CreateNewMembershipCard = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    const requestBody = {
      memberId: values.memberId,
      template: "sampletesttemp",
      name: values.name,
      category: values.category,
      startDate: values.startDate,
      expDate: values.expDate,
      type: values.type,
      size: values.size,
    };

    setIsLoading(true);

    try {
      const response = await createMembershipCard(requestBody);

      if (response.status) {
        setAlertSeverity("success");
        setAlertMessage("Membership Card added successfully");
        setTimeout(() => {
          navigate("/membership");
        }, 2500);
      } else {
        throw new Error(response.message || "Unexpected response from server");
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new membership card: ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title="Create New Membership Card" subtitle="" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} style={{ height: "41vh" }}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Member ID*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.memberId}
                  name="memberId"
                  error={!!touched.memberId && !!errors.memberId}
                  helperText={touched.memberId && errors.memberId}
                />
              </Box>

              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </Box>

              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Category*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.category}
                  name="category"
                  error={!!touched.category && !!errors.category}
                  helperText={touched.category && errors.category}
                />
              </Box>

              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Start Date*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.startDate}
                  name="startDate"
                  error={!!touched.startDate && !!errors.startDate}
                  helperText={touched.startDate && errors.startDate}
                />
              </Box>

              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="End Date*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.expDate}
                  name="expDate"
                  error={!!touched.expDate && !!errors.expDate}
                  helperText={touched.expDate && errors.expDate}
                />
              </Box>

              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Type*</InputLabel>
                  <Select
                    name="type"
                    value={values.type}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Type*"
                    error={!!touched.type && !!errors.type}
                  >
                    <MenuItem value="Free">Free</MenuItem>
                    <MenuItem value="Paid">Paid</MenuItem>
                  </Select>
                  {touched.type && errors.type && (
                    <Box color="error.main" mt="0.5rem" fontSize="0.875rem">
                      {errors.type}
                    </Box>
                  )}
                </FormControl>
              </Box>

              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Size*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.size}
                  name="size"
                  error={!!touched.size && !!errors.size}
                  helperText={touched.size && errors.size}
                />
              </Box>
            </Box>

            {/* Submit Button */}
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
              >
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  memberId: yup.number().required("Member ID is required").positive().integer(),
  name: yup.string().required("Name is required"),
  category: yup.string().required("Category is required"),
  startDate: yup.date().required("Start Date is required"),
  expDate: yup
    .date()
    .required("End Date is required")
    .min(yup.ref("startDate"), "End Date must be later than Start Date"),
  type: yup
    .string()
    .oneOf(["Free", "Paid"], "Invalid type")
    .required("Type is required"),
  size: yup.string().required("Size is required"),
});

const initialValues = {
  memberId: "",
  name: "",
  category: "",
  startDate: "",
  expDate: "",
  type: "Free",
  size: "",
};

export default CreateNewMembershipCard;
