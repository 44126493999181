import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EmailIcon from "@mui/icons-material/Email";
import GroupIcon from "@mui/icons-material/Group";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TrafficIcon from "@mui/icons-material/Traffic";
import { Box, useTheme } from "@mui/material";
import React, { useState } from "react";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { tokens } from "../../theme";

// Mock data for the new bar chart
const mockUserData = [
  { month: "Jan", year: "2023", users: 25 },
  { month: "Feb", year: "2023", users: 30 },
  { month: "Mar", year: "2023", users: 40 },
  { month: "Apr", year: "2023", users: 35 },
  { month: "May", year: "2023", users: 45 },
  { month: "Jun", year: "2023", users: 50 },
  { month: "Jul", year: "2023", users: 55 },
  { month: "Aug", year: "2023", users: 60 },
  { month: "Sep", year: "2023", users: 65 },
  { month: "Oct", year: "2023", users: 70 },
  { month: "Nov", year: "2023", users: 75 },
  { month: "Dec", year: "2023", users: 80 },
  // Add more data for other months and years as needed
];

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selectedYear, setSelectedYear] = useState("2023");

  const handleChangeYear = (event) => {
    setSelectedYear(event.target.value);
  };

  const getFilteredData = () => {
    return mockUserData.filter((data) => data.year === selectedYear);
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Dashboard" subtitle="Welcome to your dashboard" />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="12,361"
            subtitle="Emails Sent"
            progress="0.75"
            increase="+14%"
            icon={
              <EmailIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            } // Assuming EmailIcon is properly imported
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="431,225"
            subtitle="Sales Obtained"
            progress="0.50"
            increase="+21%"
            icon={
              <AttachMoneyIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            } // Using AttachMoneyIcon instead of PointOfSaleIcon
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="32,441"
            subtitle="New Clients"
            progress="0.30"
            increase="+5%"
            icon={
              <CalendarTodayIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            } // Using CalendarTodayIcon instead of PersonAddIcon
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1,325,134"
            subtitle="Traffic Received"
            progress="0.80"
            increase="+43%"
            icon={
              <GroupIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            } // Using GroupIcon instead of TrafficIcon
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="2,781"
            subtitle="Emails Recieved"
            progress="0.75"
            increase="+14%"
            icon={
              <EmailIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            } // Assuming EmailIcon is properly imported
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1,225"
            subtitle="Donations Recieved"
            progress="0.20"
            increase="+11%"
            icon={
              <ReceiptIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            } // Using ReceiptIcon instead of PointOfSaleIcon
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="421"
            subtitle="New Subscribers"
            progress="0.25"
            increase="+25%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            } // Assuming PersonAddIcon is properly imported
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="45,575"
            subtitle="User Quantity"
            progress="0.80"
            increase="+80%"
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            } // Using TrafficIcon again
          />
        </Box>

        {/* ROW 2
        <Box
          gridColumn="span 5"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Users Quantity
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
