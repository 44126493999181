import { Visibility as VisibilityIcon } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react"; // Import React, useEffect, and useState
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const FormsUser = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://jcgnapi.hasthiya.org/api/dedicationFrom/getall"
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }
      const responseData = await response.json();

      // Check if responseData has the expected structure
      if (
        !responseData ||
        !responseData.result ||
        !Array.isArray(responseData.result.data)
      ) {
        throw new Error("Invalid response data format");
      }

      // Map the response data to the required fields
      const mappedData = responseData.result.data.map((item) => ({
        id: item.id,
        firstName: item.firstName + " " + item.middleName + " " + item.lastName,
        middleName: item.middleName,
        lastName: item.lastName,
        email: item.email,
        country: item.country,
        dateSubmitted: new Date(item.updatedAt).toLocaleString(),
      }));
      mappedData.sort((a, b) => b.id - a.id);

      setData(mappedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleViewClick = (id) => {
    console.log("View clicked for id:", id);
  };

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this form!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(
            `https://jcgnapi.hasthiya.org/api/dedicationFrom/delete/${id}`,
            {
              method: "DELETE",
            }
          );
          if (!response.ok) {
            throw new Error("Failed to delete data");
          }
          // Remove the deleted item from the state
          const updatedData = data.filter((item) => item.id !== id);
          setData(updatedData);
          Swal.fire("Deleted!", "Your form has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting form:", error);
          Swal.fire("Error!", "Failed to delete form.", "error");
        }
      }
    });
  };

  const exportToPdf = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [["ID", "Full Name", "Email", "Date Submitted", "Form Type"]],
      body: data.map(({ id, name, email, dateSubmitted, formType }) => [
        id,
        name,
        email,
        dateSubmitted,
        formType,
      ]),
    });
    doc.save("form_submissions.pdf");
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "firstName",
      headerName: "Full Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    // {
    //   field: "middleName",
    //   headerName: "Middle Name",
    //   flex: 1,
    //   cellClassName: "name-column--cell",
    // },
    // {
    //   field: "lastName",
    //   headerName: "Last Name",
    //   flex: 1,
    //   cellClassName: "name-column--cell",
    // },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "country", headerName: "Country", flex: 1 },
    {
      field: "dateSubmitted",
      headerName: "Date Submitted(MM-DD-YYYY)",
      flex: 1.2,
    },

    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.7,
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <Link to={`/dedication/view/${params.row.id}`}>
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const getRowClassName = (params) => {
    return "row-divider";
  };

  return (
    <Box m="20px">
      <Header
        title="Dedication Logs"
        subtitle="Dedication Forms Submitted By Users."
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="0px"
      >
        <Button
          variant="contained"
          onClick={exportToPdf}
          sx={{
            backgroundColor: "#4caf50",
            color: "white",
            fontSize: "10px",
            "&:hover": {
              backgroundColor: "#388e3c",
            },
          }}
        >
          Export to PDF
        </Button>
      </Box>
      <Box
        m="0 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .row-divider": {
            borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowClassName={getRowClassName}
        />
      </Box>
    </Box>
  );
};

export default FormsUser;
