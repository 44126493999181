import { Box, Grid, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import axios from "axios";
import { getSermon, formatDateToReadable } from "./../../ApiConfig.jsx";
import { SaveAlt as SaveAltIcon } from "@mui/icons-material";

function ViewSermons() {
  const { id } = useParams();
  const [video, setVideo] = useState();
  const [file, setFile] = useState();
  const [userDetails, setUserDetails] = useState({});

  const fetchAudio = async (sermonId) => {
    try {
      const data = await getSermon(sermonId);
      if (data.status) {
        if (data.result.videoUrl) {
          setVideo(data.result.videoUrl);
        }
        if (data.result.fileUrl) {
          setFile(data.result.fileUrl);
        }
        const formattedDate = formatDateToReadable(data.result.addedDate);

        let itemData = {
          "S.No": data.result.id,
          Title: data.result.title,
          "Added date": data.result.addedDate ? formattedDate : "",
          Description: data.result.description,
          Duration: `${data.result.duration} Min`,
          Language: data.result.language,
          Preacher: data.result.preacther,
          Type: data.result.type,
          "Video type":
            data.result.video_payment_method === "free" ? "Free" : "Paid",
          "Note type":
            data.result.note_payment_method === "free" ? "Free" : "Paid",
        };
        if (data.result.video_payment_method === "paid") {
          itemData = { ...itemData, "Video Amount": data.result.videoAmount };
        }
        if (data.result.note_payment_method === "paid") {
          itemData = { ...itemData, "Note Amount": data.result.noteAmount };
        }
        setUserDetails(itemData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchAudio(id);
    }
  }, [id]);

  return (
    <Box m="20px" height="80vh" overflow="auto" paddingRight="20px">
      <Header title={`View Sermon ${id}`} subtitle="" />
      <Box ml="40px">
        <Grid container spacing={2}>
          {Object.entries(userDetails).map(([field, value]) => (
            <React.Fragment key={field}>
              <Grid item xs={2}>
                <Typography
                  variant="h5"
                  component="span"
                  fontWeight="bold"
                  mt={3}>
                  {field}:
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h6" component="span" mt={3} mr={-5}>
                  {value}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}

          <Grid container spacing={2} paddingTop={2}>
            {video && (
              <>
                <Grid item xs={2}>
                  <Typography
                    variant="h5"
                    component="span"
                    fontWeight="bold"
                    mt={3}
                    ml={2}>
                    Video:
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <video
                    src={video}
                    controls
                    style={{
                      maxWidth: "250px",
                      maxHeight: "250px",
                      marginTop: "10px",
                    }}></video>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container spacing={2} paddingTop={2}>
            {file && (
              <>
                <Grid item xs={2}>
                  <Typography
                    variant="h5"
                    component="span"
                    fontWeight="bold"
                    mt={3}
                    ml={1.5}>
                    File:
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#6870fa",
                      color: "white",
                      marginRight: 2,
                      marginBottom: 5,
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor: "#3e4396",
                      },
                    }}
                    startIcon={<SaveAltIcon />}
                    href={file}
                    download
                    style={{
                      textTransform: "none",
                    }}>
                    Download File
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewSermons;
