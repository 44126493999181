import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  FormControl,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import Header from "../../components/Header";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import { createSermon } from "./../../ApiConfig.jsx";

const CreateNewSermons = () => {
  const navigate = useNavigate();

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const handleFormSubmit = async (values) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("video", values.video);
    formData.append("pdf", values.pdf);
    formData.append("addedDate", formattedDate);
    formData.append("status", "Active");
    formData.append("language", values.language);
    formData.append("duration", values.duration);
    formData.append("preacther", values.preacher);
    formData.append("type", values.type);
    formData.append("video_payment_method", values.videoType);
    formData.append("note_payment_method", values.pdfType);
    formData.append("videoAmount", 1);
    formData.append("noteAmount",1);



    try {
      const response = await createSermon(formData);
      if (response.status) {
        setAlertSeverity("success");
        setAlertMessage("Sermon added successfully");
        setTimeout(() => {
          navigate("/sermons");
        }, 2500);
      }
    } catch (error) {
      console.log(error);
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new sermon : ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }

  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title="Add New Sermon" subtitle="Create a New Sermon" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} height="41vh">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Sermon Title*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  name="title"
                  error={!!touched.title && !!errors.title}
                  helperText={touched.title && errors.title}
                />
              </Box>

              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Language*</InputLabel>
                  <Select
                    value={values.language}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="language"
                    error={!!touched.language && !!errors.language}
                  >
                    <MenuItem value="English">English</MenuItem>
                    <MenuItem value="French">French</MenuItem>
                  </Select>
                  {touched.language && errors.language && (
                    <FormHelperText style={{ color: '#f44336' }}>{errors.language}</FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Preacher*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.preacher}
                  name="preacher"
                  error={!!touched.preacher && !!errors.preacher}
                  helperText={touched.preacher && errors.preacher}
                />
              </Box>
              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Type*</InputLabel>
                  <Select
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="type"
                    error={!!touched.type && !!errors.type}

                  >
                    <MenuItem value="Online">Online</MenuItem>
                    <MenuItem value="Physical">Physical</MenuItem>

                  </Select>
                  {touched.type && errors.type && (
                    <FormHelperText style={{ color: '#f44336' }}>{errors.type}</FormHelperText>
                  )}
                </FormControl>
              </Box>

              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Description*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  name="description"
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                />
              </Box>

              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Duration (minutes)*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.duration}
                  name="duration"
                  error={!!touched.duration && !!errors.duration}
                  helperText={touched.duration && errors.duration}
                />
              </Box>

              <Box>

                <InputLabel htmlFor="eventType">Upload an video*</InputLabel>
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  accept="video/*"
                  onChange={(event) =>
                    handleChange({
                      target: {
                        name: "video",
                        value: event.currentTarget.files[0],
                      },
                    })
                  }
                  name="video"
                  error={!!touched.video && !!errors.video}
                  helperText={touched.video && errors.video}
                />

              </Box>
              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Video type*</InputLabel>
                  <Select
                    value={values.videoType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="videoType"
                    error={!!touched.videoType && !!errors.videoType}

                  >
                    <MenuItem value="free">Free</MenuItem>
                    <MenuItem value="paid">Paid</MenuItem>

                  </Select>
                  {touched.videoType && errors.videoType && (
                    <FormHelperText style={{ color: '#f44336' }}>{errors.videoType}</FormHelperText>
                  )}
                </FormControl>
              </Box>
              {values.videoType == "paid" && (
                <Box>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Video Amount*"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.videoAmount}
                    name="videoAmount"
                    error={!!touched.videoAmount && !!errors.videoAmount}
                    helperText={touched.videoAmount && errors.videoAmount}
                  />
                </Box>
              )}
              <Box>

                <InputLabel htmlFor="eventType">Upload a note*</InputLabel>
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  accept=".pdf"
                  onChange={(event) =>
                    handleChange({
                      target: {
                        name: "pdf",
                        value: event.currentTarget.files[0],
                      },
                    })
                  }
                  name="pdf"
                  error={!!touched.pdf && !!errors.pdf}
                  helperText={touched.pdf && errors.pdf}
                />

              </Box>

              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Note type*</InputLabel>
                  <Select
                    value={values.pdfType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="pdfType"
                    error={!!touched.pdfType && !!errors.pdfType}

                  >
                    <MenuItem value="free">Free</MenuItem>
                    <MenuItem value="paid">Paid</MenuItem>

                  </Select>
                  {touched.pdfType && errors.pdfType && (
                    <FormHelperText style={{ color: '#f44336' }}>{errors.pdfType}</FormHelperText>
                  )}
                </FormControl>
              </Box>
              {values.pdfType == "paid" && (
                <Box>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Note Amount*"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.noteAmount}
                    name="noteAmount"
                    error={!!touched.noteAmount && !!errors.noteAmount}
                    helperText={touched.noteAmount && errors.noteAmount}
                  />
                </Box>
              )}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  marginRight: 2,
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
              >
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  language: yup.string().required("Language is required"),
  preacher: yup.string().required("Preacher is required"),
  type: yup.string().required("Type is required"),
  videoType: yup.string().required("Video type is required"),
  pdfType: yup.string().required("Note type is required"),
  duration: yup.string().required("Duration is required").matches(/^\d+$/, "Duration must contain only numbers"),
  description: yup.string().required("Description is required"),
  preacher: yup.string().required("Preacher is required"),
  video: yup
    .mixed()
    .required("Video is required")
    .test("fileType", "Please enter a valid video type", (value) => {
      return !value || (value && value.type.startsWith("video/"));
    }),
  pdf: yup
    .mixed()
    .required("PDF is required")
    .test("fileType", "Please enter a valid PDF file", (value) => {
      return !value || (value && value.type.startsWith("application/pdf"));
    }),
  videoAmount: yup.string().when("videoType", {
    is: (val) => val === "paid", // Condition: validate only when type is "paid"
    then: yup
      .string()
      .required("Amount is required")
      .matches(/^\d+$/, "Amount must contain only numbers"),
    otherwise: yup.string(), // No validation if type is not "paid"
  }),
  noteAmount: yup.string().when("pdfType", {
    is: (val) => val === "paid", // Condition: validate only when type is "paid"
    then: yup
      .string()
      .required("Amount is required")
      .matches(/^\d+$/, "Amount must contain only numbers"),
    otherwise: yup.string(), // No validation if type is not "paid"
  }),
});

const initialValues = {
  title: "",
  language: "",
  preacher: "",
  videoType: "",
  pdfType:"",
  type: "",
  duration: "",
  description: "",
  preacher: "",
  video: null,
  pdf: null,
  videoAmount: "",
  noteAmount:""
};

export default CreateNewSermons;
