import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Snackbar,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import MuiAlert from "@mui/material/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { getWorshipServiceByID, updateWorshipService } from "../../ApiConfig";

const EditWorshipService = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const [updatedWorshipServiceDetails, setUpdatedWorshipServiceDetails] =
    useState({
      name: "",
      location: "",
      time: "",
      date: "",
      hours: "",
      language: "",
      request: "",
      description: "",
      image: null,
    });
  const [errors, setErrors] = useState({});
  const [imageName, setImageName] = useState("");

  const GetWorshipServiceById = async (id) => {
    try {
      const data = await getWorshipServiceByID(id);
      setUpdatedWorshipServiceDetails({
        name: data.data.result.name,
        location: data.data.result.location,
        date: data.data.result.date,
        time: data.data.result.time,
        hours: data.data.result.hours,
        language: data.data.result.language,
        request: data.data.result.requestRedirect,
        description: data.data.result.description,
        image: data.data.result.imageURL,
      });
      const imageUrl = data.data.result.imageURL;
      const imageFileName = decodeURIComponent(
        imageUrl.split("/").pop().split("%20").pop().split("?")[0]
      );
      setImageName(imageFileName);
    } catch (error) {
      console.error("Error fetching worship service:", error);
    }
  };

  useEffect(() => {
    GetWorshipServiceById(id);
  }, [id]);

  const handleInputChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });
    setUpdatedWorshipServiceDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    if (name === "image" && value) {
      setImageName(value.name);
    }
    validateForm();
  };

  const validateForm = () => {
    const errors = {};

    if (!updatedWorshipServiceDetails.name) {
      errors.name = "Name is required.";
    }
    if (!updatedWorshipServiceDetails.location) {
      errors.location = "Location is required.";
    }
    if (!updatedWorshipServiceDetails.time) {
      errors.time = "Time is required.";
    }
    if (!updatedWorshipServiceDetails.date) {
      errors.date = "Date is required.";
    }
    if (!updatedWorshipServiceDetails.hours) {
      errors.hours = "Hours is required.";
    }else if (!/^[0-9]*$/.test(updatedWorshipServiceDetails.hours)) {
      errors.hours = "Hours must be a positive and numeric value.";
    }
    if (!updatedWorshipServiceDetails.language) {
      errors.language = "Language is required.";
    }
    if (!updatedWorshipServiceDetails.request) {
      errors.request = "Redirect request is required.";
    }
    if (!updatedWorshipServiceDetails.description) {
      errors.description = "Description is required.";
    }
    if (!updatedWorshipServiceDetails.image) {
      errors.image = "Image is required.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleUpdateWorshipService = async () => {
    if (validateForm()) {
      setIsLoading(true);

      const body = {
        name: updatedWorshipServiceDetails.name,
        location: updatedWorshipServiceDetails.location,
        date: updatedWorshipServiceDetails.date,
        time: updatedWorshipServiceDetails.time,
        hours: updatedWorshipServiceDetails.hours,
        requestRedirect: updatedWorshipServiceDetails.request,
        language: updatedWorshipServiceDetails.language,
        description: updatedWorshipServiceDetails.description,
        imageURL: updatedWorshipServiceDetails.image,
      };

      try {
        const response = await updateWorshipService(id, body);
        if (response.result.status) {
          setAlertSeverity("success");
          setAlertMessage("Worship Service updated successfully");
          setTimeout(() => {
            navigate("/worship-service-management");
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        setAlertSeverity("error");
        setAlertMessage(`Failed worship service update: ${error.message}`);
      } finally {
        setOpenSnackbar(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`UPDATE WORSHIP SERVICE ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Name*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedWorshipServiceDetails.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Location*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedWorshipServiceDetails.location}
              onChange={(e) => handleInputChange("location", e.target.value)}
              error={!!errors.location}
              helperText={errors.location}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Date*
            </Typography>
            <TextField
              type="date"
              fullWidth
              variant="filled"
              value={updatedWorshipServiceDetails.date}
              onChange={(e) => handleInputChange("date", e.target.value)}
              error={!!errors.date}
              helperText={errors.date}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Time*
            </Typography>
            <TextField
              type="time"
              fullWidth
              variant="filled"
              value={updatedWorshipServiceDetails.time}
              onChange={(e) => handleInputChange("time", e.target.value)}
              error={!!errors.time}
              helperText={errors.time}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Hours*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedWorshipServiceDetails.hours}
              onChange={(e) => handleInputChange("hours", e.target.value)}
              error={!!errors.hours}
              helperText={errors.hours}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Language*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={updatedWorshipServiceDetails.language}
                onChange={(e) => handleInputChange("language", e.target.value)}
                name="language"
                error={!!errors.language}
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="French">French</MenuItem>
              </Select>
              {errors.language && (
                <Typography variant="caption" color="error">
                  {errors.language}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Request Redirect*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={updatedWorshipServiceDetails.request}
                onChange={(e) => handleInputChange("request", e.target.value)}
                name="request"
                error={!!errors.request}
              >
                <MenuItem value="Appoinment">Appoinment</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
              {errors.request && (
                <Typography variant="caption" color="error">
                  {errors.request}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1" fontWeight="bold">
              Description*(Limited to 1000 characters)
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedWorshipServiceDetails.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              multiline
              rows={4}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>Image*</div>
              <TextField
                fullWidth
                variant="filled"
                type="file"
                accept="image/*"
                onChange={(e) => handleInputChange("image", e.target.files[0])}
                name="image"
                error={!!errors.image}
                helperText={errors.image}
              />
              {imageName && (
                <Typography variant="subtitle1" mt={1}>
                  {`Current image: ${imageName}`}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={handleUpdateWorshipService}
            >
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditWorshipService;
