import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Snackbar,
  FormHelperText,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { createWorshioService } from "../../ApiConfig";

const CreateNewAdminService = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    const formData = new FormData();

    formData.append("name", values.name);
    formData.append("location", values.location);
    formData.append("date", values.date);
    formData.append("time", values.time);
    formData.append("hours", values.hours);
    formData.append("requestRedirect", values.request);
    formData.append("imageURL", values.image);
    formData.append("language", values.language);
    formData.append("description", values.description);

    setIsLoading(true);

    try {
      const response = await createWorshioService(formData);
      if (response.status) {
        setAlertSeverity("success");
        setAlertMessage("Worship service added successfully");
        setTimeout(() => {
          navigate("/worship-service-management");
        }, 2500);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new worship service : ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title="CREATE NEW WORSHIP SERVICE" subtitle="" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} height="41vh">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Location*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.location}
                  name="location"
                  error={!!touched.location && !!errors.location}
                  helperText={touched.location && errors.location}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Date*"
                  InputLabelProps={{ shrink: true }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.date}
                  name="date"
                  error={!!touched.date && !!errors.date}
                  helperText={touched.date && errors.date}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="time"
                  label="Time*"
                  InputLabelProps={{ shrink: true }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.time}
                  name="time"
                  error={!!touched.time && !!errors.time}
                  helperText={touched.time && errors.time}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Hours*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.hours}
                  name="hours"
                  error={!!touched.hours && !!errors.hours}
                  helperText={touched.hours && errors.hours}
                />
              </Box>
              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Language*</InputLabel>
                  <Select
                    variant="filled"
                    value={values.language}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="language"
                    error={!!touched.language && !!errors.language}
                    helperText={touched.language && errors.language}
                  >
                    <MenuItem value="English">English</MenuItem>
                    <MenuItem value="French">French</MenuItem>
                  </Select>
                  {touched.language && errors.language && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {errors.language}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Request Redirect*</InputLabel>
                  <Select
                    value={values.request}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="request"
                    error={!!touched.request && !!errors.request}
                  >
                    <MenuItem value="Appoinment">Appoinment</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {touched.request && errors.request && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {errors.request}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Box>
            <Box
              display="grid"
              gap="30px"
              marginTop="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Description*(Limited to 1000 characters)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  name="description"
                  multiline
                  rows={4}
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                />
              </Box>
              <Box>
                <InputLabel htmlFor="eventType">Upload an Image*</InputLabel>
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  onBlur={handleBlur}
                  onChange={(event) =>
                    handleChange({
                      target: {
                        name: "image",
                        value: event.currentTarget.files[0],
                      },
                    })
                  }
                  name="image"
                  error={!!touched.image && !!errors.image}
                  helperText={touched.image && errors.image}
                />
              </Box>
            </Box>
            {/* Submit Button */}
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  marginRight: 2,
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396", // Change to green color on hover
                  },
                }}
              >
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  location: yup.string().required("Location is required"),
  time: yup.string().required("Time is required"),
  date: yup.date().required("Date is required"),
  hours: yup
    .number()
    .required("Hours value is required")
    .typeError("Hours value must be a number")
    .min(1, "Hours value must be greater than 0"),
  language: yup.string().required("Language is required"),
  request: yup.string().required("Redirect request is required"),
  description: yup.string().required("Description is required"),
  image: yup
    .mixed()
    .required("Image is required")
    .test("fileType", "Please enter a valid image type", (value) => {
      return !value || (value && value.type.startsWith("image/"));
    }),
});

const initialValues = {
  name: "",
  location: "",
  time: "",
  date: "",
  hours: "",
  language: "",
  request: "",
  description: "",
  image: null,
};

export default CreateNewAdminService;
