import {
  Box,
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { getContent, updateContent } from "./../../ApiConfig.jsx";

import * as yup from "yup";
function EditContent() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const initialContentDetails = {
    pageTitle: "",
    parent: "",
    description: "",
    cms_image: null,
  };

  const [contentDetails, setContentDetails] = useState(initialContentDetails);
  const [errors, setErrors] = useState({});

  const fetchAudio = async (id) => {
    try {
      const data = await getContent(id);
      if (data.status) {
        console.log(data.result);
        setCurrentImage(data.result.imageURL);
        setContentDetails({
          pageTitle: data.result.pageTitle,
          parent: data.result.parent,
          description: data.result.description,
          cms_image: null,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAudio(id);
  }, [id]);

  const handleFieldChange = (field, value) => {
    validateField(field, value);
    setContentDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [field]: value,
    }));
  };

  const validateField = (field, value) => {
    const fieldSchema = yup.object().shape({
      pageTitle: yup.string().required("Page title is required"),
      parent: yup.string().required("Parent is required"),
      description: yup.string().required("Description type is required"),
      cms_image: yup
        .mixed()
        .test("fileType", "Please enter a valid image type", (value) => {
          return !value || (value && value.type.startsWith("image/"));
        }),
    });

    fieldSchema
      .validateAt(field, { [field]: value })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: error.message }));
      });
  };

  const updateAudio = async (userDetails) => {
    const validationPromises = Object.keys(userDetails).map((field) =>
      validateField(field, userDetails[field])
    );

    console.log(errors);
    const hasErrors = Object.values(errors).some(
      (errorMessage) => errorMessage !== ""
    );

    if (hasErrors) {
      console.log("Errors found. Cannot update.");
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("pageTitle", userDetails.pageTitle);
    formData.append("cms-image", userDetails.cms_image);
    formData.append("parent", userDetails.parent);
    formData.append("description", userDetails.description);
    try {
      const res = await updateContent(id, formData);
      if (res.status) {
        console.log(res.status);
        setAlertSeverity("success");
        setAlertMessage("Audio updated successfully");

        setTimeout(() => {
          navigate("/cms");
        }, 3000);
      }
    } catch (err) {
      console.log(err);
      setAlertSeverity("error");
      setAlertMessage(`Failed to update audio: ${err.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`Edit Content ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Page title*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={contentDetails.pageTitle}
              onChange={(e) => handleFieldChange("pageTitle", e.target.value)}
              error={!!errors.pageTitle}
              helperText={errors.pageTitle}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Parent*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={contentDetails.parent}
              onChange={(e) => handleFieldChange("parent", e.target.value)}
              error={!!errors.parent}
              helperText={errors.parent}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Description*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={contentDetails.description}
              onChange={(e) => handleFieldChange("description", e.target.value)}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>Image*</div>
              <TextField
                fullWidth
                variant="filled"
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleFieldChange("cms_image", e.target.files[0])
                }
                name="cms_image"
                error={!!errors.cms_image}
                helperText={errors.cms_image}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>Current Image</div>
              <img src={currentImage} style={{ width: "100%" }} alt="" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={() => updateAudio(contentDetails)}>
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}>
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}

export default EditContent;
