import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import * as yup from "yup";
import Header from "../../components/Header";

function EditCareer() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [careerDetails, setCareerDetails] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCareerDetails = async () => {
      try {
        const response = await axios.get(
          `https://jcgnapi.hasthiya.org/api/career/get/${id}`
        );
        const responseData = response.data;
        if (responseData.result.status) {
          const { id, createdAt, updatedAt, ...relevantDetails } =
            responseData.result.data;
          setCareerDetails(relevantDetails);
          formik.setValues(relevantDetails);
        } else {
          console.error(
            "Failed to fetch career details:",
            responseData.result.message
          );
        }
      } catch (error) {
        console.error("Error fetching career details:", error);
      }
    };

    fetchCareerDetails();
  }, [id]);

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("Company/Organization Name is required"),
    description: yup
      .string()
      .required("Company/Organization Description is required"),
    jobTitle: yup.string().required("Job Title is required"),
    jobLocation: yup.string().required("Job Location is required"),
    jobDescription: yup.string().required("Job Description is required"),
    duty: yup.string().required("Duties and Responsibilities are required"),
    qualification: yup.string().required("Job Qualifications are required"),
    keyCompetencies: yup.string().required("Key Competencies are required"),
    physicalRequirement: yup
      .string()
      .required("Physical Requirements are required"),
    other: yup.string().required("Others are required"),
    duration: yup.string().required("Time Period in months is required"),
    status: yup.string().required("Status is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      jobTitle: "",
      jobLocation: "",
      jobDescription: "",
      duty: "",
      qualification: "",
      keyCompetencies: "",
      physicalRequirement: "",
      other: "",
      duration: "",
      status: "",
    },
    validationSchema: checkoutSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true); // Set loading state when submitting
        const response = await axios.put(
          `https://jcgnapi.hasthiya.org/api/career/update/${id}`,
          values
        );
        const responseData = response.data;
        if (responseData.result.status) {
          setAlertSeverity("success");
          setAlertMessage("Career updated successfully");
          setOpenSnackbar(true);

          setTimeout(() => {
            navigate("/careers");
          }, 2000);
        } else {
          setAlertSeverity("error");
          setAlertMessage(
            `Failed to update career: ${responseData.result.message}`
          );
          setOpenSnackbar(true);
        }
      } catch (error) {
        console.error("Error updating career:", error);
        setAlertSeverity("error");
        setAlertMessage("Error updating career. Please try again.");
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`Edit Career ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {careerDetails &&
            Object.entries(formik.values).map(([field, value]) => (
              <Grid item xs={12} key={field}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  {field === "name" ? "Company/Organization Name" : field}
                  {formik.errors[field] && (
                    <StarIcon color="error" sx={{ fontSize: 10, ml: 0.5 }} />
                  )}
                </Typography>
                {field === "status" ? (
                  <Select
                    fullWidth
                    variant="outlined"
                    name={field}
                    value={value}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[field] && Boolean(formik.errors[field])
                    }
                  >
                    <MenuItem value="">Select Status</MenuItem>
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </Select>
                ) : (
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    multiline={field === "jobDescription" || field === "duty"}
                    rows={
                      field === "jobDescription" || field === "duty" ? 4 : 1
                    }
                    name={field}
                    value={value}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[field] && Boolean(formik.errors[field])
                    }
                    helperText={formik.touched[field] && formik.errors[field]}
                  />
                )}
              </Grid>
            ))}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={formik.handleSubmit}
              disabled={loading} // Disable button when loading
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              endIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      {/* Snackbar for showing success or error message */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          sx={{ color: "#fff" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}: {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}

export default EditCareer;
