import {
  Box,
  Button,
  MenuItem,
  Alert as MuiAlert,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/Header";

const CreateNewNews = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [wordCount, setWordCount] = useState(0);
  const navigate = useNavigate();

  const handleFormSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://jcgnapi.hasthiya.org/bural/addfuneral",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );
      const responseData = await response.json();
      console.log("Response:", responseData);
      if (responseData.status) {
        setAlertSeverity("success");
        setAlertMessage("Funeral details added successfully");
        setTimeout(() => {
          navigate("/burialexpensesclaim");
        }, 2000);
        resetForm();
      } else {
        setAlertSeverity("error");
        setAlertMessage(responseData.message);
      }
    } catch (error) {
      console.error("Error adding funeral details:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to add funeral details");
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  const handleDescriptionChange = (event, setFieldValue) => {
    const value = event.target.value;
    const words = value.split(/\s+/).filter((word) => word.length > 0);
    if (words.length <= 50) {
      setFieldValue("docname", value);
      setWordCount(words.length);
    }
  };

  return (
    <Box
      m="20px"
      height="70vh"
      width="90%"
      overflow="auto"
      paddingRight="20px"
      position="relative"
    >
      <Header title="Add Funeral Details" subtitle="" />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          sx={{ color: "#fff" }}
        >
          <AlertTitle>
            {alertSeverity === "success" ? "Success" : "Error"}
          </AlertTitle>
          {alertMessage}
        </MuiAlert>
      </Snackbar>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} height="41vh">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Typography fontWeight="bold" fontSize="16px">
                Document Name{" "}
              </Typography>
              <Box mt={-2}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  onBlur={handleBlur}
                  onChange={(e) => handleDescriptionChange(e, setFieldValue)}
                  value={values.docname}
                  name="docname"
                  multiline
                  rows={2}
                  error={!!touched.docname && !!errors.docname}
                  helperText={touched.docname && errors.docname}
                />
              </Box>

              <Typography fontWeight="bold" fontSize="16px">
                Issued By*
              </Typography>
              <Box mt={-2}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.issuedby}
                  name="issuedby"
                  error={!!touched.issuedby && !!errors.issuedby}
                  helperText={touched.issuedby && errors.issuedby}
                />
              </Box>

              <Typography fontWeight="bold" fontSize="16px">
                Claim Amount*
              </Typography>
              <Box mt={-2}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.claimAmount}
                  name="claimAmount"
                  error={!!touched.claimAmount && !!errors.claimAmount}
                  helperText={touched.claimAmount && errors.claimAmount}
                />
              </Box>

              <Typography fontWeight="bold" fontSize="16px">
                Submit Date*
              </Typography>
              <Box mt={-2}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.submitDate}
                  name="submitDate"
                  error={!!touched.submitDate && !!errors.submitDate}
                  helperText={touched.submitDate && errors.submitDate}
                />
              </Box>

              <Typography fontWeight="bold" fontSize="16px">
                Received Date*
              </Typography>
              <Box mt={-2}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.recivedDate}
                  name="recivedDate"
                  error={!!touched.recivedDate && !!errors.recivedDate}
                  helperText={touched.recivedDate && errors.recivedDate}
                />
              </Box>

              <Typography fontWeight="bold" fontSize="16px">
                Approved Date*
              </Typography>
              <Box mt={-2}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.approvedDate}
                  name="approvedDate"
                  error={!!touched.approvedDate && !!errors.approvedDate}
                  helperText={touched.approvedDate && errors.approvedDate}
                />
              </Box>

              <Typography fontWeight="bold" fontSize="16px">
                Amount*
              </Typography>
              <Box mt={-2}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amount}
                  name="amount"
                  error={!!touched.amount && !!errors.amount}
                  helperText={touched.amount && errors.amount}
                />
              </Box>

              <Typography fontWeight="bold" fontSize="16px">
                Claim Status*
              </Typography>
              <Box mt={-2}>
                <Select
                  fullWidth
                  variant="filled"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.claimStatus}
                  name="claimStatus"
                  error={!!touched.claimStatus && !!errors.claimStatus}
                  helperText={touched.claimStatus && errors.claimStatus}
                >
                  <MenuItem value="Approved">Approved</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                </Select>
              </Box>
            </Box>
            {/* Submit Button */}
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={loading}
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  marginRight: 2,
                  fontSize: "14px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

// Initial values for form fields
const initialValues = {
  docname: "",
  issuedby: "",
  claimAmount: "",
  submitDate: "",
  recivedDate: "",
  approvedDate: "",
  amount: "",
  claimStatus: "Approved",
};

// Validation schema using Yup
const checkoutSchema = yup.object().shape({
  docname: yup
    .string()
    .required("Document Name is Required")
    .max(50, "Maximum 50 words allowed"),
  issuedby: yup.string().required("Issued by Required"),
  claimAmount: yup.number().required("Claim Amount is Required"),
  submitDate: yup.date().required("Submit Date is Required"),
  recivedDate: yup.date().required("Received Date is Required"),
  approvedDate: yup.date().required("Approved Date is Required"),
  amount: yup.number().required("Amount is Required"),
  claimStatus: yup.string().required("Claim Status is Required"),
});

export default CreateNewNews;
