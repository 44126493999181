import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import axios from "axios";
import { getAudio } from "./../../ApiConfig.jsx";


function ViewAudio() {
  const { id } = useParams();
  const [audioDetails, setAudioDetails] = useState({});

  const fetchAudio = async(id) => {
  try {
    const data = await getAudio(id);
     if (data.status) {
       console.log(data.result);
       setAudioDetails(data.result);
     }
  } catch (error) {
     console.log(error);
  }
}

  useEffect(() => {
    fetchAudio(id);
  }, [id]);

const AudioPlayer = React.memo(({ audioUrl }) => (
  <audio controls style={{ width: "50%" }}>
    <source src={audioUrl} type="audio/mp3" />
  </audio>
));
  return (
    <div>
      {" "}
      <Box m="20px" height="70vh" overflow="auto">
        <Header title={`View Audio Track ${id}`} subtitle="" />
        <Box ml={"40px"}>
          <Grid container>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Title:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{audioDetails.title}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}>
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Type:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{audioDetails.type}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}>
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Audio Type:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{audioDetails.audio_type}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}>
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Language:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{audioDetails.language}</Typography>
              </Grid>
            </div>
          </Grid>

          {audioDetails && audioDetails.artist_name && (
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}>
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Artist:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{audioDetails.artist_name}</Typography>
                </Grid>
              </div>
            </Grid>
          )}

          {audioDetails.type == "Paid" && (
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}>
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Amount:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{`${audioDetails.amount} $`}</Typography>
                </Grid>
              </div>
            </Grid>
          )}

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}>
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Audio:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <AudioPlayer audioUrl={audioDetails.audio_url} />
              </Grid>
            </div>
          </Grid>

          {audioDetails.image_url && (
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}>
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Image:
                  </Typography>
                </Grid>
                <img
                  style={{ maxWidth: "400px", maxHeight: "400px" }}
                  src={audioDetails.image_url}
                  alt=""
                />
              </div>
            </Grid>
          )}
        </Box>
      </Box>
    </div>
  );
}

export default ViewAudio;
