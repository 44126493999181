import { Avatar, Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";

function ViewOrganization() {
  const { id } = useParams();
  const [orgDetails, setOrgDetails] = useState(null);

  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        const response = await fetch(
          `https://jcgnapi.hasthiya.org/user/getUserById/${id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch organization details");
        }
        const orgData = await response.json();

        // Extract data from the API response
        const {
          email,
          streetNumber,
          streetName,
          APT,
          city,
          state,
          country,
          zipCode,
          imageUrl,
          Role,
          Organization,
          createdAt,
        } = orgData.result;

        // Construct the orgDetails object
        const formattedOrgDetails = {
          email,
          streetNumber,
          streetName,
          APT,
          city,
          state,
          country,
          zipCode,
          imageUrl,
          role: Role.role,
          name: Organization.name,
          orgphone: Organization.orgphone,
          profileSelection: Organization.profileSelection,
          localPlace: Organization.localPlace,
          bussinesage: Organization.bussinesage,
          startdate: Organization.startdate,
          bussineStructure: Organization.bussineStructure,
          mission: Organization.mission,
          relationship: Organization.relationship,
          serviceprovide: Organization.serviceprovide,
          title: Organization.title,
          othersphone: Organization.othersphone,
          createdAt,
        };

        setOrgDetails(formattedOrgDetails);
      } catch (error) {
        console.error("Error fetching organization details:", error);
      }
    };

    fetchOrgDetails();
  }, [id]);

  const renderDetails = () => {
    if (!orgDetails) return null;

    const detailItems = [
      { label: "Email", value: orgDetails.email },
      { label: "Organization Name", value: orgDetails.name },
      { label: "Organization Phone", value: orgDetails.orgphone },
      { label: "Street Number", value: orgDetails.streetNumber },
      { label: "Street Name", value: orgDetails.streetName },
      { label: "APT", value: orgDetails.APT },
      { label: "City", value: orgDetails.city },
      { label: "State", value: orgDetails.state },
      { label: "Country", value: orgDetails.country },
      { label: "Zip Code", value: orgDetails.zipCode },
      { label: "Role", value: orgDetails.role },

      { label: "Profile Selection", value: orgDetails.profileSelection },
      { label: "Local Place", value: orgDetails.localPlace },
      { label: "Business Age", value: orgDetails.bussinesage },
      { label: "Start Date", value: orgDetails.startdate },
      { label: "Business Structure", value: orgDetails.bussineStructure },
      { label: "Mission", value: orgDetails.mission },
      { label: "Relationship", value: orgDetails.relationship },
      { label: "Services Provided", value: orgDetails.serviceprovide },
      { label: "Title", value: orgDetails.title },
      { label: "Others Phone", value: orgDetails.othersphone },
      { label: "Created At", value: formatCreatedAt(orgDetails.createdAt) },
    ];

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Avatar
            alt="Organization"
            src={`/public/assets/${orgDetails.imageUrl}`}
            sx={{ width: 150, height: 150 }}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          {detailItems.map((item) => (
            <Grid container spacing={1} key={item.label}>
              <Grid item xs={6} md={3}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1rem", marginBottom: 1 }}
                >
                  <strong>{item.label}</strong>
                </Typography>
              </Grid>
              <Grid item xs={6} md={9}>
                <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                  :{item.value}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    return date.toLocaleString("en-US", {
      weekday: "long",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };

  return (
    <Box p="20px">
      <Header
        title={`View Organization ID ${id}`}
        subtitle={orgDetails ? `Role: ${orgDetails.role}` : ""}
      />{" "}
      <Box m="10px 0 0 0" height="55vh" overflow="auto">
        {renderDetails()}{" "}
      </Box>
    </Box>
  );
}

export default ViewOrganization;
