import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Snackbar,
  Tooltip,
  useTheme,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { deleteEvent, getAllEvent, updateEvent } from "./../../ApiConfig.jsx";

const EventManagement = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const fetchData = async () => {
    const audioData = await getAllEvent();
    setData(audioData);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = (id) => {
    console.log("Edit clicked for id:", id);
    navigate(`/event/edit/${id}`);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleStatusChange = async (id, newStatus, params) => {
    try {
      const formData = new FormData();
      formData.append("status", newStatus);
      const res = await updateEvent(id, formData);
      if (res.status) {
        const updatedData = data.map((item) => {
          if (item.id === id) {
            return { ...item, status: newStatus };
          }
          return item;
        });
        setData(updatedData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    { field: "id", headerName: "S.NO" },
    {
      field: "name",
      headerName: "Event Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "location",
      headerName: "Booked From",
      flex: 1,
    },
    {
      field: "numOfParticipant",
      headerName: "Number of Participants",
      flex: 1,
    },
    {
      field: "end_date",
      headerName: "Booked Till",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Event Type",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    // {
    //   field: "status",
    //   headerName: "Event Status",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Select
    //       value={params.row.status}
    //       onChange={(event) =>
    //         handleStatusChange(params.row.id, event.target.value, params.row)
    //       }
    //       style={{ width: "100px" }}
    //     >
    //       <MenuItem value="Active">Active</MenuItem>
    //       <MenuItem value="Inactive">Inactive</MenuItem>
    //     </Select>
    //   ),
    // },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <Link to={`/event/view/${params.row.id}`}>
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={() => handleEditClick(params.row.id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this event!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deleteResponse = await deleteEvent(id);
          if (!deleteResponse.status) {
            throw new Error("Failed to delete event");
          }
          fetchData();
          setAlertSeverity("success");
          setAlertMessage("Event deleted successfully");
        } catch (error) {
          console.log(error);
          setAlertSeverity("error");
          setAlertMessage(`Failed to delete event: ${error.message}`);
        } finally {
          setOpenSnackbar(true);
        }
      }
    });
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Header title="Event Management" subtitle="Managing the Event" />
        <Box>
          <Link to={"createevent"} style={{ marginRight: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
            >
              Add New Event
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EventManagement;
