import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Snackbar,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import * as yup from "yup";
import Header from "../../components/Header";
import MuiAlert from "@mui/material/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { getTemplateByID, updateTemplate } from "../../ApiConfig";
import JoditEditor from "jodit-react";

const EditTemplate = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const config = {
    placeholder: "Description",
    toolbarButtonSize: "small",
    style: {
      color: "#000000",
    },
  };

  const navigate = useNavigate();
  const { id } = useParams();
  const [updatedTemplateDetails, setUpdatedTemplateDetails] = useState({
    template: "",
    fieldTitle: "",
    type: "",
    status: "",
  });
  const [errors, setErrors] = useState({});

  const GetTemplateById = async (id) => {
    try {
      const data = await getTemplateByID(id);
      setUpdatedTemplateDetails({
        template: data.result.template,
        fieldTitle: data.result.fieldTitle,
        type: data.result.type,
        status: data.result.status,
      });
    } catch (error) {
      console.error("Error fetching template:", error);
    }
  };

  useEffect(() => {
    GetTemplateById(id);
  }, [id]);

  const handleInputChange = (name, value) => {
    setUpdatedTemplateDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (field, value) => {
    const checkoutSchema = yup.object().shape({
      template: yup.string().required("Template is required"),
      // Add other fields if needed
    });

    checkoutSchema
      .validateAt(field, { [field]: value })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: error.message }));
      });
  };

  const handleUpdateTemplate = async () => {
    setIsLoading(true);

    const body = {
      template: updatedTemplateDetails.template,
      fieldTitle: updatedTemplateDetails.fieldTitle,
      type: updatedTemplateDetails.type,
      status: updatedTemplateDetails.status,
    };

    try {
      const response = await updateTemplate(id, body);
      if (response.status) {
        setAlertSeverity("success");
        setAlertMessage("Template updated successfully");
        setTimeout(() => {
          navigate("/templates");
        }, 2000);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage("Failed to update template. Please check the required fields.");
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`UPDATE TEMPLATE ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Template*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedTemplateDetails.template}
              onChange={(e) => handleInputChange("template", e.target.value)}
              error={!!errors.template}
              helperText={errors.template}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={handleUpdateTemplate}
            >
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditTemplate;
