import { Box, Button, Snackbar, TextField, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import * as yup from "yup";
import Header from "../../components/Header";
const EditActivity = () => {
  const navigate = useNavigate();
  const signatureRef = useRef();
  const { id } = useParams();
  const [formData, setFormData] = useState(null);
  const [formNotFound, setFormNotFound] = useState(false);
  const [penColor, setPenColor] = useState("#04CCB8");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await axios.get(
          `https://jcgnapi.hasthiya.org/activityForm/getAdminFormById/${id}`
        );
        if (response.data && response.data.success) {
          setFormData(response.data.form);
        } else {
          setFormNotFound(true); // Set state to indicate form not found
        }
      } catch (error) {
        console.error("Error fetching form data:", error);
        setFormNotFound(true); // Set state to indicate form not found on error
      }
    };

    fetchFormData();
  }, [id]);
  const handlePenColorChange = (color) => {
    setPenColor(color);
  };
  const handleSubmit = async (values) => {
    console.log("Submitting form...");
    setLoading(true);

    if (signatureRef.current.isEmpty()) {
      // alert("Please provide your signature.");
      setAlertSeverity("error");
      setAlertMessage("Please provide your signature");
      setOpenSnackbar(true);
      setLoading(false); // Ensure loading state is set to false on error path
      return;
    }

    const canvas = signatureRef.current.getCanvas();

    canvas.toBlob(async (blob) => {
      if (!blob) {
        console.error("Failed to convert canvas to blob.");
        setLoading(false); // Ensure loading state is set to false on error path
        return;
      }

      const formData = new FormData();

      formData.append("comments", values.commentsFeedback);
      formData.append("rejectedUserName", values.receivedRejectedBy);
      formData.append("approvedUserName", values.receivedApprovedBy);
      formData.append("title", values.titlePosition);
      formData.append("signatureImg", blob, "signature.png");

      try {
        const response = await axios.put(
          `https://jcgnapi.hasthiya.org/activityForm/updateAdminForm/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        clearSignature();

        setAlertSeverity("success");
        setAlertMessage("Form submitted successfully!");
        setOpenSnackbar(true);
        setLoading(false);

        setTimeout(() => {
          navigate("/activityreport");
        }, 3000);
      } catch (error) {
        console.error("Error submitting form:", error);
        // alert("Error submitting form. Please try again.");
        setAlertSeverity("error");
        setAlertMessage("Error submitting form. Please try again.");
        setOpenSnackbar(true);
        setLoading(false); // Ensure loading state is set to false on error
      }
    }, "image/png");
  };
  const clearSignature = () => {
    signatureRef.current.clear();
  };
  const validationSchema = yup.object().shape({
    commentsFeedback: yup.string().required("Comments/Feedback is required"),
    receivedRejectedBy: yup
      .string()
      .required("Received/Rejected by is required"),
    receivedApprovedBy: yup
      .string()
      .required("Received/Approved by is required"),
    titlePosition: yup.string().required("Title/Position is required"),
  });

  const canvasProps = {
    width: 500,
    height: 300,
    className: "sigCanvas",
    style: {
      border: "1px solid",
      background: "#fff",
      borderRadius: "20px",
      boxShadow: "-4px 5px 8px rgba(240, 20, 20, 0.11)",
      marginBottom: "16px",
    },
  };

  return (
    <Box m="20px" height="70vh" width="90%" overflow="auto" paddingRight="20px">
      <Header
        title={`Activity Report Id ${id} Administration`}
        // subtitle={`Report Created On: ${new Date(
        //   formData.createdAt
        // ).toLocaleString()}`}
      />
      {formData && formData.title ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            border: "1px solid #ccc",
            borderRadius: "8px",
            maxWidth: 800,
            marginBottom: "40px",

            p: 2,
          }}
        >
          {" "}
          <Typography variant="h6" fontWeight="bold" mb={1}>
            Previous Recorded Admin Report
          </Typography>
          <Box sx={{ flex: "10% 0 40%", marginLeft: "40px" }}>
            <Typography variant="body1" fontWeight="bold" mb={1}>
              Title
            </Typography>
            <Typography variant="body1" fontWeight="bold" mb={1}>
              Comments
            </Typography>
            <Typography variant="body1" fontWeight="bold" mb={1}>
              Rejected User Name
            </Typography>
            <Typography variant="body1" fontWeight="bold" mb={1}>
              Approved User Name
            </Typography>
            <Typography variant="body1" fontWeight="bold" mb={1}>
              Created On (MM/DD/YYYY)
            </Typography>
            <Typography variant="body1" fontWeight="bold" mb={1}>
              Updated On (MM/DD/YYYY)
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              Signature
            </Typography>
          </Box>
          <Box sx={{ flex: "0 0 40%" }}>
            <Typography variant="body1" mb={1}>
              :{formData.title || "Not Available"}
            </Typography>
            <Typography variant="body1" mb={1}>
              :{formData.comments || "Not Available"}
            </Typography>
            <Typography variant="body1" mb={1}>
              :{formData.rejectedUserName || "Not Available"}
            </Typography>
            <Typography variant="body1" mb={1}>
              :{formData.approvedUserName || "Not Available"}
            </Typography>
            <Typography variant="body1" mb={1}>
              :{new Date(formData.createdAt).toLocaleString()}
            </Typography>
            <Typography variant="body1" mb={1}>
              :{new Date(formData.updatedAt).toLocaleString()}
            </Typography>
            <Typography variant="body1">
              {formData.signatureImg && (
                <img
                  src={formData.signatureImg}
                  alt="Signature"
                  style={{
                    width: "100%",
                    maxWidth: 200,
                    height: "auto",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    marginTop: "8px",
                  }}
                />
              )}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ marginBottom: "20px" }}
        >
          No past records found for this form.
        </Typography>
      )}

      <Formik
        initialValues={{
          commentsFeedback: "",
          receivedRejectedBy: "",
          receivedApprovedBy: "",
          titlePosition: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Comments/Feedback*"
                name="commentsFeedback"
                value={values.commentsFeedback}
                onChange={handleChange}
                onBlur={handleBlur}
                multiline
                rows={5}
                error={touched.commentsFeedback && !!errors.commentsFeedback}
                helperText={touched.commentsFeedback && errors.commentsFeedback}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Received and Rejected by (full name)*"
                name="receivedRejectedBy"
                value={values.receivedRejectedBy}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.receivedRejectedBy && !!errors.receivedRejectedBy
                }
                helperText={
                  touched.receivedRejectedBy && errors.receivedRejectedBy
                }
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Received and Approved by (full name)*"
                name="receivedApprovedBy"
                value={values.receivedApprovedBy}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.receivedApprovedBy && !!errors.receivedApprovedBy
                }
                helperText={
                  touched.receivedApprovedBy && errors.receivedApprovedBy
                }
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Title/Position*"
                name="titlePosition"
                value={values.titlePosition}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.titlePosition && !!errors.titlePosition}
                helperText={touched.titlePosition && errors.titlePosition}
              />
              <Box>
                <div>
                  <h3>Signature</h3>
                  <br></br>
                  <SignatureCanvas
                    sx={{
                      marginTop: 2,
                    }}
                    ref={signatureRef}
                    canvasProps={canvasProps}
                    penColor={penColor}
                  />
                  <br />
                  <Button
                    variant="contained"
                    onClick={clearSignature}
                    sx={{
                      backgroundColor: "#6870fa",
                      color: "white",
                      marginRight: 2,
                      fontSize: "10px",
                      "&:hover": {
                        backgroundColor: "#3e4396",
                      },
                    }}
                  >
                    Clear Signature
                  </Button>
                  <input
                    type="color"
                    value={penColor}
                    onChange={(e) => handlePenColorChange(e.target.value)}
                    style={{ marginLeft: "20px" }}
                  />
                </div>
              </Box>

              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#6870fa",
                    color: "white",
                    marginRight: 2,
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#3e4396",
                    },
                  }}
                  disabled={loading}
                >
                  {loading ? "Updating..." : "Update"}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          sx={{ color: "#fff" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}: {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditActivity;
