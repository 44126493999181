import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Snackbar,
  IconButton,
  Typography,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import Header from "../../components/Header";
import DeleteIcon from "@mui/icons-material/Delete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import {
  createDoctor,
  getHospitalsAndHealthCenters,
} from "./../../ApiConfig.jsx";

import { MuiTelInput } from "mui-tel-input";

const AddDoctor = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hospitalData, setHospitalData] = useState([]);

  const getHospital = async () => {
    const response = await getHospitalsAndHealthCenters();
    setHospitalData(response);
  };

  useEffect(() => {
    getHospital();
  }, []);
  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const body = { ...values, status: "active" };
    try {
      const response = await createDoctor(body);
      if (response.success) {
        setAlertSeverity("success");
        setAlertMessage("Doctor added successfully");
        setTimeout(() => {
          navigate("/doctorManagement");
        }, 2500);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new doctor : ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title="Add New Doctor" subtitle="" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} style={{ height: "41vh" }}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </Box>
              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Field*</InputLabel>
                  <Select
                    variant="filled"
                    value={values.field}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="field"
                    error={!!touched.field && !!errors.field}
                    helperText={touched.field && errors.field}>
                    <MenuItem value="md">MD</MenuItem>
                    <MenuItem value="assist_md">Assist MD</MenuItem>
                  </Select>
                  {touched.field && errors.field && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {errors.field}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>

              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Professional Id*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.professionalId}
                  name="professionalId"
                  error={!!touched.professionalId && !!errors.professionalId}
                  helperText={touched.professionalId && errors.professionalId}
                />
              </Box>
              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Hospital*</InputLabel>
                  <Select
                    variant="filled"
                    value={values.hospitalId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="hospitalId"
                    error={!!touched.hospitalId && !!errors.hospitalId}
                    helperText={touched.hospitalId && errors.hospitalId}>
                    {hospitalData.map((data) => (
                      <MenuItem value={data.id}>{data.name}</MenuItem>
                    ))}
                  </Select>
                  {touched.hospitalId && errors.hospitalId && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {errors.hospitalId}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="mail"
                  label="Email*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
              </Box>
              <Box>
                <MuiTelInput
                  inputProps={{ maxLength: 16 }}
                  fullWidth
                  variant="filled"
                  defaultCountry={"us"}
                  label="Phone*"
                  onBlur={handleBlur}
                  onChange={(value, country) =>
                    handleChange({ target: { name: "phone", value } })
                  }
                  value={values.phone}
                  name="phone"
                  error={!!touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}>
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}>
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};
const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const checkoutSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  field: yup.string().required("Field is required"),
  professionalId: yup.string().required("Professional Id is required"),
  hospitalId: yup.string().required("Hospital is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup
    .string()
    .matches(/^\+\d(\s*\d){5,13}$/, "Phone number is invalid")
    .required("Phone number is required"),
});

const initialValues = {
  name: "",
  field: "",
  professionalId: "",
  hospitalId: "",
  phone: "+",
  email: "",
};

export default AddDoctor;
