import { Visibility as VisibilityIcon } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Settings";
import { Box, IconButton, Tooltip, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";

const CareerRepliers = () => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://jcgnapi.hasthiya.org/api/career/getAllUserCarrer"
        );
        const { result } = await response.json();
        if (result && Array.isArray(result)) {
          const mappedData = result.map((item) => ({
            id: item.id,
            name: item.fullName,
            email: item.email,
            phone: item.phone,
            jobTitle: item.jobTitle,
            createdAt: formatDate(item.createdAt),
            status: item.careeradminreport?.status || "Unknown",
          }));

          // Sort mappedData by ID in descending order
          mappedData.sort((a, b) => b.id - a.id);

          setData(mappedData);
        }
      } catch (error) {
        console.error("Error fetching career responses:", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return date.toLocaleString(); // Format date-time string to readable format
  };

  const handleEditClick = (id) => {
    console.log(`Edit clicked id:`, id);
  };

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        // If user confirms deletion, send DELETE request to the API
        fetch(`https://jcgnapi.hasthiya.org/api/career/deleteCarrer/${id}`, {
          method: "DELETE",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to delete record");
            }
            // Update UI or fetch data again if needed
            const updatedData = data.filter((item) => item.id !== id);
            setData(updatedData);
            Swal.fire("Deleted!", "The record has been deleted.", "success");
          })
          .catch((error) => {
            console.error("Error deleting record:", error);
            Swal.fire(
              "Error!",
              "Failed to delete record. Please try again later.",
              "error"
            );
          });
      }
    });
  };

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "Applicant's Name", flex: 1 },
    { field: "phone", headerName: "Phone Number", flex: 1 },
    { field: "jobTitle", headerName: "Job Title", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "createdAt", headerName: "Date Time (MM/DD/YYYY)", flex: 1 },
    { field: "status", headerName: "Status", flex: 0.5 },

    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.8,
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <Link to={`/careers/repliers/viewcareer/${params.row.id}`}>
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Control">
            <Link to={`/careers/repliers/controlcareer/${params.row.id}`}>
              <IconButton onClick={() => handleEditClick(params.row.id)}>
                <EditIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="-10px"
      >
        <Header
          title="Career Responses"
          subtitle="Managing received career applicants"
        />
      </Box>

      <Box
        m="10px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default CareerRepliers;
