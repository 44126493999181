import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import {
  formatDateToReadable,
  getMembershipCardByID,
} from "./../../ApiConfig.jsx";

function ViewMembershipCard() {
  const { id } = useParams();
  const [membershipCardDetails, setMembershipCardDetails] = useState({});

  const GetMembershipCardByID = async (id) => {
    try {
      const data = await getMembershipCardByID(id);

      if (data.status) {
        setMembershipCardDetails(data.result);
      }
    } catch (error) {
      console.error("Error fetching membership card:", error);
    }
  };

  useEffect(() => {
    GetMembershipCardByID(id);
  }, [id]);

  return (
    <div>
      {" "}
      <Box m="20px" height="70vh" overflow="auto">
        <Header title={`View Membership Card ID: ${id}`} subtitle="" />
        <Box ml={"40px"}>
          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Member Id:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{membershipCardDetails.memberId}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Name:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{membershipCardDetails.name}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Category:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{membershipCardDetails.category}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Added Date:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  {formatDateToReadable(membershipCardDetails.startDate)}
                </Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  End Date:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  {formatDateToReadable(membershipCardDetails.expDate)}
                </Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Type:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{membershipCardDetails.type}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Size:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{membershipCardDetails.size}</Typography>
              </Grid>
            </div>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

export default ViewMembershipCard;
