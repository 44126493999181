import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/Header";
import { getEvent, updateEvent } from "./../../ApiConfig.jsx";

const validationSchema = yup.object().shape({
  name: yup.string().required("Event Name is required"),
  status: yup.string().required("Status is required"),
  type: yup.string().required("Type is required"),
  location: yup.string().required("Location is required"),
  start_date: yup.date().required("Start Date is required"),
  start_time: yup.string().required("Start time is required"),
  numOfParticipant: yup
    .string()
    .required("Event Participants amount is required"),
  end_date: yup.date().required("End Date is required"),
  charges: yup.string().when("type", {
    is: "Paid",
    then: yup
      .string()
      .required("Charges are required")
      .matches(/^\d+$/, "Charges must contain only numbers"),
    otherwise: yup.string(),
  }),
  event_image: yup.mixed(),
});

function EditAudio() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const initialUserDetails = {
    type: "",
    name: "",
    status: "",
    start_date: "",
    start_time: "",
    end_date: "",
    location: "",
    numOfParticipant: "",
    charges: "",
    event_image: null,
  };

  const [userDetails, setUserDetails] = useState(initialUserDetails);
  const [errors, setErrors] = useState({});
  const [currentImage, setCurrentImage] = useState("");

  const fetchEvent = async (id) => {
    try {
      const data = await getEvent(id);
      if (data.status) {
        const formattedStartDate = new Date(data.data.start_date)
          .toISOString()
          .split("T")[0];
        const formattedEndDate = new Date(data.data.end_date)
          .toISOString()
          .split("T")[0];

        setCurrentImage(data.data.img_url);
        setUserDetails({
          type: data.data.type,
          name: data.data.name,
          status: data.data.status,
          location: data.data.location,
          numOfParticipant: data.data.numOfParticipant,
          start_date: formattedStartDate,
          start_time: data.data.start_time,
          end_date: formattedEndDate,
          charges: data.data.charges,
          event_image: null,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEvent(id);
  }, [id]);

  const handleFieldChange = (field, value) => {
    if (field === "type" && value === "Free") {
      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        charges: "",
      }));
      setErrors((prev) => ({
        ...prev,
        charges: "",
      }));
    }
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [field]: value,
    }));
    validateField(field, value);
  };

  const validateField = (field, value) => {
    validationSchema
      .validateAt(field, { ...userDetails, [field]: value })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: error.message }));
      });
  };

  const updateEventHandler = async () => {
    if (
      userDetails.event_image &&
      !userDetails.event_image.type.startsWith("image/")
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        event_image: "Image must be a valid image type",
      }));
      return;
    }

    const hasErrors = Object.values(errors).some(
      (errorMessage) => errorMessage !== ""
    );

    if (hasErrors) {
      console.log("Errors found.");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", userDetails.name);
    formData.append("type", userDetails.type);
    formData.append("start_date", userDetails.start_date);
    formData.append("end_date", userDetails.end_date);
    formData.append("start_time", userDetails.start_time);
    formData.append("location", userDetails.location);
    formData.append("numOfParticipant", userDetails.numOfParticipant);
    formData.append("charges", userDetails.charges);
    formData.append("status", userDetails.status);
    formData.append("event-image", userDetails.event_image);

    try {
      const res = await updateEvent(id, formData);

      if (res.status) {
        setAlertSeverity("success");
        setAlertMessage("Event updated successfully");

        setTimeout(() => {
          navigate("/event");
        }, 3000);
      }
    } catch (err) {
      console.log(err);
      setAlertSeverity("error");
      setAlertMessage(`Failed to update event: ${err.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`Edit Event ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Name *
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.name}
              onChange={(e) => handleFieldChange("name", e.target.value)}
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Type*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={userDetails.type}
                onChange={(e) => handleFieldChange("type", e.target.value)}
                name="type"
                error={!!errors.type}
              >
                <MenuItem value="Free">Free</MenuItem>
                <MenuItem value="Paid">Paid</MenuItem>
              </Select>
              {errors.type && (
                <Typography variant="caption" color="error">
                  {errors.type}
                </Typography>
              )}
            </FormControl>
          </Grid>
          {userDetails.type === "Paid" && (
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                Charges*
              </Typography>
              <TextField
                type="text"
                fullWidth
                variant="filled"
                value={userDetails.charges}
                onChange={(e) => handleFieldChange("charges", e.target.value)}
                error={!!errors.charges}
                helperText={errors.charges}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Start Date*
            </Typography>
            <TextField
              type="date"
              fullWidth
              variant="filled"
              value={userDetails.start_date}
              onChange={(e) => handleFieldChange("start_date", e.target.value)}
              error={!!errors.start_date}
              helperText={errors.start_date}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              End Date*
            </Typography>
            <TextField
              type="date"
              fullWidth
              variant="filled"
              value={userDetails.end_date}
              onChange={(e) => handleFieldChange("end_date", e.target.value)}
              error={!!errors.end_date}
              helperText={errors.end_date}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Start Time*
            </Typography>
            <TextField
              type="time"
              fullWidth
              variant="filled"
              value={userDetails.start_time}
              onChange={(e) => handleFieldChange("start_time", e.target.value)}
              error={!!errors.start_time}
              helperText={errors.start_time}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Location*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.location}
              onChange={(e) => handleFieldChange("location", e.target.value)}
              error={!!errors.location}
              helperText={errors.location}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Number of Participants*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.numOfParticipant}
              onChange={(e) =>
                handleFieldChange("numOfParticipant", e.target.value)
              }
              error={!!errors.numOfParticipant}
              helperText={errors.numOfParticipant}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <div style={{ fontWeight: "bold" }}>Image*</div>
              <TextField
                fullWidth
                variant="filled"
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleFieldChange("event_image", e.target.files[0])
                }
                name="image"
                error={!!errors.event_image}
                helperText={errors.event_image}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Status*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={userDetails.status}
                onChange={(e) => handleFieldChange("status", e.target.value)}
                name="status"
                error={!!errors.status}
              >
                <MenuItem value="Upcoming">Upcoming</MenuItem>
                <MenuItem value="Booked/Active">Booked/Active</MenuItem>
                <MenuItem value="Cancelled">Cancelled</MenuItem>
                <MenuItem value="Last Events">Last Events</MenuItem>
              </Select>
              {errors.status && (
                <Typography variant="caption" color="error">
                  {errors.status}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <div style={{ fontWeight: "bold" }}>Current Image</div>
              <img src={currentImage} style={{ width: "100%" }} alt="" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={updateEventHandler}
            >
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}

export default EditAudio;
