import {
  Box,
  Button,
  IconButton,
  Tooltip,
  useTheme,
  Select,
  Snackbar,
  MenuItem,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import Swal from "sweetalert2";
import {
  Edit as EditIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import MuiAlert from "@mui/material/Alert";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  getAllSermon,
  updateSermon,
  deleteSermon,
} from "./../../ApiConfig.jsx";

const SermonsManagement = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const fetchData = async () => {
    const audioData = await getAllSermon();
    setData(audioData);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleStatusChange = async (id, newStatus, params) => {
    try {
      console.log(params);
      const formData = new FormData();
      formData.append("addedDate", params.addedDate);
      formData.append("description", params.description);
      formData.append("duration", params.duration);
      formData.append("language", params.language);
      formData.append("preacther", params.preacther);
      formData.append("status", newStatus);
      formData.append("title", params.title);
      formData.append("type", params.type);
      formData.append("video_payment_method", params.video_payment_method);
      formData.append("note_payment_method", params.note_payment_method);
      formData.append("noteAmount", params.noteAmount);
      formData.append("videoAmount", params.videoAmount);


      const res = await updateSermon(id, formData);
      if (res.status) {
        const updatedData = data.map((item) => {
          if (item.id === id) {
            return { ...item, status: newStatus };
          }
          return item;
        });
        setData(updatedData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    { field: "id", headerName: "S.NO" },
    {
      field: "title",
      headerName: "Sermon Title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "language",
      headerName: "Language",
      flex: 1,
    },
    {
      field: "preacther",
      headerName: "Preacher",
      flex: 1,
    },
    {
      field: "addedDate",
      headerName: "Added Date",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Select
          value={params.row.status}
          onChange={(event) =>
            handleStatusChange(params.row.id, event.target.value, params.row)
          }
          style={{ width: "100px" }}
        >
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Inactive">Inactive</MenuItem>
        </Select>
      ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <Link to={`/sermons/view/${params.row.id}`}>
              {" "}
              {/* Construct URL dynamically */}
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={() => handleEditClick(params.row.id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this sermon!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deleteResponse = await deleteSermon(id);
          if (!deleteResponse.status) {
            throw new Error("Failed to delete sermon");
          }
          fetchData();
          setAlertSeverity("success");
          setAlertMessage("Sermon deleted successfully");
        } catch (error) {
          console.log(error);
          setAlertSeverity("error");
          setAlertMessage(`Failed to delete sermon: ${error.message}`);
        } finally {
          setOpenSnackbar(true);
        }
      }
    });
  };

  const handleEditClick = (id) => {
    navigate(`/sermons/edit/${id}`);
    console.log("Edit clicked for id:", id);
  };
  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Header title="Sermons Management" subtitle="Managing the Sermons" />
        <Box>
          <Link to={"createsemons"} style={{ marginRight: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
            >
              Add Sermons
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default SermonsManagement;
