import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const AppointmentLogs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://jcgnapi.hasthiya.org/api/appointment/getAllAppointments"
      );
      const responseData = await response.json();
      const sortedData = responseData.result.sort((a, b) => b.id - a.id);
      setData(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleStatusChange = async (id, newStatus) => {
    const result = await Swal.fire({
      title: `Are you sure you want to change the status to ${newStatus}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `https://jcgnapi.hasthiya.org/api/appointment/updateAppointmentByID/${id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ status: newStatus }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update appointment status");
        }

        fetchData();
        Swal.fire(
          "Updated!",
          "Appointment status has been changed.",
          "success"
        );
      } catch (error) {
        Swal.fire("Error!", "Failed to update appointment status.", "error");
      }
    }
  };

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this appointment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `https://jcgnapi.hasthiya.org/api/appointment/deleteAppointmentByID/${id}`,
          {
            method: "DELETE",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to delete appointment");
        }

        fetchData();
        Swal.fire("Deleted!", "Appointment has been deleted.", "success");
      } catch (error) {
        console.error("Error deleting appointment:", error);
        Swal.fire("Error!", "Failed to delete appointment.", "error");
      }
    }
  };

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "appointment_date",
      headerName: "Appointment Date (MM/DD/YYYY)",
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Select
          value={params.row.status}
          onChange={(event) =>
            handleStatusChange(params.row.id, event.target.value)
          }
        >
          <MenuItem value="Finished">Finished</MenuItem>
          <MenuItem value="Pending">Pending&nbsp;</MenuItem>
          <MenuItem value="Cancel">Cancel&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>
        </Select>
      ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <Link to={`/appointment/view/${params.row.id}`}>
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const getRowClassName = () => {
    return "row-divider";
  };

  const exportToPdf = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [["ID", "Name", "Email", "Date Submitted (MM/DD/YYYY)", "Status"]],
      body: data.map(({ id, name, email, appointment_date, status }) => [
        id,
        name,
        email,
        new Date(appointment_date).toLocaleDateString("en-US"),
        status,
      ]),
    });
    doc.save("appointment_logs.pdf");
  };

  return (
    <Box m="20px">
      <Header
        title="Appointment Logs"
        subtitle="Show all the appointments have been submitted by users."
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="0px"
      >
        <Button
          variant="contained"
          onClick={exportToPdf}
          sx={{
            backgroundColor: "#4caf50",
            color: "white",
            fontSize: "10px",
            "&:hover": {
              backgroundColor: "#388e3c",
            },
          }}
        >
          Export to PDF
        </Button>
      </Box>
      <Box
        m="0 0 0 0"
        height="57vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .row-divider": {
            borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowClassName={getRowClassName}
        />
      </Box>
    </Box>
  );
};

export default AppointmentLogs;
