import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import { getPharmacyById } from "./../../ApiConfig.jsx";

function ViewPharmacy() {
  const { id } = useParams();
  const [itemDetails, setItemDetails] = useState({});
  const fetchData = async (id) => {
    try {
      const data = await getPharmacyById(id);
      if (data.succes) {
        console.log(data.data);
        setItemDetails({
          Name: data.data.name,
          Id: data.data.pharmacyId,
          Address: data.data.address,
          Phone: data.data.phone,
          Email: data.data.email,
          Type: data.data.type === "public" ? "Public" : "Private",
          "Contact person": data.data.contactPerson,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`View Pharmacy ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(itemDetails).map(([field, value]) => (
            <React.Fragment key={field}>
              <Grid item xs={3}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  {field}:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>{value}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewPharmacy;
