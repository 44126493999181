import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Snackbar,
  IconButton,
  Typography,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import Header from "../../components/Header";
import DeleteIcon from "@mui/icons-material/Delete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { createFeeSchedulesForHospitalServicesManagement } from "./../../ApiConfig.jsx";

const AddFeeSchedulesForHospitalServices = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values) => {

    setIsLoading(true);
    const body = {
      ...values,
      status: "active",
      isCovered: values.isCovered === "true",
    };
    try {
      const response = await createFeeSchedulesForHospitalServicesManagement(
        body
      );
      if (response.status) {
        setAlertSeverity("success");
        setAlertMessage("Fee Schedule added successfully");
        setTimeout(() => {
          navigate("/feeSchedulesForHospitalServicesManagement");
        }, 2500);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new fee schedule : ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title="Add New Fee Schedule" subtitle="" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} style={{ height: "41vh" }}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Service*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.services}
                  name="services"
                  error={!!touched.services && !!errors.services}
                  helperText={touched.services && errors.services}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Type of service*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.typeOfService}
                  name="typeOfService"
                  error={!!touched.typeOfService && !!errors.typeOfService}
                  helperText={touched.typeOfService && errors.typeOfService}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Hospital name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.hospitalname}
                  name="hospitalname"
                  error={!!touched.hospitalname && !!errors.hospitalname}
                  helperText={touched.hospitalname && errors.hospitalname}
                />
              </Box>

              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Is covered*</InputLabel>
                  <Select
                    variant="filled"
                    value={values.isCovered}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="isCovered"
                    error={!!touched.isCovered && !!errors.isCovered}
                    helperText={touched.isCovered && errors.isCovered}>
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                  {touched.isCovered && errors.isCovered && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {errors.isCovered}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Fee*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fee}
                  name="fee"
                  error={!!touched.fee && !!errors.fee}
                  helperText={touched.fee && errors.fee}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}>
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}>
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  services: yup.string().required("Service is required"),
  typeOfService: yup.string(),
  hospitalname: yup.string().required("Hospital name is required"),
  isCovered: yup.string().required("Covered is required"),
  fee: yup
    .string()
    .required("Fee is required")
    .matches(/^\d+$/, "Fee must contain only numbers"),
});

const initialValues = {
  services: "",
  typeOfService: "",
  hospitalname: "",
  isCovered: "",
  fee: "",
};

export default AddFeeSchedulesForHospitalServices;
