import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_FORM_FIELDS_BY_ID } from "../../ApiConfig";
import Header from "../../components/Header";

function FormsUserView() {
  const { id } = useParams();
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await fetch(GET_FORM_FIELDS_BY_ID(id));
        if (!response.ok) {
          throw new Error(`Failed to fetch form data for ID ${id}`);
        }
        const responseData = await response.json();
        if (responseData.success && responseData.form) {
          setFormData(responseData.form);
        } else {
          console.error("Failed to fetch form data:", responseData);
        }
      } catch (error) {
        console.error("Error fetching form data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFormData();
  }, [id]);

  const formatFieldLabel = (fieldName) => {
    if (fieldName === "godDoneForYouData") {
      return "What has God Done For You";
    }

    return fieldName
      .split(/(?=[A-Z])/)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
  };

  const renderValue = (value) => {
    if (value === null || value === "") {
      return null;
    } else if (typeof value === "object" && value !== null) {
      return JSON.stringify(value);
    } else if (typeof value === "string" && value.includes("T")) {
      return new Date(value).toLocaleString();
    } else {
      return value;
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!formData) {
    return <Typography>No data found</Typography>;
  }

  const { addAnotherArea, ...otherFields } = formData;
  const fieldsToRender = { ...otherFields, addAnotherArea };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`View Request User ID ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(fieldsToRender)
            .filter(([_, value]) => value !== null && value !== "")
            .map(([field, value]) => (
              <Grid item xs={12} key={field}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography
                      variant="h5"
                      component="span"
                      fontWeight="bold"
                      mt={3}
                    >
                      {formatFieldLabel(field)}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      variant="h5"
                      component="span"
                      fontWeight="bold"
                      mt={3}
                    >
                      :
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" component="span" mt={3}>
                      {renderValue(value)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default FormsUserView;
