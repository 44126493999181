import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";

const BibleModuleDetail = () => {
  const [moduleDetail, setModuleDetail] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetchModuleDetail();
  }, []);

  const fetchModuleDetail = async () => {
    try {
      const response = await axios.get(
        `https://jcgnapi.hasthiya.org/api/bibalModule/getBibleModuleDetailByCourseModuleId/${id}`
      );
      if (response.data.status) {
        setModuleDetail(response.data.result[0]);
      }
    } catch (error) {
      console.error("Error fetching module detail:", error);
    }
  };

  if (!moduleDetail) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box m="20px" height="75vh" overflow="auto">
      <Header
        title={`View Module Details By ID ${id}`}
        subtitle="View all module details"
      />

      <Grid container spacing={2}>
        {/* Instructor Name */}
        <Grid item xs={2}>
          <Typography variant="h6">Instructor Name</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>
            {moduleDetail.course_info_instructer_info_name}
          </Typography>
        </Grid>

        {/* Office */}
        <Grid item xs={2}>
          <Typography variant="h6">Office</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>
            {moduleDetail.course_info_instructer_info_office}
          </Typography>
        </Grid>

        {/* Office Hours */}
        <Grid item xs={2}>
          <Typography variant="h6">Office Hours</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>
            {moduleDetail.course_info_instructer_info_office_hours}
          </Typography>
        </Grid>

        {/* Telephone */}
        <Grid item xs={2}>
          <Typography variant="h6">Telephone</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>
            {moduleDetail.course_info_instructer_info_tele}
          </Typography>
        </Grid>

        {/* Email */}
        <Grid item xs={2}>
          <Typography variant="h6">Email</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>
            {moduleDetail.course_info_instructer_info_mail}
          </Typography>
        </Grid>

        {/* Course Description */}
        <Grid item xs={2}>
          <Typography variant="h6">Course Description</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{moduleDetail.course_info_course_description}</Typography>
        </Grid>

        {/* Required Texts */}
        <Grid item xs={2}>
          <Typography variant="h6" sx={{ mb: "-15px" }}>
            Required Texts
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">:</Typography>
        </Grid>
        <Grid item xs={9}>
          {" "}
          <Typography variant="h6">Required Texts Topics</Typography>
          <ul>
            {moduleDetail.course_info_required_text.map((text) => (
              <li key={text.id}>{text.course_info_required_text}</li>
            ))}
          </ul>
        </Grid>

        {/* Course Requirements */}
        <Grid item xs={2}>
          <Typography variant="h6">Course Requirements</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">:</Typography>
        </Grid>
        <Grid item xs={9}>
          {" "}
          <Typography variant="h6">Course Requirements</Typography>
          <ul>
            {moduleDetail.course_info_course_requirements.map((req) => (
              <li key={req.id}>{req.course_info_course_requirements}</li>
            ))}
          </ul>
        </Grid>

        {/* Student Learning Topics */}
        <Grid item xs={2}>
          <Typography variant="h6">Student Learning Topics</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">:</Typography>
        </Grid>
        <Grid item xs={9}>
          {moduleDetail.student_learning_topic_outline.map((topic) => (
            <Box key={topic.id} mb={2}>
              <Typography>
                <strong>Week :</strong> {topic.student_learning_topic_week}
              </Typography>
              <Typography>
                <strong>Topic :</strong> {topic.student_learning_topic_topic}
              </Typography>
              <Typography>
                <strong>Readings :</strong>{" "}
                {topic.student_learning_topic_readings}
              </Typography>
              <Typography>
                <strong>Activities :</strong>{" "}
                {topic.student_learning_topic_activities}
              </Typography>
              <Typography>
                <strong>Due Date :</strong>{" "}
                {topic.student_learning_topic_due_date}
              </Typography>
            </Box>
          ))}
        </Grid>

        {/* Grading Policy */}
        <Grid item xs={2}>
          <Typography variant="h6">Grading Policy</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">:</Typography>
        </Grid>
        <Grid item xs={9}>
          {moduleDetail.grading_policy.map((policy) => (
            <Box key={policy.id} mb={2}>
              <Typography>
                <strong>Grade :</strong>{" "}
                {policy.grading_policy_Letter_grade_grade}
              </Typography>
              <Typography>
                <strong>Percentage :</strong>{" "}
                {policy.grading_policy_Letter_grade_percentage}
              </Typography>
              <Typography>
                <strong>Performance :</strong>{" "}
                {policy.grading_policy_Letter_grade_performance}
              </Typography>
            </Box>
          ))}
        </Grid>

        {/* Timestamps */}
        <Grid item xs={2}>
          <Typography variant="h6">Created At (MM/DD/YYYY)</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>
            {new Date(moduleDetail.createdAt).toLocaleString()}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6">Updated At (MM/DD/YYYY)</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>
            {new Date(moduleDetail.updatedAt).toLocaleString()}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BibleModuleDetail;
