import { Box, Button, Snackbar, TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/Header";
import { createCourse } from "./../../ApiConfig.jsx";

const CreateNewCourse = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    console.log(values);
    try {
      setIsLoading(true);

      const body = {
        bibleCourseName: values.name,
      };
      const response = await createCourse(body);
      if (response.status) {
        setAlertSeverity("success");
        setAlertMessage("Course added successfully");
        setTimeout(() => {
          navigate("/courses");
        }, 2500);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new course: ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px">
      <Header title="Create Course" subtitle="Create a New Course" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} height="41vh">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Course Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </Box>

              {/* <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Credits"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.credits}
                  name="credits"
                  error={!!touched.credits && !!errors.credits}
                  helperText={touched.credits && errors.credits}
                />
              </Box> */}

              {/* <Box>
                <InputLabel htmlFor="eventType">Category*</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Event Type"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.category}
                  name="category"
                  error={!!touched.category && !!errors.category}
                  helperText={touched.category && errors.category}
                >
                  <MenuItem value="Select Event Type" selected disabled>
                    Select category
                  </MenuItem>
                  <MenuItem value="Biblical Courses">Biblical Courses</MenuItem>
                  <MenuItem value="Missions Courses">Missions Courses</MenuItem>
                  <MenuItem value="Theological Courses">
                    Theological Courses
                  </MenuItem>
                  <MenuItem value="Counseling Courses">
                    Counseling Courses
                  </MenuItem>
                  <MenuItem value="Leadership Courses">
                    Leadership Courses
                  </MenuItem>
                  <MenuItem value="Christian Education Courses,Ministry Courses">
                    Christian Education Courses/Ministry Courses
                  </MenuItem>
                  <MenuItem value="Business and Management Courses">
                    Business and Management Courses
                  </MenuItem>
                </Select>
                {touched.category && errors.category && (
                  <FormHelperText
                    style={{ color: "#f44336", paddingLeft: "15px" }}
                  >
                    {errors.category}
                  </FormHelperText>
                )}
              </Box> */}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
              >
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("Course Name is required"),
  // category: yup.string().required("Category type is required"),
  // credits: yup
  //   .string()
  //   .required("Credits is required")
  //   .matches(/^\d+(\.\d+)?$/, "Credits must be a valid number"),
});

const initialValues = {
  name: "",
  category: "",
  credits: "",
};

export default CreateNewCourse;
