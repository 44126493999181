import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Snackbar,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import MuiAlert from "@mui/material/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { getArtistByID, updateArtist } from "../../ApiConfig";

const EditArtist = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const [updatedArtistDetails, setUpdatedArtistDetails] = useState({
    artistName: "",
    description: "",
    type: "",
    language: "",
    image: null,
    status: "",
  });
  const [errors, setErrors] = useState({});
  const [imageName, setImageName] = useState("");

  const GetArtistById = async (id) => {
    try {
      const data = await getArtistByID(id);
      setUpdatedArtistDetails({
        artistName: data.result.artist_name,
        description: data.result.description,
        language: data.result.language,
        type: data.result.type,
        image: data.result.image_url,
      });
      const imageUrl = data.result.image_url;
      const imageFileName = decodeURIComponent(
        imageUrl.split("/").pop().split("%20").pop().split("?")[0]
      );
      setImageName(imageFileName);
    } catch (error) {
      console.error("Error fetching artist:", error);
    }
  };

  useEffect(() => {
    GetArtistById(id);
  }, [id]);

  const handleInputChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });
    setUpdatedArtistDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    if (name === "image" && value) {
      setImageName(value.name);
    }
    validateForm();
  };

  const validateForm = () => {
    const errors = {};

    if (!updatedArtistDetails.artistName) {
      errors.artistName = "Artist Name is required.";
    }
    if (!updatedArtistDetails.description) {
      errors.description = "Description is required.";
    }
    if (!updatedArtistDetails.language) {
      errors.language = "Language is required.";
    }
    if (!updatedArtistDetails.type) {
      errors.type = "Type is required.";
    }
    if (!updatedArtistDetails.image) {
      errors.image = "Image is required.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const handleUpdateArtist = async () => {
    if (validateForm()) {
      setIsLoading(true);

      const body = {
        artist_name: updatedArtistDetails.artistName,
        description: updatedArtistDetails.description,
        language: updatedArtistDetails.language,
        type: updatedArtistDetails.type,
        image: updatedArtistDetails.image,
      };

      try {
        const response = await updateArtist(id, body);
        if (response.status) {
          setAlertSeverity("success");
          setAlertMessage("Artist updated successfully");
          setTimeout(() => {
            navigate("/artist-management");
          }, 2000);
        }
      } catch (error) {
        setAlertSeverity("error");
        setAlertMessage(`Failed artist update: ${error.message}`);
      } finally {
        setOpenSnackbar(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`UPDATE ARTIST ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Artist Name*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedArtistDetails.artistName}
              onChange={(e) => handleInputChange("artistName", e.target.value)}
              error={!!errors.artistName}
              helperText={errors.artistName}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1" fontWeight="bold">
              Description*(Limited to 1000 characters)
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedArtistDetails.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              multiline
              rows={5}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Language*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={updatedArtistDetails.language}
                onChange={(e) => handleInputChange("language", e.target.value)}
                name="language"
                error={!!errors.language}
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="French">French</MenuItem>
              </Select>
              {errors.language && (
                <Typography variant="caption" color="error">
                  {errors.language}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Type*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={updatedArtistDetails.type}
                onChange={(e) => handleInputChange("type", e.target.value)}
                name="type"
                error={!!errors.type}
              >
                <MenuItem value="Book">Book</MenuItem>
                <MenuItem value="Article">Article</MenuItem>
              </Select>
              {errors.type && (
                <Typography variant="caption" color="error">
                  {errors.type}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>Image*</div>
              <TextField
                fullWidth
                variant="filled"
                type="file"
                accept="image/*"
                onChange={(e) => handleInputChange("image", e.target.files[0])}
                name="image"
                error={!!errors.image}
                helperText={errors.image}
              />
              {imageName && (
                <Typography variant="subtitle1" mt={1}>
                  {`Current image: ${imageName}`}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={handleUpdateArtist}
            >
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditArtist;
