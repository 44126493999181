import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const BurialExpences = () => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const fetchBurialExpenses = async () => {
    try {
      const response = await fetch(
        "https://jcgnapi.hasthiya.org/bural/getAccpetBurals"
      );
      const responseData = await response.json();

      if (responseData.status) {
        const modifiedData = responseData.result.map((item) => ({
          ...item,
          submitDate: item.submitDate.split("T")[0],
          recivedDate: item.recivedDate.split("T")[0],
          approvedDate: item.approvedDate.split("T")[0],
        }));

        modifiedData.sort((a, b) => b.id - a.id);

        setData(modifiedData);
      } else {
        console.error("Failed to fetch burial expenses:", responseData.message);
      }
    } catch (error) {
      console.error("Error fetching burial expenses:", error);
    }
  };

  useEffect(() => {
    fetchBurialExpenses();
  }, []);

  const exportToPdf = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "ID",
          "Document Name",
          "Issued By",
          "Claim Amount",
          "Submit Date",
          "Received Date",
          "Approved Date",
          "Claim Status",
        ],
      ],
      body: data.map(
        ({
          id,
          docname,
          issuedby,
          claimAmount,
          submitDate,
          recivedDate,
          approvedDate,
          claimStatus,
        }) => [
          id,
          docname,
          issuedby,
          claimAmount,
          submitDate,
          recivedDate,
          approvedDate,
          claimStatus,
        ]
      ),
    });
    doc.save("burial_expenses_data.pdf");
  };

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this burial expense!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://jcgnapi.hasthiya.org/bural/deleteAccpetBural/${id}`, {
          method: "DELETE",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to delete burial expense record");
            }

            setData(data.filter((item) => item.id !== id));
            Swal.fire(
              "Deleted!",
              "The burial expense record has been deleted.",
              "success"
            );
          })
          .catch((error) => {
            console.error("Error deleting burial expense:", error);
            Swal.fire(
              "Error!",
              "Failed to delete burial expense. Please try again later.",
              "error"
            );
          });
      }
    });
  };

  const handleEditClick = (id) => {
    // Handle edit logic
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.3 },
    { field: "docname", headerName: "Document Name", flex: 1.5 },
    { field: "issuedby", headerName: "Issued By", flex: 1 },
    { field: "claimAmount", headerName: "Claim Amount", flex: 0.5 },
    { field: "submitDate", headerName: "Submit Date (Y/M/D)", flex: 0.8 },
    // { field: "recivedDate", headerName: "Received Date (YYYY/MM/DD)", flex: 1 },
    {
      field: "approvedDate",
      headerName: "Approved Date (Y/M/D)",
      flex: 0.8,
    },
    { field: "claimStatus", headerName: "Claim Status", flex: 0.5 },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.8,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Edit">
            <Link
              to={`/burialexpensesclaim/editburialexpensesclaim/${params.row.id}`}
            >
              <IconButton onClick={() => handleEditClick(params.row.id)}>
                <EditIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="View">
            <Link
              to={`/burialexpensesclaim/viewburialexpensesclaim/${params.row.id}`}
            >
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="-10px"
      >
        <Header
          title="Burial Expenses Management"
          subtitle="Managing the burial expenses"
        />
        <Box>
          <Link
            to={"/burialexpensesclaim/addburialexpenses"}
            style={{ marginRight: "10px" }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
            >
              Add a Burial Expense
            </Button>
          </Link>
        </Box>
      </Box>
      <Box
        m="10px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none", fontSize: "14px" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.greenAccent[300] },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Button
          variant="contained"
          onClick={exportToPdf}
          sx={{
            backgroundColor: "#4caf50",
            color: "white",
            fontSize: "10px",
            "&:hover": {
              backgroundColor: "#388e3c",
            },
          }}
        >
          Export as PDF
        </Button>
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default BurialExpences;
