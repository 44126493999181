import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../components/Header";

const ViewPreTour = () => {
  const { id } = useParams();
  const [preTourDetails, setPreTourDetails] = useState({});

  useEffect(() => {
    fetchPreTourDetails();
  }, [id]);

  const fetchPreTourDetails = async () => {
    try {
      const response = await fetch(
        `https://jcgnapi.hasthiya.org/api/tour/getPreTourRegistrationById/${id}`
      );
      const responseData = await response.json();
      if (responseData.status) {
        const modifiedData = {
          ...responseData.result,
          travelDate: formatDate(responseData.result.travelDate),
          createdAt: formatDateTime(responseData.result.createdAt),
          updatedAt: formatDateTime(responseData.result.updatedAt),
        };
        delete modifiedData.date;
        handleEmptyFields(modifiedData);
        setPreTourDetails(modifiedData);
      } else {
        console.error(
          "Failed to fetch pre-tour details:",
          responseData.message
        );
      }
    } catch (error) {
      console.error("Error fetching pre-tour details:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return null;
    const date = new Date(dateTimeString);
    return `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  };

  const formatFieldName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const handleEmptyFields = (data) => {
    for (const key in data) {
      if (!data[key]) {
        data[key] = "null";
      }
    }
  };

  return (
    <Box m="20px" height="75vh" overflow="auto" paddingRight="20px">
      <Header title={`View Pre-Tour Registration ID: ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(preTourDetails).map(([field, value]) => (
            <React.Fragment key={field}>
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  {formatFieldName(field)}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  :
                </Typography>
              </Grid>
              <Grid item xs={9}>
                {field === "signatureImg" ? (
                  <img
                    src={value}
                    alt="Signature"
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <Typography>
                    {Array.isArray(value) ? value.join(", ") : value}
                  </Typography>
                )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ViewPreTour;
