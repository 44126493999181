import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { getMembershipCardByID, updateMembershipCard } from "../../ApiConfig";
import Header from "../../components/Header";
const EditMembershipCard = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [updatedMembershipCardDetails, setUpdatedMembershipCardDetails] =
    useState({
      memberId: "",
      template: "",
      name: "",
      category: "",
      startDate: "",
      expDate: "",
      type: "",
      size: "",
    });
  const [errors, setErrors] = useState({});

  const GetMembershipCardByID = async (id) => {
    try {
      const data = await getMembershipCardByID(id);
      setUpdatedMembershipCardDetails({
        memberId: data.result.memberId,
        template: data.result.template,
        name: data.result.name,
        category: data.result.category,
        startDate: data.result.startDate
          ? data.result.startDate.split("T")[0]
          : "",
        expDate: data.result.expDate ? data.result.expDate.split("T")[0] : "",
        type:
          data.result.type.charAt(0).toUpperCase() + data.result.type.slice(1),
        size: data.result.size,
      });
    } catch (error) {
      console.error("Error fetching membership card:", error);
    }
  };

  useEffect(() => {
    GetMembershipCardByID(id);
  }, [id]);

  const validateForm = async () => {
    try {
      const checkoutSchema = yup.object().shape({
        memberId: yup.string().required("Member ID is required"),
        template: yup.string().required("Template ID is required"),
        name: yup.string().required("Name is required"),
        category: yup.string().required("Category is required"),
        startDate: yup
          .date()
          .required("Start Date is required")
          .typeError("Invalid start date")
          .max(
            yup.ref("expDate"),
            "Start Date must be before or the same as End Date"
          ),
        expDate: yup
          .date()
          .required("Expire Date is required")
          .typeError("Invalid end date")
          .min(yup.ref("startDate"), "End Date must be later than Start Date"),
        type: yup
          .string()
          .oneOf(["Free", "Paid"], "Invalid type")
          .required("Type is required"),
        size: yup.string().required("Size is required"),
      });

      await checkoutSchema.validate(updatedMembershipCardDetails, {
        abortEarly: false,
      });
      setErrors({});
      return true;
    } catch (error) {
      const fieldErrors = error.inner.reduce((acc, err) => {
        acc[err.path] = err.message;
        return acc;
      }, {});
      setErrors(fieldErrors);
      return false;
    }
  };

  const handleInputChange = (name, value) => {
    setUpdatedMembershipCardDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleUpdateTemplate = async () => {
    const isValid = await validateForm();
    if (!isValid) return;

    setIsLoading(true);

    const body = {
      memberId: updatedMembershipCardDetails.memberId,
      template: updatedMembershipCardDetails.template,
      name: updatedMembershipCardDetails.name,
      category: updatedMembershipCardDetails.category,
      startDate: updatedMembershipCardDetails.startDate,
      expDate: updatedMembershipCardDetails.expDate,
      type: updatedMembershipCardDetails.type.toLowerCase(),
      size: updatedMembershipCardDetails.size,
    };

    try {
      const response = await updateMembershipCard(id, body);
      if (response.status) {
        setAlertSeverity("success");
        setAlertMessage("Membership Card updated successfully");
        setTimeout(() => {
          navigate("/membership");
        }, 2000);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(
        "Failed to update membership card. Please check the required fields."
      );
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`UPDATE MEMBERSHIP CARD ID: ${id}`} subtitle="" />
      <Box ml={"10px"}>
        <Grid container spacing={2}>
          {/* Form fields */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Member ID*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedMembershipCardDetails.memberId}
              onChange={(e) => handleInputChange("memberId", e.target.value)}
              error={!!errors.memberId}
              helperText={errors.memberId}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Template ID*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedMembershipCardDetails.template}
              onChange={(e) => handleInputChange("template", e.target.value)}
              error={!!errors.template}
              helperText={errors.template}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Name*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedMembershipCardDetails.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Category*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedMembershipCardDetails.category}
              onChange={(e) => handleInputChange("category", e.target.value)}
              error={!!errors.category}
              helperText={errors.category}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Start Date*
            </Typography>
            <TextField
              type="date"
              fullWidth
              variant="filled"
              value={updatedMembershipCardDetails.startDate}
              onChange={(e) => handleInputChange("startDate", e.target.value)}
              error={!!errors.startDate}
              helperText={errors.startDate}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Expire Date*
            </Typography>
            <TextField
              type="date"
              fullWidth
              variant="filled"
              value={updatedMembershipCardDetails.expDate}
              onChange={(e) => handleInputChange("expDate", e.target.value)}
              error={!!errors.expDate}
              helperText={errors.expDate}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Type*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={updatedMembershipCardDetails.type}
                onChange={(e) => handleInputChange("type", e.target.value)}
                error={!!errors.type}
                label="Type*"
              >
                <MenuItem value="Free">Free</MenuItem>
                <MenuItem value="Paid">Paid</MenuItem>
              </Select>
              {errors.type && (
                <FormHelperText error>{errors.type}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Size*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedMembershipCardDetails.size}
              onChange={(e) => handleInputChange("size", e.target.value)}
              error={!!errors.size}
              helperText={errors.size}
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateTemplate}
                disabled={isLoading}
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
              >
                {isLoading ? "Updating..." : "Update"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditMembershipCard;
