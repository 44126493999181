import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";

function FormsUserView() {
  const { id } = useParams();
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await fetch(
          `https://jcgnapi.hasthiya.org/afyaborabook/getafyabookByID/${id}`
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch form data for ID ${id}`);
        }

        const responseData = await response.json();
        if (responseData.result) {
          setFormData(responseData.result); // Use responseData.result directly
        } else {
          console.error("Failed to fetch form data:", responseData);
        }
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    fetchFormData();
  }, [id]);

  const formatFieldLabel = (fieldName) => {
    return fieldName
      .split(/(?=[A-Z])/)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
  };

  const renderValue = (value) => {
    if (value === null || value === undefined) {
      return "N/A";
    } else if (typeof value === "object" && value !== null) {
      return JSON.stringify(value, null, 2);
    } else if (typeof value === "string" && value.includes("T")) {
      return new Date(value).toLocaleString();
    } else {
      return value.toString();
    }
  };

  return (
    <Box m="20px" height="72vh" overflow="auto" paddingRight="20px">
      <Header
        title={`View Afya Bora Tool-Booking Report ID ${id}`}
        subtitle=""
      />
      <Box ml={4}>
        <Grid container spacing={2}>
          {formData &&
            Object.entries(formData).map(([field, value]) => (
              <Grid item xs={12} key={field}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="h5" component="span" fontWeight="bold">
                      {formatFieldLabel(field)}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h6" component="span">
                      : {renderValue(value)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default FormsUserView;
