import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Header from "../../../components/Header";

const EditCareer = () => {
  const { id } = useParams();
  const [pastData, setPastData] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    // Fetch data from the API endpoint
    axios
      .get(
        `https://jcgnapi.hasthiya.org/api/career/getCareerResponseById/${id}`
      )
      .then((response) => {
        if (response.data.status && response.data.result) {
          setPastData(response.data.result);
        } else {
          console.error("Failed to fetch past data");
        }
      })
      .catch((error) => {
        console.error("Error fetching past data:", error);
      });
  }, [id]);

  // Define yup validation schema
  const validationSchema = yup.object().shape({
    description: yup
      .string()
      .required("Signature Approved By Name is required"),
    dateA: yup.date().required("Date Approved is required"),
    dateR: yup.date().required("Date Last Reviewed is required"),
    applicationA: yup
      .string()
      .required("Application Approved By Name is required"),
    applicationR: yup
      .string()
      .required("Application Rejected By Name is required"),
    status: yup.string().required("Status is required"),
  });

  const formik = useFormik({
    initialValues: {
      description: pastData?.signatureApprovedBy || "",
      dateA: pastData?.dateApproved || "",
      dateR: pastData?.dateLastReviewed || "",
      applicationA: pastData?.applycationAprovedBy || "",
      applicationR: pastData?.applycationRejectedBy || "",
      status: "keep", // Default value for Status field
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const updatedData = {
          signatureApprovedBy: values.description,
          dateApproved: values.dateA,
          dateLastReviewed: values.dateR,
          applycationAprovedBy: values.applicationA,
          applycationRejectedBy: values.applicationR,
          applicationKeepOrNot:
            values.status === "keep" ? "keep" : "Do Not Keep",
          status: values.status === "keep" ? "keep" : "Do Not Keep",
        };

        const response = await axios.put(
          `https://jcgnapi.hasthiya.org/api/career/updateCareerResponseById/${id}`,
          updatedData
        );

        console.log("API response:", response.data);
        setAlertSeverity("success");
        setAlertMessage("Data updated successfully!");
        setOpenSnackbar(true);

        setTimeout(() => {
          navigate("/careers/careerrepliers");
        }, 3000);
      } catch (error) {
        console.error("Error updating data:", error);
        setAlertSeverity("error");
        setAlertMessage("Failed to update data");
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`Application Id ${id} Authorization`} subtitle="" />
      {pastData ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            border: "1px solid #ccc",
            borderRadius: "8px",
            maxWidth: 800,
            marginBottom: "40px",
            p: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Previous Recorded Admin Report :
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {pastData.signatureApprovedBy ? (
                <>
                  <Typography>
                    <strong>Signature Approved By</strong>
                  </Typography>
                  <Typography>
                    <strong>Date Approved</strong>
                  </Typography>
                  <Typography>
                    <strong>Date Last Reviewed</strong>
                  </Typography>
                  <Typography>
                    <strong>Application Approved By</strong>
                  </Typography>
                  <Typography>
                    <strong>Application Rejected By</strong>
                  </Typography>
                  <Typography>
                    <strong>Created On (MM/DD/YYYY)</strong>
                  </Typography>
                  <Typography>
                    <strong>Updated On (MM/DD/YYYY)</strong>
                  </Typography>
                  <Typography>
                    <strong>Status</strong>
                  </Typography>
                </>
              ) : (
                <Typography marginTop={0.3}>
                  No Past Record is Available
                </Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              {pastData.signatureApprovedBy ? (
                <>
                  <Typography>:{pastData.signatureApprovedBy}</Typography>
                  <Typography>
                    :{new Date(pastData.dateApproved).toLocaleDateString()}
                  </Typography>
                  <Typography>
                    :{new Date(pastData.dateLastReviewed).toLocaleDateString()}
                  </Typography>
                  <Typography>:{pastData.applycationAprovedBy}</Typography>
                  <Typography>:{pastData.applycationRejectedBy}</Typography>
                  <Typography>
                    {" "}
                    :{new Date(pastData.createdAt).toLocaleString()}
                  </Typography>
                  <Typography>
                    :{new Date(pastData.updatedAt).toLocaleString()}
                  </Typography>
                  <Typography>:{pastData.status}</Typography>
                </>
              ) : (
                <Typography></Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Typography>Loading...</Typography>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Box mt={3}>
          <Typography>
            <strong>Signature Approved By*</strong>
          </Typography>
          <TextField
            fullWidth
            variant="filled"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            name="description"
            multiline
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
        </Box>
        <Box mt={3}>
          <Typography>
            <strong>Date Approved*</strong>
          </Typography>
          <TextField
            fullWidth
            variant="filled"
            type="date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dateA}
            name="dateA"
            error={formik.touched.dateA && Boolean(formik.errors.dateA)}
            helperText={formik.touched.dateA && formik.errors.dateA}
          />
        </Box>
        <Box mt={3}>
          <Typography>
            <strong>Date Last Reviewed*</strong>
          </Typography>
          <TextField
            fullWidth
            variant="filled"
            type="date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dateR}
            name="dateR"
            error={formik.touched.dateR && Boolean(formik.errors.dateR)}
            helperText={formik.touched.dateR && formik.errors.dateR}
          />
        </Box>
        <Box mt={3}>
          <Typography>
            <strong>Application Approved By*</strong>
          </Typography>
          <TextField
            fullWidth
            variant="filled"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.applicationA}
            name="applicationA"
            multiline
            error={
              formik.touched.applicationA && Boolean(formik.errors.applicationA)
            }
            helperText={
              formik.touched.applicationA && formik.errors.applicationA
            }
          />
        </Box>
        <Box mt={3}>
          <Typography>
            <strong>Application Rejected By*</strong>
          </Typography>
          <TextField
            fullWidth
            variant="filled"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.applicationR}
            name="applicationR"
            multiline
            error={
              formik.touched.applicationR && Boolean(formik.errors.applicationR)
            }
            helperText={
              formik.touched.applicationR && formik.errors.applicationR
            }
          />
        </Box>
        <Box mt={3}>
          <Typography>
            <strong>Status*</strong>
          </Typography>
          <Select
            fullWidth
            variant="filled"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.status}
            name="status"
            error={formik.touched.status && Boolean(formik.errors.status)}
            helperText={formik.touched.status && formik.errors.status}
          >
            <MenuItem value="keep">Keep</MenuItem>
            <MenuItem value="doNotKeep">Do Not Keep</MenuItem>
          </Select>
        </Box>

        <Box display="flex" justifyContent="end" mt="20px">
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#6870fa",
              color: "white",
              marginRight: 2,
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "#3e4396",
              },
            }}
            disabled={loading}
          >
            {loading ? "Updating..." : "Update"}
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          sx={{ color: "#fff" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}: {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditCareer;
