import {
  Box,
  Button,
  InputLabel,
  TextField,
  Snackbar,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import Header from "../../components/Header";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { createQuestion } from "../../ApiConfig";

const CreateNewQuestion = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    const formData = new FormData();

    formData.append("question", values.question);
    formData.append("file", values.file);
    formData.append("description", values.description);
    formData.append("voteOption1", values.answer1);
    formData.append("vote1", 0);
    formData.append("voteOption2", values.answer2);
    formData.append("vote2", 0);
    formData.append("voteOption3", values.answer3);
    formData.append("vote3", 0);
    formData.append("voteOption4", values.answer4);
    formData.append("vote4", 0);

    setIsLoading(true);

    try {
      const response = await createQuestion(formData);
      if (response.response_code === 201) {
        setAlertSeverity("success");
        setAlertMessage("Question create successfully");
        setTimeout(() => {
          navigate("/vote-management");
        }, 2500);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new question : ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title="CREATE NEW QUESTION" subtitle="" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} height="41vh">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Question*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.question}
                  name="question"
                  error={!!touched.question && !!errors.question}
                  helperText={touched.question && errors.question}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Description(Limited to 1000 characters)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  name="description"
                  multiline
                  rows={5}
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                />
              </Box>
              <Box>
                <InputLabel htmlFor="eventType">File Upload</InputLabel>
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  onBlur={handleBlur}
                  onChange={(event) =>
                    handleChange({
                      target: {
                        name: "file",
                        value: event.currentTarget.files[0],
                      },
                    })
                  }
                  name="file"
                  error={!!touched.file && !!errors.file}
                  helperText={touched.file && errors.file}
                />
              </Box>
            </Box>
            <Box
              display="grid"
              gap="30px"
              marginTop="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Answer 1*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.answer1}
                  name="answer1"
                  error={!!touched.answer1 && !!errors.answer1}
                  helperText={touched.answer1 && errors.answer1}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Answer 2*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.answer2}
                  name="answer2"
                  error={!!touched.answer2 && !!errors.answer2}
                  helperText={touched.answer2 && errors.answer2}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Answer 3*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.answer3}
                  name="answer3"
                  error={!!touched.answer3 && !!errors.answer3}
                  helperText={touched.answer3 && errors.answer3}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Answer 4*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.answer4}
                  name="answer4"
                  error={!!touched.answer4 && !!errors.answer4}
                  helperText={touched.answer4 && errors.answer4}
                />
              </Box>
            </Box>
            {/* Submit Button */}
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396", // Change to green color on hover
                  },
                }}
              >
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  question: yup.string().required("Question is required"),
  answer1: yup.string().required("Answer 1 is required"),
  answer2: yup.string().required("Answer 2 is required"),
  answer3: yup.string().required("Answer 3 is required"),
  answer4: yup.string().required("Answer 4 is required"),
});

const initialValues = {
  question: "",
  description: "",
  answer1: "",
  answer2: "",
  answer3: "",
  answer4: "",
  file: null,
};

export default CreateNewQuestion;
