import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import { formatDateToReadable, getTemplateByID } from "./../../ApiConfig.jsx";

function ViewTemplate() {
  const { id } = useParams();
  const [templateDetails, setTemplateDetails] = useState({});

  const GetTemplateById = async (id) => {
    try {
      const data = await getTemplateByID(id);

      if (data.status) {
        setTemplateDetails(data.result);
      }
    } catch (error) {
      console.error("Error fetching template:", error);
    }
  };

  useEffect(() => {
    GetTemplateById(id);
  }, [id]);

  return (
    <div>
      {" "}
      <Box m="20px" height="70vh" overflow="auto">
        <Header title={`View Template ${id}`} subtitle="" />
        <Box ml={"40px"}>
          <Grid container>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Template:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{templateDetails.template}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Create Date:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  {formatDateToReadable(templateDetails.createdAt)}
                </Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Status:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{templateDetails.status}</Typography>
              </Grid>
            </div>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

export default ViewTemplate;
