import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Snackbar,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import MuiAlert from "@mui/material/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { getBlogByID, updateBlog } from "../../ApiConfig";
import JoditEditor from "jodit-react";

const EditBlog = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const config = {
    placeholder: "Description",
    toolbarButtonSize: "small",
    style: {
      color: "#000000",
    },
  };

  const navigate = useNavigate();
  const { id } = useParams();
  const [updatedBlogDetails, setUpdatedBlogDetails] = useState({
    email: "",
    author: "",
    type: "",
    title: "",
    description: "",
    image: null,
    noOfReaders: "",
    status: "",
  });
  const [errors, setErrors] = useState({});
  const [imageName, setImageName] = useState("");

  const GetBlogById = async (id) => {
    try {
      const data = await getBlogByID(id);
      setUpdatedBlogDetails({
        email: data.result.email,
        author: data.result.author,
        type: data.result.type,
        title: data.result.title,
        description: data.result.description,
        image: data.result.imageURL,
        noOfReaders: data.result.noOfReaders,
        status: data.result.status,
      });
      const imageUrl = data.result.imageURL;
      const imageFileName = decodeURIComponent(
        imageUrl.split("/").pop().split("%20").pop().split("?")[0]
      );
      setImageName(imageFileName);
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  useEffect(() => {
    GetBlogById(id);
  }, [id]);

  const handleInputChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });
    setUpdatedBlogDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    if (name === "image" && value) {
      setImageName(value.name);
    }
    validateForm(name, value);
  };

  const handleDescriptionChange = (name, value) => {
    setUpdatedBlogDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    validateForm();
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const errors = {};

    if (!updatedBlogDetails.email) {
      errors.email = "Email is required.";
    } else if (!validateEmail(updatedBlogDetails.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!updatedBlogDetails.author) {
      errors.author = "Author is required.";
    }
    if (!updatedBlogDetails.type) {
      errors.type = "Type is required.";
    }
    if (!updatedBlogDetails.title) {
      errors.title = "Title is required.";
    }
    if (!updatedBlogDetails.description) {
      errors.description = "Description is required.";
    }
    if (!updatedBlogDetails.image) {
      errors.image = "Image is required.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleUpdateBlog = async () => {
    if (validateForm()) {
      setIsLoading(true);

      const body = {
        title: updatedBlogDetails.title,
        "blog-image": updatedBlogDetails.image,
        description: updatedBlogDetails.description,
        author: updatedBlogDetails.author,
        email: updatedBlogDetails.email,
        noOfReaders: updatedBlogDetails.noOfReaders,
        type: updatedBlogDetails.type,
        status: updatedBlogDetails.status,
      };

      try {
        const response = await updateBlog(id, body);
        if (response.status) {
          setAlertSeverity("success");
          setAlertMessage("Blog updated successfully");
          setTimeout(() => {
            navigate("/blogs");
          }, 2000);
        }
      } catch (error) {
        setAlertSeverity("error");
        setAlertMessage(`Failed blog update: ${error.message}`);
      } finally {
        setOpenSnackbar(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`UPDATE BLOG ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Author*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedBlogDetails.author}
              onChange={(e) => handleInputChange("author", e.target.value)}
              error={!!errors.author}
              helperText={errors.author}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Email*
            </Typography>
            <TextField
              type="email"
              fullWidth
              variant="filled"
              value={updatedBlogDetails.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Type*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={updatedBlogDetails.type}
                onChange={(e) => handleInputChange("type", e.target.value)}
                name="type"
                error={!!errors.type}
              >
                <MenuItem value="poem">Poem</MenuItem>
                <MenuItem value="romantic">Romantic</MenuItem>
              </Select>
              {errors.type && (
                <Typography variant="caption" color="error">
                  {errors.type}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Title*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedBlogDetails.title}
              onChange={(e) => handleInputChange("title", e.target.value)}
              error={!!errors.title}
              helperText={errors.title}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1" fontWeight="bold">
              Description*
            </Typography>
            <JoditEditor
              config={config}
              tabIndex={1}
              value={updatedBlogDetails.description}
              onChange={(newContent) =>
                handleDescriptionChange("description", newContent)
              }
            />
            {errors.description && (
              <Typography variant="caption" color="error">
                {errors.description}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={12}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>Image*</div>
              <TextField
                fullWidth
                variant="filled"
                type="file"
                accept="image/*"
                onChange={(e) => handleInputChange("image", e.target.files[0])}
                name="image"
                error={!!errors.image}
                helperText={errors.image}
              />
              {imageName && (
                <Typography variant="subtitle1" mt={1}>
                  {`Current image: ${imageName}`}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={handleUpdateBlog}
            >
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditBlog;
