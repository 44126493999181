import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import { getBlogByID } from "./../../ApiConfig.jsx";

function ViewBlog() {
  const { id } = useParams();
  const [blogDetails, setBlogDetails] = useState({});

  const GetBlogById = async (id) => {
    try {
      const data = await getBlogByID(id);
      if (data.status) {
        setBlogDetails(data.result);
      }
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  useEffect(() => {
    GetBlogById(id);
  }, [id]);

  return (
    <div>
      {" "}
      <Box m="20px" height="70vh" overflow="auto">
        <Header title={`View Blog ${id}`} subtitle="" />
        <Box ml={"40px"}>
          <Grid container>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Author:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{blogDetails.author}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Email:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{blogDetails.email}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Type:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{blogDetails.type?.charAt(0).toUpperCase() + blogDetails.type?.slice(1)}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Number of Readers:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  {blogDetails.noOfReaders ? blogDetails.noOfReaders : 0}
                </Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Title:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{blogDetails.title}</Typography>
              </Grid>
            </div>
          </Grid>

          {blogDetails.imageURL && (
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Image:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <img
                    src={blogDetails.imageURL}
                    alt={`Image of ${blogDetails.title}`}
                    style={{ maxWidth: "100%" }}
                  />
                </Grid>
              </div>
            </Grid>
          )}

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Description:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  dangerouslySetInnerHTML={{ __html: blogDetails.description }}
                />
              </Grid>
            </div>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

export default ViewBlog;
