import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../components/Header";

const ViewTour = () => {
  const { id } = useParams();
  const [preTourDetails, setPreTourDetails] = useState({});
  const [passengers, setPassengers] = useState([]);

  useEffect(() => {
    fetchPreTourDetails();
  }, [id]);

  const fetchPreTourDetails = async () => {
    try {
      const response = await fetch(
        `https://jcgnapi.hasthiya.org/api/tour/getTourRegistrationById/${id}`
      );
      const responseData = await response.json();
      if (responseData.status) {
        const modifiedData = {
          ...responseData.result,
          travelDate: formatDate(responseData.result.travelDate),
          createdAt: formatDateTime(responseData.result.createdAt),
          updatedAt: formatDateTime(responseData.result.updatedAt),
          departureDate: formatDate(responseData.result.departureDate),
          returnDate: formatDate(responseData.result.returnDate),
        };
        delete modifiedData.date;
        handleEmptyFields(modifiedData);
        setPreTourDetails(modifiedData);
        setPassengers(responseData.result.Passengers || []);
      } else {
        console.error(
          "Failed to fetch pre-tour details:",
          responseData.message
        );
      }
    } catch (error) {
      console.error("Error fetching pre-tour details:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  };

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return null;
    const date = new Date(dateTimeString);
    return `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  };

  const formatFieldName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const handleEmptyFields = (data) => {
    for (const key in data) {
      if (!data[key]) {
        data[key] = null;
      }
    }
  };

  return (
    <Box m="20px" height="75vh" overflow="auto" paddingRight="20px">
      <Header title={`View Pre-Tour Registration ID: ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(preTourDetails)
            .filter(
              ([field, value]) =>
                value !== null && value !== "" && field !== "Passengers"
            )
            .map(([field, value]) => (
              <React.Fragment key={field}>
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    {formatFieldName(field)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    :
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  {field === "signatureImg" ? (
                    <img
                      src={value}
                      alt="Signature"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <Typography>
                      {Array.isArray(value) ? value.join(", ") : value}
                    </Typography>
                  )}
                </Grid>
              </React.Fragment>
            ))}
        </Grid>

        {passengers.length > 0 && (
          <Box mt={4}>
            <hr></hr>
            <Typography variant="h3" gutterBottom>
              Total Passengers Amount: {passengers.length}
            </Typography>

            {passengers.map((passenger, index) => (
              <Box key={passenger.passengerId} mb={2}>
                <Typography variant="h4" gutterBottom>
                  Passenger {index + 1}
                </Typography>
                <Grid container spacing={2}>
                  {Object.entries(passenger)
                    .filter(
                      ([field, value]) =>
                        value !== null &&
                        value !== "" &&
                        field !== "createdAt" &&
                        field !== "updatedAt"
                    )
                    .map(([field, value]) => (
                      <React.Fragment key={field}>
                        <Grid item xs={2}>
                          <Typography
                            variant="h6"
                            component="span"
                            fontWeight="bold"
                          >
                            {formatFieldName(field)}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            variant="h6"
                            component="span"
                            fontWeight="bold"
                          >
                            :
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          {field === "passportFile" && value ? (
                            <img
                              src={value}
                              alt="Passport"
                              style={{
                                width: "200px",
                                height: "200px",
                                objectFit: "contain",
                              }}
                            />
                          ) : field === "passportIssueDate" ||
                            field === "passportExpDate" ||
                            field === "departureDate" ||
                            field === "returnDate" ? (
                            <Typography>{formatDate(value)}</Typography>
                          ) : (
                            <Typography>
                              {Array.isArray(value) ? value.join(", ") : value}
                            </Typography>
                          )}
                        </Grid>
                      </React.Fragment>
                    ))}
                </Grid>
                <hr></hr>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ViewTour;
