import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Snackbar,
  IconButton,
  Typography,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import Header from "../../components/Header";
import DeleteIcon from "@mui/icons-material/Delete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import { createVideo, getAllArtists } from "./../../ApiConfig.jsx";

const AddNewVideo = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [artists, setArtists] = useState([]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const getArtists = async () => {
      try {
        const artistData = await getAllArtists();
        if (artistData.status) {
          setArtists(artistData.result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getArtists();
  }, []);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", values.video_track_title);
    formData.append("language", values.language);
    formData.append("type", values.type);
    if (values.type == "Paid") {
      formData.append("amount", values.amount);
    } else {
      formData.append("amount", "0");
    }
    formData.append("status", "Active");
    formData.append("video", values.video);
    formData.append("artist_name", values.artist);

    try {
      const response = await createVideo(formData);
      console.log(response.data);
      if (response.status) {
        setAlertSeverity("success");
        setAlertMessage("Video added successfully");
        setTimeout(() => {
          navigate("/video-management");
        }, 2500);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new video : ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title="Add New Video" subtitle="" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} style={{ height: "41vh" }}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Video Track Title*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.video_track_title}
                  name="video_track_title"
                  error={
                    !!touched.video_track_title && !!errors.video_track_title
                  }
                  helperText={
                    touched.video_track_title && errors.video_track_title
                  }
                />
              </Box>
              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Language*</InputLabel>
                  <Select
                    variant="filled"
                    value={values.language}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="language"
                    error={!!touched.language && !!errors.language}
                    helperText={touched.language && errors.language}>
                    <MenuItem value="English">English</MenuItem>
                    <MenuItem value="French">French</MenuItem>
                  </Select>
                  {touched.language && errors.language && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {errors.language}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Select Type*</InputLabel>
                  <Select
                    variant="filled"
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="type"
                    error={!!touched.type && !!errors.type}
                    helperText={touched.type && errors.type}
                  >
                    <MenuItem value="Free">Free</MenuItem>
                    <MenuItem value="Paid">Paid</MenuItem>
                  </Select>
                  {touched.type && errors.type && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {errors.type}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Artist*</InputLabel>
                  <Select
                    variant="filled"
                    value={values.artist}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="artist"
                    error={!!touched.artist && !!errors.artist}
                    helperText={touched.artist && errors.artist}
                  >
                    {artists.map((artist, index) => {
                      return (
                        <MenuItem key={index} value={artist.artist_name}>
                          {artist.artist_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {touched.artist && errors.artist && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {errors.artist}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>

              {values.type == "Paid" && (
                <Box>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Amount*"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.amount}
                    name="amount"
                    error={!!touched.amount && !!errors.amount}
                    helperText={touched.amount && errors.amount}
                  />
                </Box>
              )}

              <Box>
                {" "}
                <InputLabel htmlFor="eventType">Upload a Video*</InputLabel>
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  accept="video/*"
                  onBlur={handleBlur}
                  onChange={(event) =>
                    handleChange({
                      target: {
                        name: "video",
                        value: event.currentTarget.files[0],
                      },
                    })
                  }
                  name="video"
                  error={!!touched.video && !!errors.video}
                  helperText={touched.video && errors.video}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}>
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  video_track_title: yup.string().required("Video track title is required"),
  language: yup.string().required("Language is required"),
  type: yup.string().required("type is required"),
  amount: yup.string().when("type", {
    is: (val) => val === "Paid", // Condition: validate only when type is "paid"
    then: yup
      .string()
      .required("Amount is required")
      .matches(/^\d+$/, "Amount must contain only numbers"),
    otherwise: yup.string(), // No validation if type is not "paid"
  }),
  artist: yup.string().required("Artist is required"),
  video: yup
    .mixed()
    .required("Video is required")
    .test("fileType", "Please enter a valid video type", (value) => {
      return !value || (value && value.type.startsWith("video/"));
    }),
});

const initialValues = {
  video_track_title: "",
  language: "",
  type: "",
  amount: "",
  artist: "",
  video: null,
};

export default AddNewVideo;
