import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import { getCoveredCareServicesById } from "./../../ApiConfig.jsx";

function ViewCoveredCareServices() {
  const { id } = useParams();
  const [itemDetails, setItemDetails] = useState({});
  const fetchData = async (id) => {
    try {
      const data = await getCoveredCareServicesById(id);
      if (data.status) {
        console.log(data.result);
        setItemDetails({
          Service: data.result.service,
          "Type of service":
            data.result.type.charAt(0).toUpperCase() +
            data.result.type.slice(1),
          Reference: data.result.reference,
          Covered: data.result.isCovered ? "Yes" : "No",
          "Posted date": data.posted_date,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData(id);
  }, [id]);

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`View Care Service ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(itemDetails).map(([field, value]) => (
            <React.Fragment key={field}>
              <Grid item xs={3}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  {field}:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>{value}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewCoveredCareServices;
