import { Box, Button, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../components/Header";

function ViewReplyCareerUser() {
  const { id } = useParams();
  const [careerDetails, setCareerDetails] = useState(null);

  useEffect(() => {
    const fetchCareerDetails = async () => {
      try {
        const response = await axios.get(
          `https://jcgnapi.hasthiya.org/api/career/getUserCareer/${id}`
        );
        const responseData = response.data;

        if (responseData && responseData.status && responseData.result) {
          const { careerresponse, Employee, Reference } = responseData.result;

          const formattedDetails = {
            "I. Applicant Details": {
              "Full Name": careerresponse.fullName,
              "Date of Birth (MM/DD/YYYY)": formatDate(
                careerresponse.dateofbirth
              ),
              Email: careerresponse.email,
              Phone: careerresponse.phone,
              "Social Security Number": careerresponse.socialSN,

              "State ID Card Number": careerresponse.stateId,

              "Applying Job Career ID": careerresponse.careerId,
              "Job Career Title": careerresponse.jobTitle,
              "Desired Position": careerresponse.appliedPosition,
              "Desired Job Location": careerresponse.selectJobLocation,
              "Desired Pay Amount": `${careerresponse.desiredPayAmount} `,
              "Desired Pay Type": ` ${careerresponse.desiredPayType}`,
              "Available Date (MM/DD/YYYY)": formatDate(
                careerresponse.availableDate
              ),
              "Desired Employment Type": careerresponse.empDesiredtype,
            },
            "II. Employment Eligibility": {
              "Age 18 Years Old or Above": careerresponse.ageAbORbelow,
              "Legal Eligibility to Work in U.S": careerresponse.legalEligible,
              "Have You Ever Work For This Employer?":
                careerresponse.empExperience,
              "Employment Experience Details": careerresponse.empExperienceDate,
              "Has Been Part of a Felony": careerresponse.felony,
              "Felony Explain": careerresponse.felonyExplain,
            },
            "III. Education": {
              "High School": careerresponse.highschool,
              Graduated: careerresponse.graduate,
              Diploma: careerresponse.diploma,
              "Time Period": `${careerresponse.from} - ${careerresponse.to}`,
              State: careerresponse.state,
              City: careerresponse.city,
              Country: careerresponse.country,
            },
            "IV. Other Details": {
              Resume: careerresponse.resume,
              Signature: careerresponse.signature,
              "Print Name": careerresponse.printName,
              "Print Date": careerresponse.printDate,
              Status: careerresponse.status,

              "Submitted At (MM/DD/YYYY)": formatDate(careerresponse.updatedAt),
            },
            "V. Previous Employment": {
              "First Employer": Employee.employer1,
              "First Employer Email": Employee.employerEmail1,
              "First Employer Phone Number": Employee.employerEmail1,
              "First Employer Location": `${Employee.employeerCountry1} - ${Employee.employeerState1} - ${Employee.employeerCity1} - ${Employee.employeerStreet1}`,
              "First Employer Zip Code": Employee.employerEmail1,

              "Second Employer": Employee.employer2,
              "Second Employer Email": Employee.employerEmail2,
              "Second Employer Phone Number": Employee.employerEmail2,
              "Second Employer Location": `${Employee.employeerCountry2} - ${Employee.employeerState2} - ${Employee.employeerCity2} - ${Employee.employeerStreet2}`,
              "Second Employer Zip Code": Employee.employerEmail2,

              "Third Employer": Employee.employer3,
              "Third Employer Email": Employee.employerEmail3,
              "Second Employer Phone Number": Employee.employerEmail3,
              "Third Employer Location": `${Employee.employeerCountry3} - ${Employee.employeerState3} - ${Employee.employeerCity3} - ${Employee.employeerStreet3}`,
              "Third Employer Zip Code": Employee.employerEmail3,
            },

            "VI. References": {
              "First Reference Full Name": Reference.refernceFullName1,
              "FirstReference Relationship": Reference.refernceFullName1,
              "First Reference Company": Reference.referenceCompany1,
              "First Reference Title": Reference.referencetitle1,
              "First Reference Email": Reference.referenceEmail1,
              "First Reference Phone": Reference.referencePhone1,

              "Second Reference Full Name": Reference.refernceFullName2,
              "Second Reference Relationship": Reference.refernceFullName2,
              "Second Reference Company": Reference.referenceCompany2,
              "Second Reference Title": Reference.referencetitle2,
              "Second Reference Email": Reference.referenceEmail2,
              "Second Reference Phone": Reference.referencePhone2,

              "Third Reference Full Name": Reference.refernceFullName3,
              "Third Reference Relationship": Reference.refernceFullName3,
              "Third Reference Company": Reference.referenceCompany3,
              "Third Reference Title": Reference.referencetitle3,
              "Third Reference Email": Reference.referenceEmail3,
              "Third Reference Phone": Reference.referencePhone3,
            },

            "VII. Military Service": {
              "Are you veteran?": careerresponse.youVertan,
              Branch: careerresponse.branch,
              "Rank at discharge": careerresponse.rankDisCharge,
              "Rank Discharge from to": careerresponse.rankDisChargeToFrom,
              "Type of Discharge": careerresponse.typeDisCharge,
              "If not honourable, please explain":
                careerresponse.honarbleExplain,
            },

            "VIII. Willing to do background check?": {
              "Answer?": careerresponse.backgrounCheck,
            },

            // "IV. Employee": {
            //   Employee: employees.employer1,
            // },
          };
          setCareerDetails(formattedDetails);
        } else {
          console.error("Error fetching career details");
          setCareerDetails(null);
        }
      } catch (error) {
        console.error("Error fetching career details:", error);
        setCareerDetails(null);
      }
    };

    fetchCareerDetails();
  }, [id]);

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const handleDownloadResume = (resumeUrl) => {
    window.open(resumeUrl, "_blank");
  };

  if (!careerDetails) {
    return <Typography variant="h5">Loading...</Typography>;
  }

  return (
    <Box m="20px" height="80vh" overflow="auto" paddingRight="20px">
      <Header
        title={`View Career Response ${id}`}
        subtitle={`Status: ${careerDetails["IV. Other Details"].Status}`}
      />
      <Box ml="40px">
        <Grid container spacing={2}>
          {Object.entries(careerDetails).map(([section, details]) => (
            <React.Fragment key={section}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  fontWeight="bold"
                  mt={1}
                >
                  {section}
                </Typography>
              </Grid>
              {Object.entries(details).map(([field, value]) => (
                <React.Fragment key={field}>
                  <Grid item xs={4}>
                    <Typography
                      variant="h6"
                      component="span"
                      fontWeight={550}
                      mt={3}
                    >
                      {field}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      variant="h6"
                      component="span"
                      fontWeight="bold"
                      mt={3}
                    >
                      :
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {field === "Resume" && (
                      <Button
                        onClick={() => handleDownloadResume(value)}
                        sx={{
                          backgroundColor: "#6870fa",
                          color: "white",
                          fontSize: "10px",
                          "&:hover": {
                            backgroundColor: "#3e4396",
                          },
                        }}
                      >
                        Download Resume Here
                      </Button>
                    )}
                    {field === "Signature" && (
                      <img
                        src={value}
                        alt="Signature"
                        style={{ maxWidth: "30%" }}
                      />
                    )}
                    {field !== "Resume" && field !== "Signature" && (
                      <Typography variant="h6" component="span" mt={3} mr={2}>
                        {value}
                      </Typography>
                    )}
                  </Grid>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewReplyCareerUser;
