import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Snackbar,
  FormHelperText,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import Header from "../../components/Header";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { createBlog } from "../../ApiConfig";
import JoditEditor from "jodit-react";

const CreateNewBlog = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const config = {
    placeholder: "Description",
    toolbarButtonSize: "small",
    style: {
      color: "#000000",
    },
  };

  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    const formData = new FormData();

    formData.append("title", values.title);
    formData.append("blog-image", values.image);
    formData.append("description", values.description);
    formData.append("author", values.author);
    formData.append("email", values.email);
    formData.append("noOfReaders", 0);
    formData.append("type", values.type);
    formData.append("status", "Active");

    setIsLoading(true);

    try {
      const response = await createBlog(formData);
      if (response.status) {
        setAlertSeverity("success");
        setAlertMessage("Blog added successfully");
        setTimeout(() => {
          navigate("/blogs");
        }, 2500);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new blog : ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title="CREATE NEW BLOG" subtitle="" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} height="41vh">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Author*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.author}
                  name="author"
                  error={!!touched.author && !!errors.author}
                  helperText={touched.author && errors.author}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="email"
                  label="Email Address*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
              </Box>
              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Select Type*</InputLabel>

                  <Select
                    variant="filled"
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="type"
                    error={!!touched.type && !!errors.type}
                    helperText={touched.type && errors.type}
                  >
                    <MenuItem value="Poem">Poem</MenuItem>
                    <MenuItem value="Romantic">Romantic</MenuItem>
                  </Select>
                  {touched.type && errors.type && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {errors.type}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Title*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  name="title"
                  error={!!touched.title && !!errors.title}
                  helperText={touched.title && errors.title}
                />
              </Box>
            </Box>
            <Box
              display="grid"
              gap="30px"
              marginTop="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <InputLabel>Description*</InputLabel>
                <JoditEditor
                  config={config}
                  tabIndex={1}
                  value={values.description}
                  onBlur={handleBlur}
                  onChange={(newContent) => setFieldValue("description", newContent)}
                />
                {touched.description && errors.description && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {errors.description}
                    </FormHelperText>
                  )}
              </Box>
              <Box>
                <InputLabel htmlFor="eventType">Upload an Image*</InputLabel>
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  onBlur={handleBlur}
                  onChange={(event) =>
                    handleChange({
                      target: {
                        name: "image",
                        value: event.currentTarget.files[0],
                      },
                    })
                  }
                  name="image"
                  error={!!touched.image && !!errors.image}
                  helperText={touched.image && errors.image}
                />
              </Box>
            </Box>
            {/* Submit Button */}
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396", // Change to green color on hover
                  },
                }}
              >
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  author: yup.string().required("Author is required"),
  type: yup.string().required("Type is required"),
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  image: yup
    .mixed()
    .required("Image is required")
    .test("fileType", "Please enter a valid image type", (value) => {
      return !value || (value && value.type.startsWith("image/"));
    }),
});

const initialValues = {
  email: "",
  author: "",
  type: "",
  title: "",
  description: "",
  image: null,
};

export default CreateNewBlog;
