import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Snackbar,
  FormHelperText,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import Header from "../../components/Header";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { createArtist } from "../../ApiConfig";

const CreateNewArtist = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    const formData = new FormData();

    formData.append("artist_name", values.artistName);
    formData.append("description", values.description);
    formData.append("language", values.language);
    formData.append("type", values.type);
    formData.append("status", "Inactive");
    formData.append("image", values.image);

    setIsLoading(true);
    try {
      const response = await createArtist(formData);
      if (response.status) {
        setAlertSeverity("success");
        setAlertMessage("Artist added successfully");
        setTimeout(() => {
          navigate("/artist-management");
        }, 2500);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new artist : ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title="CREATE NEW ARTIST" subtitle="" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} height="41vh">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Artist Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.artistName}
                  name="artistName"
                  error={!!touched.artistName && !!errors.artistName}
                  helperText={touched.artistName && errors.artistName}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Description*(Limited to 1000 characters)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  name="description"
                  multiline
                  rows={5}
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                />
              </Box>
            </Box>
            <Box
              display="grid"
              gap="30px"
              marginTop="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Language*</InputLabel>
                  <Select
                    variant="filled"
                    value={values.language}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="language"
                    error={!!touched.language && !!errors.language}
                    helperText={touched.language && errors.language}
                  >
                    <MenuItem value="English">English</MenuItem>
                    <MenuItem value="French">French</MenuItem>
                  </Select>
                  {touched.language && errors.language && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {errors.language}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Select Type*</InputLabel>

                  <Select
                    variant="filled"
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="type"
                    error={!!touched.type && !!errors.type}
                    helperText={touched.type && errors.type}
                  >
                    <MenuItem value="Book">Book</MenuItem>
                    <MenuItem value="Article">Article</MenuItem>
                  </Select>
                  {touched.type && errors.type && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {errors.type}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box>
                <InputLabel htmlFor="eventType">Upload an Image*</InputLabel>
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  onBlur={handleBlur}
                  onChange={(event) =>
                    handleChange({
                      target: {
                        name: "image",
                        value: event.currentTarget.files[0],
                      },
                    })
                  }
                  name="image"
                  error={!!touched.image && !!errors.image}
                  helperText={touched.image && errors.image}
                />
              </Box>
            </Box>
            {/* Submit Button */}
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396", // Change to green color on hover
                  },
                }}
              >
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  artistName: yup.string().required("Artist name is required"),
  description: yup.string().required("Description is required"),
  type: yup.string().required("Type is required"),
  language: yup.string().required("Language is required"),
  image: yup
    .mixed()
    .required("Image is required")
    .test("fileType", "Please enter a valid image type", (value) => {
      return !value || (value && value.type.startsWith("image/"));
    }),
});

const initialValues = {
  artistName: "",
  description: "",
  type: "",
  language: "",
  image: null,
};

export default CreateNewArtist;
