import {
  Box,
  Button,
  MenuItem,
  Alert as MuiAlert,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/Header";

const CreateNewNews = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [initialValues, setInitialValues] = useState({
    docname: "",
    issuedby: "",
    claimAmount: "",
    submitDate: "",
    recivedDate: "",
    approvedDate: "",
    amount: "",
    claimStatus: "",
  });

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://jcgnapi.hasthiya.org/bural/getAccpetBuralById/${id}`
        );
        const responseData = await response.json();
        if (responseData.status) {
          setInitialValues({
            ...responseData.result,
            submitDate: responseData.result.submitDate.split("T")[0] || "", // Ensure empty string for undefined values
            recivedDate: responseData.result.recivedDate.split("T")[0] || "",
            approvedDate: responseData.result.approvedDate.split("T")[0] || "",
          });
        } else {
          setAlertSeverity("error");
          setAlertMessage("Failed to fetch data");
          setOpenSnackbar(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setAlertSeverity("error");
        setAlertMessage("Failed to fetch data");
        setOpenSnackbar(true);
      }
    };

    fetchData();
  }, [id]);

  const handleFormSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://jcgnapi.hasthiya.org/bural/updateAccpetBural/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );
      const responseData = await response.json();
      if (responseData.status) {
        setAlertSeverity("success");
        setAlertMessage("Burial details updated successfully");
        setTimeout(() => {
          navigate("/burialexpensesclaim");
        }, 2000);
        resetForm();
      } else {
        setAlertSeverity("error");
        setAlertMessage(responseData.message);
      }
    } catch (error) {
      console.error("Error updating burial details:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to update burial details");
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  const handleDescriptionChange = (event, setFieldValue) => {
    const value = event.target.value;
    const words = value.split(/\s+/).filter((word) => word.length > 0);
    if (words.length <= 50) {
      setFieldValue("docname", value);
    }
  };

  return (
    <Box
      m="20px"
      height="70vh"
      width="90%"
      overflow="auto"
      paddingRight="20px"
      position="relative"
    >
      <Header title={`Edit Burial Details ID: ${id}`} />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          sx={{ color: "#fff" }}
        >
          <AlertTitle>
            {alertSeverity === "success" ? "Success" : "Error"}
          </AlertTitle>
          {alertMessage}
        </MuiAlert>
      </Snackbar>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        enableReinitialize={true} // Add this to reinitialize form values when initialValues change
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} style={{ height: "41vh" }}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Typography fontWeight="bold" fontSize="16px">
                Document Name
              </Typography>
              <Box mt={-2}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  onBlur={handleBlur}
                  onChange={(e) => handleDescriptionChange(e, setFieldValue)}
                  value={values.docname}
                  name="docname"
                  multiline
                  rows={2}
                  error={!!touched.docname && !!errors.docname}
                  helperText={touched.docname && errors.docname}
                />
              </Box>

              <Typography fontWeight="bold" fontSize="16px">
                Issued By*
              </Typography>
              <Box mt={-2}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.issuedby}
                  name="issuedby"
                  error={!!touched.issuedby && !!errors.issuedby}
                  helperText={touched.issuedby && errors.issuedby}
                />
              </Box>

              <Typography fontWeight="bold" fontSize="16px">
                Claim Amount*
              </Typography>
              <Box mt={-2}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.claimAmount}
                  name="claimAmount"
                  error={!!touched.claimAmount && !!errors.claimAmount}
                  helperText={touched.claimAmount && errors.claimAmount}
                />
              </Box>

              <Typography fontWeight="bold" fontSize="16px">
                Submit Date*
              </Typography>
              <Box mt={-2}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.submitDate}
                  name="submitDate"
                  error={!!touched.submitDate && !!errors.submitDate}
                  helperText={touched.submitDate && errors.submitDate}
                />
              </Box>

              <Typography fontWeight="bold" fontSize="16px">
                Received Date*
              </Typography>
              <Box mt={-2}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.recivedDate}
                  name="recivedDate"
                  error={!!touched.recivedDate && !!errors.recivedDate}
                  helperText={touched.recivedDate && errors.recivedDate}
                />
              </Box>

              <Typography fontWeight="bold" fontSize="16px">
                Approved Date*
              </Typography>
              <Box mt={-2}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.approvedDate}
                  name="approvedDate"
                  error={!!touched.approvedDate && !!errors.approvedDate}
                  helperText={touched.approvedDate && errors.approvedDate}
                />
              </Box>

              <Typography fontWeight="bold" fontSize="16px">
                Amount*
              </Typography>
              <Box mt={-2}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amount}
                  name="amount"
                  error={!!touched.amount && !!errors.amount}
                  helperText={touched.amount && errors.amount}
                />
              </Box>

              <Typography fontWeight="bold" fontSize="16px">
                Claim Status*
              </Typography>
              <Box mt={-2}>
                <Select
                  fullWidth
                  variant="filled"
                  value={values.claimStatus}
                  onChange={handleChange}
                  name="claimStatus"
                  onBlur={handleBlur}
                  error={!!touched.claimStatus && !!errors.claimStatus}
                >
                  <MenuItem value="Approved">Approved</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                </Select>
                {touched.claimStatus && errors.claimStatus && (
                  <Typography color="error">{errors.claimStatus}</Typography>
                )}
              </Box>

              <Box display="flex" justifyContent="end" mt={2}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save Changes"}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  docname: yup.string().required("Document Name is required"),
  issuedby: yup.string().required("Issued By is required"),
  claimAmount: yup.number().required("Claim Amount is required"),
  submitDate: yup.date().required("Submit Date is required"),
  recivedDate: yup.date().required("Received Date is required"),
  approvedDate: yup.date().required("Approved Date is required"),
  amount: yup.number().required("Amount is required"),
  claimStatus: yup.string().required("Claim Status is required"),
});

export default CreateNewNews;
