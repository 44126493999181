import { Box, Button, TextField, Snackbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import Header from "../../components/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { createWorshipCenter } from "./../../ApiConfig.jsx";
import { create } from "yup/lib/Reference.js";

const CreateNewWorship = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    churchName: "",
    address: "",
    email: "",
    tel: "",
    noOfUsers: "",
  };

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const checkoutSchema = yup.object().shape({
    churchName: yup.string().required("Church name is required"),
    address: yup.string().required("Church address is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    tel: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .max(11, "Phone number must be less than 12 digits")
      .required("Phone No. is required"),
    noOfUsers: yup
      .string()
      .required("No. of users required")
      .matches(/^\d+$/, "No. of users must contain only numbers"),
  });

  const handleFormSubmit = async (values) => {
    console.log(
      values,
      values.churchName,
      values.address,
      values.email,
      values.tel
    );
    setIsLoading(true);
    try {
      const body = {
        church: values.churchName,
        address: values.address,
        email: values.email,
        tel: values.tel,
        noOfUsers: values.noOfUsers,
        status: "active",
      };
      const response = await createWorshipCenter(body);
      if (response.status) {
        setAlertSeverity("success");
        setAlertMessage("Worship center added successfully");
        setTimeout(() => {
          navigate("/worship-centers");
        }, 2500);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new worship center : ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title="Add Worship Center" subtitle="" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} height="41vh">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Church Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.churchName}
                  name="churchName"
                  error={!!touched.churchName && !!errors.churchName}
                  helperText={touched.churchName && errors.churchName}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Church Address*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  name="address"
                  error={!!touched.address && !!errors.address}
                  helperText={touched.address && errors.address}
                />{" "}
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="email"
                  label="Email Address*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Phone No.*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.tel}
                  name="tel"
                  error={!!touched.tel && !!errors.tel}
                  helperText={touched.tel && errors.tel}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="No of users*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.noOfUsers}
                  name="noOfUsers"
                  error={!!touched.noOfUsers && !!errors.noOfUsers}
                  helperText={touched.noOfUsers && errors.noOfUsers}
                />
              </Box>
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  marginRight: 2,
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}>
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
          >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default CreateNewWorship;
