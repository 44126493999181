import { Avatar, Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";

function ViewAyfaMember() {
  const { id } = useParams();
  const [memberDetails, setMemberDetails] = useState(null);

  useEffect(() => {
    const fetchMemberDetails = async () => {
      try {
        const response = await fetch(
          `https://jcgnapi.hasthiya.org/user/getUserById/${id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch member details");
        }
        const memberData = await response.json();

        const {
          email,
          streetNumber,
          streetName,
          APT,
          city,
          state,
          country,
          zipCode,
          imageUrl,
          Role,
          createdAt,
          registered,
          IsVerified,
          updatedAt,
          RoleId,
          pwdotpExpiryTime,
          profileName,
          Ayfabora,
          packageName,
        } = memberData.result;

        const formattedMemberDetails = {
          email,
          streetNumber,
          streetName,
          APT,
          city,
          state,
          country,
          zipCode,
          imageUrl,
          role: Role.role,
          createdAt,
          registered,
          isVerified: IsVerified,
          updatedAt,
          RoleId,
          pwdotpExpiryTime,
          profileName,
          ayfabora: Ayfabora,
          packageName,
        };

        setMemberDetails(formattedMemberDetails);
      } catch (error) {
        console.error("Error fetching member details:", error);
      }
    };

    fetchMemberDetails();
  }, [id]);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      weekday: "long",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };

  const renderDetails = () => {
    if (!memberDetails) return null;

    const detailItems = [
      { label: "Email", value: memberDetails.email },
      { label: "Street Number", value: memberDetails.streetNumber },
      { label: "Street Name", value: memberDetails.streetName },
      { label: "APT", value: memberDetails.APT },
      { label: "City", value: memberDetails.city },
      { label: "State", value: memberDetails.state },
      { label: "Country", value: memberDetails.country },
      { label: "Zip Code", value: memberDetails.zipCode },
      { label: "Role", value: memberDetails.role },
      { label: "Created At", value: formatDate(memberDetails.createdAt) },
      { label: "Registered", value: memberDetails.registered ? "Yes" : "No" },
      { label: "Verified", value: memberDetails.isVerified ? "Yes" : "No" },
      { label: "Updated At", value: formatDate(memberDetails.updatedAt) },
      { label: "Role ID", value: memberDetails.RoleId },
      // {
      //   label: "Password OTP Expiry Time",
      //   value: formatDate(memberDetails.pwdotpExpiryTime),
      // },
    ].filter(
      (item) =>
        item.value !== null && item.value !== undefined && item.value !== "N/A"
    );

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Avatar
            alt="Member"
            src={`/public/assets/${memberDetails.imageUrl}`}
            sx={{ width: 150, height: 150 }}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          {detailItems.map((item) => (
            <Grid container spacing={1} key={item.label}>
              <Grid item xs={6} md={3}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1rem", marginBottom: 1 }}
                >
                  <strong>{item.label}</strong>
                </Typography>
              </Grid>
              <Grid item xs={6} md={9}>
                <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                  : {item.value}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  return (
    <Box p="20px">
      <Header
        title={`View Afya ID ${id}`}
        subtitle={memberDetails ? `Role: ${memberDetails.role}` : ""}
      />
      <Box m="10px 0 0 0" height="55vh" overflow="auto">
        {renderDetails()}
      </Box>
    </Box>
  );
}

export default ViewAyfaMember;
