import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Snackbar,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import MuiAlert from "@mui/material/Alert";
import { getVideo, getAllArtists, updateVideo } from "./../../ApiConfig.jsx";

const validationSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  type: yup.string().required("Type is required"),
  artist_name: yup.string(),
  amount: yup.string().when("type", {
    is: "Paid",
    then: yup
      .string()
      .required("Amount is required")
      .matches(/^\d+$/, "Amount must contain only numbers"),
    otherwise: yup.string(),
  }),
  language: yup.string().required("Language is required"),
  video: yup.mixed(),
});

function EditAudio() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [status, setStatus] = useState("Inactive");
  const initialUserDetails = {
    title: "",
    amount: "",
    type: "",
    artist_name: "",
    language: "",
    video: null,
  };

  const [userDetails, setUserDetails] = useState(initialUserDetails);
  const [errors, setErrors] = useState({});
  const [artists, setArtists] = useState([]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentVideo, setCurrentVideo] = useState("");

  const fetchAudio = async (id) => {
    try {
      const data = await getVideo(id);
      if (data.status) {
        console.log(data.result);
        setStatus(data.result.status);
        setCurrentVideo(data.result.video_url);
        setUserDetails({
          title: data.result.title,
          type: data.result.type,
          artist_name: data.result.artist_name,
          amount: data.result.amount ? data.result.amount : "",
          language: data.result.language,
          video: null,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAudio(id);
  }, [id]);

  useEffect(() => {
    const getArtists = async () => {
      try {
        const artistData = await getAllArtists();
        if (artistData.status) {
          setArtists(artistData.result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getArtists();
  }, []);

  const validateField = (field, value) => {
    validationSchema
      .validateAt(field, { ...userDetails, [field]: value })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: error.message }));
      });
  };

  const handleFieldChange = (field, value) => {
    if (field == "type" && value == "Free") {
      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        amount: "0",
      }));
      setErrors((prevErrors) => {
        return { ...prevErrors, amount: "" };
      });
    }
    if (field == "type" && value == "Paid") {
      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        amount: "0",
      }));
    }
    console.log(value);
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [field]: value,
    }));
    validateField(field, value);
  };

  const updateVideoHandler = async (userDetails) => {
    if (userDetails.type === "Paid") {
      const amount = parseFloat(userDetails.amount);
      if (isNaN(amount) || amount === "" || amount < 0) {
        setErrors((prevUserDetails) => ({
          ...prevUserDetails,
          amount: "Amount must be a valid number",
        }));
        return;
      }
    }

    if (userDetails.video && !userDetails.video.type.startsWith("video/")) {
      setErrors((prevUserDetails) => ({
        ...prevUserDetails,
        video: "Video must be a valid video",
      }));

      return;
    }

    const hasErrors = Object.values(errors).some(
      (errorMessage) => errorMessage !== ""
    );

    if (hasErrors) {
      console.log("Errors found. Cannot update video.");
      return;
    }

    console.log(userDetails);
    setIsLoading(true);

    const formData = new FormData();
    formData.append("title", userDetails.title);
    formData.append("type", userDetails.type);
    formData.append("artist_name", userDetails.artist_name);
    formData.append("amount", userDetails.amount ? userDetails.amount : "0");
    formData.append("language", userDetails.language);
    formData.append("video", userDetails.video);
    formData.append("status", status == "Active" ? "Active" : "Inactive");

    try {
      const videoData = await updateVideo(id, formData);
      console.log(videoData);
      if (videoData.status) {
        setAlertSeverity("success");
        setAlertMessage("Video edited successfully");
        setTimeout(() => {
          navigate("/video-management");
        }, 2500);
      }
    } catch (err) {
      console.log(err);
      setAlertSeverity("error");
      setAlertMessage(`Failed to edit video: ${err.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`Edit Video ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Title*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.title}
              onChange={(e) => handleFieldChange("title", e.target.value)}
              error={!!errors.title}
              helperText={errors.title}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Type*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={userDetails.type}
                onChange={(e) => handleFieldChange("type", e.target.value)}
                name="type"
                error={!!errors.type}>
                <MenuItem value="Free">Free</MenuItem>
                <MenuItem value="Paid">paid</MenuItem>
              </Select>
              {errors.type && (
                <Typography variant="caption" color="error">
                  {errors.type}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {userDetails.type == "Paid" && (
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                Amount*
              </Typography>
              <TextField
                type="text"
                fullWidth
                variant="filled"
                value={userDetails.amount}
                onChange={(e) => handleFieldChange("amount", e.target.value)}
                error={!!errors.amount}
                helperText={errors.amount}
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Artist*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={userDetails.artist_name}
                onChange={(e) =>
                  handleFieldChange("artist_name", e.target.value)
                }
                name="artist_name"
                error={!!errors.artist_name}>
                {artists.map((artist, index) => {
                  return (
                    <MenuItem key={index} value={artist.artist_name}>
                      {artist.artist_name}
                    </MenuItem>
                  );
                })}
              </Select>
              {errors.artist_name && (
                <Typography variant="caption" color="error">
                  {errors.artist_name}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Language*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={userDetails.language}
                onChange={(e) => handleFieldChange("language", e.target.value)}
                name="language"
                error={!!errors.language}>
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="French">French</MenuItem>
              </Select>
              {errors.language && (
                <Typography variant="caption" color="error">
                  {errors.language}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>Video*</div>
              <TextField
                fullWidth
                variant="filled"
                type="file"
                accept="video/*"
                onChange={(e) => handleFieldChange("video", e.target.files[0])}
                name="video"
                error={!!errors.video}
                helperText={errors.video}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>Current Video</div>
              <video
                controls
                src={currentVideo}
                alt="Video Thumbnail"
                style={{
                  marginTop: "5px",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={() => updateVideoHandler(userDetails)}>
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}>
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}

export default EditAudio;
