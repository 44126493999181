import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import { getWorshipServiceByID } from "./../../ApiConfig.jsx";

function ViewWorshipService() {
  const { id } = useParams();
  const [worshipServiceDetails, setWorshipServiceDetails] = useState({});

  const GetWorshipServiceById = async (id) => {
    try {
      const data = await getWorshipServiceByID(id);
      if (data.data.status) {
        setWorshipServiceDetails(data.data.result);
      }
    } catch (error) {
      console.error("Error fetching worship service:", error);
    }
  };

  useEffect(() => {
    GetWorshipServiceById(id);
  }, [id]);

  return (
    <div>
      {" "}
      <Box m="20px" height="70vh" overflow="auto">
        <Header title={`View Worship Service ${id}`} subtitle="" />
        <Box ml={"40px"}>
          <Grid container>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Name:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{worshipServiceDetails.name}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Location:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{worshipServiceDetails.location}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Date:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{worshipServiceDetails.date}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Time:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{worshipServiceDetails.time}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Hours:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{worshipServiceDetails.hours}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Language:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{worshipServiceDetails.language}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Request Redirect:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{worshipServiceDetails.requestRedirect}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Description:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{worshipServiceDetails.description}</Typography>
              </Grid>
            </div>
          </Grid>

          {worshipServiceDetails.imageURL && (
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Image:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <img
                    src={worshipServiceDetails.imageURL}
                    alt={`Image of ${worshipServiceDetails.name}`}
                    style={{ maxWidth: "100%" }}
                  />
                </Grid>
              </div>
            </Grid>
          )}
        </Box>
      </Box>
    </div>
  );
}

export default ViewWorshipService;
