import {
  Box,
  Button,
  TextField,
  Snackbar,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import MuiAlert from "@mui/material/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { getQuestionById, updateQuestion } from "../../ApiConfig";

const EditQuestion = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const [updatedQuestionDetails, setUpdatedQuestionDetails] = useState({
    question: "",
    description: "",
    file: null,
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
    vote1: "",
    vote2: "",
    vote3: "",
    vote4: "",
  });
  const [errors, setErrors] = useState({});
  const [fileName, setFileName] = useState("");

  const GetQuestionById = async (id) => {
    try {
      const data = await getQuestionById(id);
      setUpdatedQuestionDetails({
        question: data.result.result.result.question,
        description: data.result.result.result.description,
        file: data.result.result.result.fileURL,
        answer1: data.result.result.result.voteOption1,
        answer2: data.result.result.result.voteOption2,
        answer3: data.result.result.result.voteOption3,
        answer4: data.result.result.result.voteOption4,
        vote1: data.result.result.result.vote1,
        vote2: data.result.result.result.vote2,
        vote3: data.result.result.result.vote3,
        vote4: data.result.result.result.vote4,
      });
      setFileName(data.result.result.result.file.name)
    } catch (error) {
      console.error("Error fetching question:", error);
    }
  };

  useEffect(() => {
    GetQuestionById(id);
  }, [id]);

  const handleInputChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });
    setUpdatedQuestionDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    if (name === "file" && value) {
      setFileName(value.name);
    }
    validateForm();
  };

  const validateForm = () => {
    const errors = {};

    if (!updatedQuestionDetails.question) {
      errors.question = "Question is required.";
    }
    if (!updatedQuestionDetails.answer1) {
      errors.answer1 = "Answer 1 is required.";
    }
    if (!updatedQuestionDetails.answer2) {
      errors.answer2 = "Answer 2 is required.";
    }
    if (!updatedQuestionDetails.answer3) {
      errors.answer3 = "Answer 3 is required.";
    }
    if (!updatedQuestionDetails.answer4) {
      errors.answer4 = "Answer 4 is required.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleUpdateQuestion = async () => {
    if (validateForm()) {
      setIsLoading(true);

      const body = {
        question: updatedQuestionDetails.question,
        description: updatedQuestionDetails.description,
        file: updatedQuestionDetails.file,
        voteOption1: updatedQuestionDetails.answer1,
        vote1: updatedQuestionDetails.vote1,
        voteOption2: updatedQuestionDetails.answer2,
        vote2: updatedQuestionDetails.vote2,
        voteOption3: updatedQuestionDetails.answer3,
        vote3: updatedQuestionDetails.vote3,
        voteOption4: updatedQuestionDetails.answer4,
        vote4: updatedQuestionDetails.vote4,
      };

      try {
        const response = await updateQuestion(id, body);
        console.log("ures", response);
        if (response.status) {
          setAlertSeverity("success");
          setAlertMessage("Question updated successfully");
          setTimeout(() => {
            navigate("/vote-management");
          }, 2000);
        }
      } catch (error) {
        setAlertSeverity("error");
        setAlertMessage(`Failed question update: ${error.message}`);
      } finally {
        setOpenSnackbar(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`UPDATE QUESTION ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1" fontWeight="bold">
              Question*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedQuestionDetails.question}
              onChange={(e) => handleInputChange("question", e.target.value)}
              error={!!errors.question}
              helperText={errors.question}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1" fontWeight="bold">
              Description*(Limited to 1000 characters)
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedQuestionDetails.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              multiline
              rows={5}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>File Upload</div>
              <TextField
                fullWidth
                variant="filled"
                type="file"
                //accept="image/*"
                onChange={(e) => handleInputChange("file", e.target.files[0])}
                name="file"
                error={!!errors.file}
                helperText={errors.file}
              />
              {fileName && (
                <Typography variant="subtitle1" mt={1}>
                  {`Current file: ${fileName}`}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Answer 1*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedQuestionDetails.answer1}
              onChange={(e) => handleInputChange("answer1", e.target.value)}
              error={!!errors.answer1}
              helperText={errors.answer1}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Answer 2*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedQuestionDetails.answer2}
              onChange={(e) => handleInputChange("answer2", e.target.value)}
              error={!!errors.answer2}
              helperText={errors.answer2}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Answer 3*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedQuestionDetails.answer3}
              onChange={(e) => handleInputChange("answer3", e.target.value)}
              error={!!errors.answer3}
              helperText={errors.answer3}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Answer 4*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedQuestionDetails.answer4}
              onChange={(e) => handleInputChange("answer4", e.target.value)}
              error={!!errors.answer4}
              helperText={errors.answer4}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={handleUpdateQuestion}
            >
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditQuestion;
