import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  useTheme,
  Snackbar,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import Swal from "sweetalert2";
import MuiAlert from "@mui/material/Alert";
import {
  getAllTemplates,
  formatDateToReadable,
  deleteTemplates,
  updateTemplateStatusById,
} from "../../ApiConfig";
import { useNavigate } from "react-router-dom";

const Templates = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const GetAllTemplates = async () => {
    try {
      const Data = await getAllTemplates();
      const formatData = Data.result.map((item) => ({
        ...item,
        type: item.type.charAt(0).toUpperCase() + item.type.slice(1),
        createdAt: formatDateToReadable(item.createdAt),
        noOfReaders: item.noOfReaders ? item.noOfReaders : 0,
      }));
      setData(formatData);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  useEffect(() => {
    GetAllTemplates();
  }, []);

  const handleViewClick = (id) => {
    navigate(`/template/view-template/${id}`);
  };

  const handleEditClick = (id) => {
    navigate(`/template/edit-templates/${id}`);
  };

  const handleStatusChange = async (id, newStatus) => {
    try {
      const body = {
        status: newStatus,
      };
      const res = await updateTemplateStatusById(id, body);
      if (res.status) {
        GetAllTemplates();
        setAlertSeverity("success");
        setAlertMessage("Status Updated successfully");
      }
    } catch (err) {
      console.log(err);
      setAlertSeverity("error");
      setAlertMessage(`Failed to update status: ${err.message}`);
    } finally {
      setOpenSnackbar(true);
    }
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "id", headerName: "S.No" },
    { field: "template", headerName: "Template", flex: 1 },
    // { field: "type", headerName: "Type", flex: 1 },
    // { field: "author", headerName: "Author", flex: 1 },
    // { field: "email", headerName: "Email", flex: 1 },
    { field: "createdAt", headerName: "Added Date", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Select
          value={params.row.status}
          onChange={(event) =>
            handleStatusChange(params.row.id, event.target.value)
          }
          style={{ width: "100px" }}
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="inactive">Inactive</MenuItem>
        </Select>
      ),
    },
    // { field: "noOfReaders", headerName: "No of Readers", flex: 1 },
    {
      flex: 1,
      headerName: "Actions",
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <IconButton onClick={() => handleViewClick(params.row.id)}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={() => handleEditClick(params.row.id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this blog!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteTemplates(id);
          if (!response.status) {
            throw new Error("Failed to delete blog");
          }
          GetAllTemplates();
          setAlertSeverity("success");
          setAlertMessage("Template deleted successfully");
        } catch (error) {
          console.log(error);
          setAlertSeverity("error");
          setAlertMessage(`Failed to delete blog: ${error.message}`);
        } finally {
          setOpenSnackbar(true);
        }
      }
    });
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Header title="Template Module" subtitle="" />
        <Box>
          <Link to={"create-template"} style={{ marginRight: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
            >
              Add New Template
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default Templates;
