import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import { getArtistByID,} from "./../../ApiConfig.jsx";

function ViewArtist() {
  const { id } = useParams();
  const [artistDetails, setArtistDetails] = useState({});


  const GetArtistById = async (id) => {
    try {
      const data = await getArtistByID(id);
      if(data.status){
        setArtistDetails(data.result);
      }
    } catch (error) {
      console.error("Error fetching artist:", error);
    }
  };

  useEffect(() => {
    GetArtistById(id);
  }, [id]);

  return (
    <div>
      {" "}
      <Box m="20px" height="70vh" overflow="auto">
        <Header title={`View Artist ${id}`} subtitle="" />
        <Box ml={"40px"}>
          <Grid container>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Artist Name:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{artistDetails.artist_name}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Description:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{artistDetails.description}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Language:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{artistDetails.language}</Typography>
              </Grid>
            </div>
          </Grid>

          <Grid container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  Type:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{artistDetails.type?.charAt(0).toUpperCase() + artistDetails.type?.slice(1)}</Typography>
              </Grid>
            </div>
          </Grid>
          {artistDetails.image_url && (
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={2}>
                  <Typography
                    variant="h5"
                    component="span"
                    fontWeight="bold"
                  >
                    Artist Image:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <img
                    src={artistDetails.image_url}
                    alt={`Image of ${artistDetails.artist_name}`}
                    style={{ maxWidth: "100%" }}
                  />
                </Grid>
              </div>
            </Grid>
          )}
        </Box>
      </Box>
    </div>
  );
}

export default ViewArtist;
