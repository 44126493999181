import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";

const Index = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://jcgnapi.hasthiya.org/api/tour/getAllTourRegistrations"
      );
      const responseData = await response.json();
      const sortedData = responseData.result.sort((a, b) => b.id - a.id);
      setData(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this registration!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `https://jcgnapi.hasthiya.org/api/tour/deleteTourRegistrationByID/${id}`,
          {
            method: "DELETE",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to delete registration");
        }

        fetchData();
        Swal.fire("Deleted!", "Registration has been deleted.", "success");
      } catch (error) {
        console.error("Error deleting registration:", error);
        Swal.fire("Error!", "Failed to delete registration.", "error");
      }
    }
  };

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "combineNames",
      headerName: "Non Traveler Name",
      flex: 1,
      valueGetter: (params) => {
        const firstName = params.row.nonTravlerFirstName || "";
        const middleName = params.row.nonTravlerMiddleName || "";
        const lastName = params.row.nonTravlerLastName || "";
        return `${firstName} ${middleName} ${lastName}`.trim();
      },
    },
    // { field: "nonTravlerFirstName", headerName: , flex: 1 },
    // { field: "nonTravlerMiddleName", headerName: "Middle Name", flex: 1 },
    // { field: "nonTravlerLastName", headerName: "Last Name", flex: 1 },
    { field: "nonTravlerEmail", headerName: "Email", flex: 1 },
    { field: "nonTravlerPhoneNumber", headerName: "Phone Number", flex: 1 },

    {
      field: "tourType",
      headerName: "Tour Type",
      flex: 1,
    },

    {
      field: "airfareService",
      headerName: "Air Fare Service",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Date Submitted (MM/DD/YYYY)",
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      },
    },
    {
      field: "depatureDate",
      headerName: "Departure Date (MM/DD/YYYY)",
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      },
    },
    // { field: "visitCountry", headerName: "Visit Country", flex: 1 },
    {
      field: "Actions",
      headerName: "Actions",
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <Link to={`/tour/regTour/view/${params.row.id}`}>
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const getRowClassName = () => {
    return "row-divider";
  };

  const exportToPdf = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "ID",
          "First Name",
          "Middle Name",
          "Last Name",
          "Email",
          "Phone Number",
          "Tour Type",
          "Date Submitted (MM/DD/YYYY)",
          "Travel Date (MM/DD/YYYY)",
          "Air Fare Service",
          "Visit Country",
        ],
      ],
      body: data.map(
        ({
          id,
          nonTravlerFirstName,
          nonTravlerLastName,
          nonTravlerMiddleName,
          nonTravlerPhoneNumber,
          phoneNumber,
          nonTravlerEmail,
          tourType,
          createdAt,
          depatureDate,
          airfareService,
          visitCountry,
        }) => [
          id,
          nonTravlerFirstName,
          nonTravlerLastName,
          nonTravlerEmail,
          tourType,
          nonTravlerMiddleName,
          nonTravlerPhoneNumber,
          phoneNumber,
          new Date(createdAt).toLocaleDateString("en-US"),
          new Date(depatureDate).toLocaleDateString("en-US"),
          airfareService,

          visitCountry,
        ]
      ),
    });
    doc.save("preTourLogs.pdf");
  };

  return (
    <Box m="20px">
      <Header
        title="Tour Registrations"
        subtitle="Show all the tour registrations have been submitted by users."
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="0px"
      >
        <Button
          variant="contained"
          onClick={exportToPdf}
          sx={{
            backgroundColor: "#4caf50",
            color: "white",
            fontSize: "10px",
            "&:hover": {
              backgroundColor: "#388e3c",
            },
          }}
        >
          Export to PDF
        </Button>
      </Box>
      <Box
        m="0 0 0 0"
        height="57vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .row-divider": {
            borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowClassName={getRowClassName}
        />
      </Box>
    </Box>
  );
};

export default Index;
