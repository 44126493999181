import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";

function ViewPayment() {
  const { id } = useParams();
  const theme = useTheme();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    fetchPaymentDetails();
  }, [id]);

  const fetchPaymentDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://jcgnapi.hasthiya.org/payment/getpayment-history/${id}`
      );
      const responseData = await response.json();
      if (responseData.status) {
        setPaymentDetails(responseData.result);
      } else {
        setError("Failed to fetch payment details: " + responseData.message);
      }
    } catch (error) {
      setError("Error fetching payment details: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatFieldName = (field) => {
    return field.replace(/([A-Z])/g, " $1").trim();
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatListOfCountries = (value) => {
    if (typeof value === "string") {
      return value
        .replace(/"/g, "")
        .split(",")
        .map((country) => country.trim())
        .join(", ");
    }
    return value;
  };

  const renderMetadata = (metadata) => {
    if (!metadata || Object.keys(metadata).length === 0) {
      return <Typography>No metadata available</Typography>;
    }

    return (
      <Grid container spacing={2}>
        {Object.entries(metadata).map(([key, value]) => {
          if (value === null || value === "") {
            return null;
          }
          if (key === "User Id") {
            return null;
          }

          const formattedValue =
            key === "listOfCountries" ? formatListOfCountries(value) : value;

          return (
            <Grid item xs={12} key={key}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {capitalizeFirstLetter(formatFieldName(key))}:
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle1">:</Typography>
                </Grid>
                <Grid item xs={9}>
                  {key.toLowerCase() === "imageurl" ? (
                    <Box
                      component="img"
                      src={formattedValue}
                      alt="Preview"
                      sx={{
                        width: 200,
                        height: 200,
                        objectFit: "cover",
                        borderRadius: 1,
                      }}
                    />
                  ) : (
                    <Typography>{formattedValue}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box m="20px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!paymentDetails) {
    return (
      <Box m="20px">
        <Typography variant="h6">No payment details found.</Typography>
      </Box>
    );
  }

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`View Payment User ID: ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            mb={2}
            color={colors.blueAccent[400]}
          >
            Payment Details
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(paymentDetails).map(([field, value]) => {
              if (value === null || value === "") {
                return null;
              }
              if (field === "userId") {
                return null;
              }
              if (field === "metadata") {
                return (
                  <Grid item xs={12} key={field}>
                    <Typography variant="h6" fontWeight="bold" mt={2} mb={1}>
                      Metadata
                    </Typography>
                    {renderMetadata(value)}
                  </Grid>
                );
              }
              if (field === "date") {
                return (
                  <Grid item xs={12} key={field}>
                    <Grid container>
                      <Grid item xs={2}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          {capitalizeFirstLetter(formatFieldName(field))}:
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="subtitle1">:</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography>{formatDate(value)}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }
              return (
                <Grid item xs={12} key={field}>
                  <Grid container>
                    <Grid item xs={2}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {capitalizeFirstLetter(formatFieldName(field))}:
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="subtitle1">:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography>{value}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
}

export default ViewPayment;
