import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete as DeleteIcon } from "@mui/icons-material";
import Swal from "sweetalert2";
import MuiAlert from "@mui/material/Alert";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Snackbar,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import axios from "axios";
import {
  getPharmacy,
  updatePharmacy,
  deletePharmacy,
} from "./../../ApiConfig.jsx";

const PharmacyManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [churchName, setChurchName] = useState("");
  const [isChurchNameValid, setIsChurchNameValid] = useState(true);
  const [data, setData] = useState([]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const fetchData = async () => {
    const data = await getPharmacy();
    console.log(data);
    
    setData(data.map((dataItem) => {
      return {
        ...dataItem,
        type: dataItem.type === "public" ? "Public" : "Private",
      };
    }));
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleStatusChange = async (id, newStatus, params) => {
    try {
      const itemData = await updatePharmacy(id, {
        status: newStatus,
      });

      if (itemData.succes) {
        const updatedData = data.map((item) => {
          if (item.id === id) {
            return { ...item, status: newStatus };
          }
          return item;
        });
        setData(updatedData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleViewClick = (id, role) => {
    console.log(`View clicked for ${role} with id:`, id);
  };

  const handleEditClick = (id, role) => {
    console.log(`Edit clicked for ${role} with id:`, id);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleNameChange = (event) => {
    const newName = event.target.value;
    setChurchName(newName);
    const isValid = newName.trim() !== "";
    setIsChurchNameValid(isValid);
  };
  const handleAddChurch = () => {
    setIsModalOpen(false);
    setChurchName("");
    setIsChurchNameValid(true);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "id", headerName: "S.NO" },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "pharmacyId", headerName: "Id", flex: 1 },

    { field: "address", headerName: "Address", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
    { field: "phone", headerName: "Phone ", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Select
          value={params.row.status}
          onChange={(event) =>
            handleStatusChange(params.row.id, event.target.value, params.row)
          }
          style={{ width: "100px" }}>
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="inactive">Inactive</MenuItem>
        </Select>
      ),
    },
    {
      flex: 1,
      headerName: "Actions",
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <Link to={`/pharmacy/view/${params.row.id}`}>
              <IconButton
                onClick={() => handleViewClick(params.row.id, params.row.role)}>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Edit">
            <Link to={`/pharmacy/edit/${params.row.id}`}>
              <IconButton
                onClick={() => handleEditClick(params.row.id, params.row.role)}>
                <EditIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this pharmacy!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deleteResponse = await deletePharmacy(id);
          console.log("🚀 ~ handleDeleteClick ~ deleteResponse:", deleteResponse)
          if (!deleteResponse.succes) {
            throw new Error("Failed to delete");
          }
          fetchData();
          setAlertSeverity("success");
          setAlertMessage("Pharmacy deleted successfully");
        } catch (error) {
          console.log(error);
          setAlertSeverity("error");
          setAlertMessage(`Failed to delete pharmacy: ${error.message}`);
        } finally {
          setOpenSnackbar(true);
        }
      }
    });
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px">
        <Header title="Pharmacy Management" subtitle="" />
        <Box>
          <Link style={{ marginRight: "10px" }}>
            <Link to="/pharmacy/add">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}>
                Add Pharmacy
              </Button>
            </Link>
          </Link>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}>
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Paper>
          <Box p={2} width={400} sx={{ textAlign: "center", m: 2 }}>
            <Typography variant="h4">Add New JCGN Church</Typography>

            <TextField
              label="Church Name"
              variant="outlined"
              fullWidth
              value={churchName}
              onChange={handleNameChange}
              error={!isChurchNameValid}
              helperText={!isChurchNameValid && "Church Name is required"}
              sx={{ mt: 2 }}
            />
            <Button
              onClick={handleAddChurch}
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "12px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
                mt: 3,
              }}>
              Add Hospital
            </Button>
          </Box>
        </Paper>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}>
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default PharmacyManagement;
